import { Col, Space } from "antd";
import React from "react";

const AddImage = (props) => {
  return (
    <div>


      <Space>
        <Col>
          <div style={{ marginTop: "0.5rem", paddingBottom: "0.1rem" }}>
            <img
              src={props.data}
              alt="NucleusTeq"
              loading="lazy"
              className="reviewcomments_ratingCards"
            />
          </div>
        </Col>
      </Space>


    </div>
  );
};

export default AddImage;
