import React from "react";
import "../../sass/styles/fyndr/_purchasePopup.scss";
import purchasePng from "../../assets/images/purchaseImage.png";
import autopay from "../../assets/images/blueAuto.svg"
import { Modal, Button, Row, Col, Image, Divider } from "antd";
import closeBtn from "../../assets/images/closeBtn.svg";
import { useHistory } from "react-router-dom";
import { getFormattedDtNew , isMobile} from "../../common/util";
import { useSelector } from "react-redux";

const PurchasePopup = (props) => {
  const {
    indv: { userTimeZone },
  } = useSelector(({ auth }) => auth);
  const { visible, onCancel, createdInvoice, destroyOnClose, payCard, list, subScription, isSubscriptionEnabled } = props;
  const closeIcon = <img src={closeBtn} alt="closeBtn" />;
  const history = useHistory();
  const invid = createdInvoice?.objid;
  console.log("ppp" , list)

  const handleClick = () => {
    history.push("/billing/transactions", { invid });
  };

  const capitalizeWords = (str) => {
    return str.replace(/\b\w/g, char => char.toUpperCase());
  };
  
  const formatChannels = (channels) => {
    return channels
      ?.split(',')
      .filter(channel => channel !== 'in_app')
      .map(channel => (channel === 'mobile_push' ? 'Phone' : channel))
      .map(capitalizeWords)
      .join(', ');
  };


  let footerContent = null;
  if (props.footer !== null) {
    footerContent = (
      <>
        <div className="zigzag-border">
          <Divider style={{ margin: "6px 0px" }} />
        </div>
      </>
    );
  }

  return (
    <Modal
      centered
      visible={visible}
      title={null}
      onCancel={onCancel}
      destroyOnClose={destroyOnClose}
      closeIcon={closeIcon}
      width={600}
      className="purchase-customize_modal"
      footer={null}
    >
      <div className="zigzag-border" style={{ marginTop: "4rem" }}>
        {!isMobile() && <div className="horizontal-bar"></div> }
        <div className="header">
          <img
            src={purchasePng}
            alt="Congratulations"
            className="header-image"
          />
        </div>
        <Row justify="space-between" className="detail-row">
          <Col className="detail">
            <Row>
              <span className="header-span">Date</span>
            </Row>
            <Row>
              <span className="bold-span">
                {getFormattedDtNew(createdInvoice?.invoiceDt, userTimeZone)}
              </span>
            </Row>
          </Col>
          <Col style={{ borderRight: "solid 2px #D3D6E1" }}></Col>
          <Col className="detail">
            <Row>
              <span className="header-span">Payment method</span>
            </Row>
            <Row>
              <span className="bold-span">
                {" "}
                {payCard?.payment_method &&
                  payCard.payment_method.charAt(0).toUpperCase() +
                    payCard.payment_method.slice(1)}
              </span>
            </Row>
          </Col>
          <Col style={{ borderRight: "solid 2px #D3D6E1" }}></Col>
          <Col className="detail">
            <Row>
              <span className="header-span">Invoice ID</span>
            </Row>
            <Row>
              <span className="bold-span">{createdInvoice?.objid}</span>
            </Row>
          </Col>
        </Row>
        <Divider style={{ borderTop: "2px dashed #d9d9d9", margin: "6px 0" }} />
        <div className="campaign-container">

        { (createdInvoice?.channel === "offers" || createdInvoice?.channel === "offer_appointment" || createdInvoice?.channel === "events") && (
          <>
          <span className="bold-span" style={{ fontSize: "16px" }}>
            {createdInvoice?.invoiceDetails?.cmpn_title}
          </span>
          { createdInvoice?.invoiceDetails?.offers.map((offer) => (
              <Row className="offer">
                <Col  xs={6} sm={4} lg={4} xl={4} >
                  <Image
                    width={isMobile() ? 80: 93}
                    height={80}
                    src={createdInvoice?.biz.mainLogo}
                    className="offer-image"
                    style={{ objectFit: "cover" }}
                  />
                </Col>
                <Col className="purchase-offer-details" xs={18} sm={20} lg={20} xl={20}>
                  <span>{offer?.title}</span>
                  <Row justify="space-between">
                    <Col>
                      <span>Qty: {offer?.qty}</span>
                    </Col>
                    <Col>
                      <span className="bold-span">
                        {createdInvoice?.currencySymbol}
                        {offer?.qty_total}
                      </span>
                    </Col>
                  </Row>
                </Col>
              </Row>
            ))}
            </>
          )}

        {(createdInvoice?.channel === "cmpn_promo") && (
          <>
          <Row justify="space-between" align="middle">
            <Col>
              <span className="bold-span" style={{ fontSize: "16px" }}>
                {createdInvoice?.invoiceDetails?.title}
              </span>
          </Col>
          <Col>
          {isSubscriptionEnabled && (
              <Image 
                src={autopay}
              />
          )}
          </Col>
          </Row>
          <Row className="offer">
            <Col xs={4} sm={4} lg={4} xl={4} >
              <Image
                width={isMobile() ? 54 : 86}                
                src={createdInvoice?.biz.mainLogo}
                className="offer-image"
                style={{ objectFit: "cover" ,marginRight:"6px" , overflow:"hidden"}}
              />
            </Col>
             <Col xs={20} sm={20} lg={20} xl={20}>
            {formatChannels(createdInvoice?.invoiceDetails?.promo_channels)?.split(', ').map((channel, index) => {
                const channelInfo = list?.find(item => formatChannels(item?.key) === channel);
                return (
                  <Row key={index} justify="space-between">
                    <Col span={20}>
                      <span>{channel}</span>
                    </Col>
                    <Col span={4} style={{ textAlign: "right" }}>
                      <span>{createdInvoice?.currencySymbol}{channel==="Featured" ? subScription : channelInfo?.price}</span>
                    </Col>
                  </Row>
                );
              })}
              </Col> 

          </Row>
          </>
          )}

        {(createdInvoice?.channel === "catalog" || createdInvoice?.channel === "catalog_appointment") && (
          <>
          <span className="bold-span" style={{ fontSize: "16px" }}>
            {createdInvoice?.invoiceDetails?.title}
          </span>
          { createdInvoice?.invoiceDetails?.items.map((catalouge) => (
              <Row className="offer">
                <Col xs={6} sm={4} lg={4} xl={4}>
                  <Image
                    width={isMobile() ? 80: 93}
                    height={80}
                    src={catalouge?.details?.mitem?.img_url}
                    className="offer-image"
                    style={{ objectFit: "cover" }}
                  />
                </Col>
                <Col className="purchase-offer-details" xs={18} sm={20} lg={20} xl={20}>
                <Row style={{fontSize:"16px"}}>{createdInvoice?.invoiceDetails?.catalogueName}</Row>
                  <span>{catalouge?.details?.mitem?.name}</span>
                  <Row justify="space-between">
                    <Col>
                      <span>Qty: {catalouge?.details?.qty}</span>
                    </Col>
                    <Col>
                      <span className="bold-span">
                        {catalouge?.details?.currencySymbol}
                        {catalouge?.details?.total}
                      </span>
                    </Col>
                  </Row>
                </Col>
              </Row>
            ))}
            </>
        )}
        </div>
        <Divider style={{ margin: "6px 0px" }} />
        <div className="summary-container">
          <Row justify="space-between" className="summary-item">
            <span>Amount:</span>
            <span>
              {createdInvoice?.currencySymbol}
              {Number(createdInvoice?.baseAmount).toFixed(2).toString()}
            </span>
          </Row>
          <Row justify="space-between" className="summary-item">
            <span>Tax:</span>
            <span>
              {createdInvoice?.currencySymbol}
              {Number(createdInvoice?.taxAmount).toFixed(2).toString()}
            </span>
          </Row>
            {createdInvoice?.tipAmount !== 0.0 && (
              <>
              <Row justify="space-between" className="summary-item">
                <span>Tip:</span>
                <span>
                  {createdInvoice?.currencySymbol}
                  {Number(createdInvoice?.tipAmount).toFixed(2).toString()}
                </span>
          </Row>
              </>
            )}
            {createdInvoice?.fyndrCash !== 0.0 && (
              <>
              <Row justify="space-between" className="summary-item">
                <span>Fyndr Cash:</span>
                <span>
                  -{createdInvoice?.currencySymbol}
                  {Number(createdInvoice?.fyndrCash).toFixed(2).toString()}
                </span>
              </Row>
              </>
            )}
          <Divider style={{ margin: "6px 0px" }} />
          <Row justify="space-between" className="total-amount">
            <span>Total Amount:</span>
            <span>
              {createdInvoice?.currencySymbol}
              {Number(
                createdInvoice?.baseAmount +
                createdInvoice?.tipAmount +
                createdInvoice?.taxAmount -
                createdInvoice?.fyndrCash
              ).toFixed(2).toString()}
            </span>
          </Row>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          {(createdInvoice?.channel === "offers" || createdInvoice?.channel === "offer_appointment" || createdInvoice?.channel === "events") && (
            <>
              <Button
                type="primary"
                className="purchase-button"
                style={{ background: "none", color: "#257CDB" }}
                onClick={handleClick}
              >
                My orders
              </Button>
              <Button
                type="primary"
                className="purchase-button"
                onClick={onCancel}
              >
                Continue Shopping
              </Button>
            </>
          )}
           {(createdInvoice?.channel === "cmpn_promo") && (
            <>
             <Button
             type="primary"
             className="purchase-button"
             style={{ background: "none", color: "#257CDB" }}
             onClick={handleClick}
           >
             My orders
           </Button>
          <Button type="primary" className="purchase-button" onClick={onCancel}>
            Campaign
          </Button> 
          </>       
  )}
       {(createdInvoice?.channel === "catalog" || createdInvoice?.channel === "catalog_appointment") && (
           <>
           <Button
             type="primary"
             className="purchase-button"
             style={{ background: "none", color: "#257CDB" }}
             onClick={handleClick}
           >
             My orders
           </Button>
           <Button
             type="primary"
             className="purchase-button"
             onClick={onCancel}
           >
             Continue Shopping
           </Button>
         </>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default PurchasePopup;
