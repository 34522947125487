import React from "react";
import Edit from './Edit';

const InvoiceWrapper = (props) => {
    const { history : { location : {state: {mode, interaction, current_location } }}} = props;
   
    return (
        <div className="container-fluid container-mw-lg chapter">
            <article className="article">
            <h2 className="article-title" >
                {mode === 'edit' ? 'Edit' : `Create Invoice for ${interaction.name}` }
            </h2>
            <div className="box box-default">
                    <div className="box-body py-5">
                         <Edit interaction={interaction} current_location={current_location} />
                    </div>    
                 </div>       
            </article> 
        </div>  
    )
}

export default InvoiceWrapper