import React from 'react';
import ReactApexChart from "react-apexcharts";
const Charts = (props)=>{
    const {series, config} = props;
      return(
        <ReactApexChart
          options={config}
          series={series}
          type='bar'
          height={300}
      />
    )
}

export default Charts