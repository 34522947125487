import React, { useEffect, useState } from 'react'
import { Row, Col, Button } from "antd"
import UserActions from "../../actions";
import card from "../../assets/images/card.svg"
import autopay from "../../assets/images/autopay.svg"
import cancel from "../../assets/images/cancel.svg"
import FeaturedLogo from "../../assets/images/featuredLogo.png"
import moment from 'moment';
import Loader from '../Loading/Loader';
import { Empty } from 'antd';
import { useSelector } from 'react-redux';
import { getFormattedDtNew } from '../../common/util';


export default function FeaturedActions(props) {
    const {
        indv: { userTimeZone },
      } = useSelector(({ auth }) => auth);
    const { onUpdateClick, isSubscription, onCancelClick, onUpdateSubsClick, cmpn_id, updatedMonthDuration } = props;
    const [apidata, setApiData] = useState();
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        fetchOngoingPromotions();
    }, [])

    const fetchOngoingPromotions = async () => {
        setLoading(true)
        const resp = await UserActions.fetchOngoingPromotions({ cmpnId: cmpn_id });
        if (resp.success) {
            setLoading(false)
            setApiData(resp)
        } else {
            setLoading(false)
        }
    };
    const locationDetails = apidata?.location?.map((location, index) => ({
        label: apidata?.location.length===1 ? `Location :` : `Location ${index + 1} :`,
        address: location,
        radius: `${apidata.radius[index]} ${apidata.radius[index] === 1 ? 'Mile' : 'Miles'}`,
        recipients: apidata.recipient[index]
    })) || [];

    let data = [
        { label: "Current Status :", value: <img src={apidata?.cmpnFeaturedDetails?.status === "ACTIVE" ? FeaturedLogo : "Not Featured"} style={{ height: "30px", width: "100px" }} alt='fyndr' /> },
        { label: "Promotion ID :", value: apidata?.promotionId},
        { label: "Start Date :", value: getFormattedDtNew(apidata?.cmpnFeaturedDetails?.startDt, userTimeZone) },
        { label: "End Date :", value: getFormattedDtNew(apidata?.cmpnFeaturedDetails?.endDt, userTimeZone) },
        { label: "Duration :", value: `${apidata?.cmpnFeaturedDetails?.duration} ${apidata?.cmpnFeaturedDetails?.duration > 1 ? 'Months' : 'Month'}` },
        ...locationDetails.flatMap((location, index) => ([
            { label: location.label, value: location.address, key: `location-${index}-address` },
            { label: 'Radius :', value: location.radius, key: `location-${index}-radius` },
            { label: 'Recipients :', value: location.recipients, key: `location-${index}-recipients` }
        ])),
        { label: "Amount :", value: apidata?.amount !== null ? `${apidata?.currencySymbol}${apidata?.amount}` : "-" },
    ]

    return (
        <>
            <div className='featuredSubs_div'>
                {loading ?
                    <Row justify={"center"} style={{ padding: "12px" }}>
                        <Loader />
                    </Row>
                    :
                    <>
                        {apidata?.cmpnFeaturedDetails || apidata?.invoiceDetails || apidata?.amount || apidata?.currencySymbol ?
                            data.map((data) => {
                                return (
                                    <Row justify={"space-between"} style={{ marginBottom: "10px" }}>
                                        <Col span={14} md={10}>
                                            {data.label}
                                        </Col>
                                        <Col span={10} align="right" md={14}>
                                            {data.value}
                                        </Col>
                                    </Row>
                                )
                            })
                            :
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
                        }
                        {isSubscription === "CANCELED" &&
                            <Col className='updateSubspopup_note2' style={{marginTop:"12px"}}>
                                Your subscription has been canceled.
                            </Col>
                        }
                        {isSubscription !== "CANCELED" && updatedMonthDuration && apidata?.cmpnFeaturedDetails?.duration !== updatedMonthDuration && 
                            <Col className='updateSubspopup_note'>
                                You have opted for a {`${updatedMonthDuration} ${updatedMonthDuration > 1 ? 'Months' : 'Month'}`} subscription, the changes will take effect from the next billing cycle.
                            </Col>
                        }


                    </>

                }
            </div>

            {isSubscription === "ACTIVE" &&
                <Row justify={"center"} gutter={[0, 12]} style={{ marginTop: "12px" }}>
                    <Button
                        size='large'
                        className='featuredSubs_btn'
                        onClick={onUpdateClick}
                    >
                        <span style={{ marginRight: "3px" }}>
                            Update Payment Method
                        </span>
                        <img src={card} alt='fyndr'></img>
                    </Button>
                    <Button
                        size='large'
                        className='featuredSubs_btn-subs'
                        onClick={() => onUpdateSubsClick(apidata?.cmpnFeaturedDetails?.duration)}
                    >
                        <span style={{ marginRight: "3px" }}>
                            Update Subscription
                        </span>
                        <img src={autopay} alt='fyndr'></img>
                    </Button>
                    <Button
                        size='large'
                        style={{
                            padding: "3px 12px",
                            borderRadius: "8px",
                            height: "46px"
                        }}
                        danger
                        onClick={onCancelClick}
                    >
                        <span style={{ marginRight: "3px" }}>
                            Cancel Auto Renew
                        </span>
                        <img src={cancel} alt='fyndr'></img>
                    </Button>
                </Row>
            }
        </>
    )
}
