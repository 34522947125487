/* eslint-disable no-fallthrough */
/* eslint-disable eqeqeq */
import { Actions } from "../common";

const {
  UPDATE_CATEGORIES,
  UPDATE_ITEM,
  UPDATE_MODIFIER,

  UPDATE_CATALOGUE_ITEM,
  UPDATE_CATALOGUE,
  UPDATE_CATALOGUE_MODIFIER,

  FETCH_CATEGORIES,
  FETCH_ITEMS,
  FETCH_MODIFIER,
  FETCH_CATALOGUE,
  CART_UPDATED,
  CART_DELETED,
  CATEGORIES_FOUND,
  CART_INVOICED,
  CART_LOADED,
  CART_STARTED,
  LOCOFFER,
  LOCQR,
  UPDATE_BIZ_DETAILS,
  DELETE_DATA
} = Actions;

const INITIAL_STATE = {
  categories: [],
  items: [],
  modifiers: [],
  catalogue: [],
  catalogue_items: [],
  catalogue_modifier: [],
  catalogueLocation: {},
  buyerQrId: null,
  invoiceBeingEdited: {},
  cart: {},
  cartModified: false,
  qrCatalog: {},
  locOffers: {},
  locQR: {},
};

const CatalogueReducer = (state = INITIAL_STATE, action) => {
  if (action.type === "undefined") {
    return state;
  }
  switch (action.type) {
    case FETCH_CATEGORIES: {
      return { ...state, categories: action.payload };
    }
    case FETCH_ITEMS: {
      return { ...state, items: action.payload != null ? action.payload : [] };
    }
    case FETCH_MODIFIER: {
      return {
        ...state,
        modifiers: action.payload != null ? action.payload : [],
      };
    }
    case FETCH_CATALOGUE: {
      let sortedValue = action.payload.sort((a, b) => a.name < b.name);

      return { ...state, catalogue: sortedValue };
    }
    case DELETE_DATA:{
      return INITIAL_STATE;
    }
    case UPDATE_CATEGORIES: {
      let tempCat = state.categories.filter(
        (row) => row.objid !== action.payload.objid
      );
      tempCat.push(action.payload);
      return { ...state, categories: tempCat };
    }
    case UPDATE_ITEM: {
      let tempITEM = state.items.filter(
        (row) => row.objid !== action.payload.objid
      );
      tempITEM.push(action.payload);
      return { ...state, items: tempITEM };
    }
    case UPDATE_MODIFIER: {
      let tempModifier = state.modifiers.filter(
        (row) => row.objid !== action.payload.objid
      );
      tempModifier.push(action.payload);
      return { ...state, modifiers: tempModifier };
    }
    case UPDATE_CATALOGUE_ITEM: {
      let tempcol = state.catalogue_items?.filter(
        (row) => row.objid != action.payload.objid
      );
      return {
        ...state,
        catalogue_items: [...tempcol, action.payload],
      };
    }
    case UPDATE_CATALOGUE_MODIFIER: {
      let tempcol = state.catalogue_modifier?.filter(
        (row) => row.objid != action.payload.objid
      );
      return {
        ...state,
        catalogue_modifier: [...tempcol, action.payload],
      };
    }
    case UPDATE_CATALOGUE: {
      let tempCollections = state?.catalogue?.filter(
        (row) => row.objid !== action.payload.objid
      );
      tempCollections.push(action.payload);

      return {
        ...state,
        catalogue: tempCollections,
      };
    }
    case CART_UPDATED:
      return {
        ...state,
        cart: {
          ...state.cart,
          [Object.keys(action.payload)[0]]: Object.values(action.payload)[0],
        },
        cartModified: !state.cartModified,
      };
    case CATEGORIES_FOUND:
      const { info, buyerQrId } = action.payload;
      return {
        ...state,
        catalogue: info.catalogue,
        categories: info.categories.sort((a, b) => (a.name > b.name ? 1 : -1)),
        buyerQrId: buyerQrId !== undefined ? buyerQrId : state.buyerQrId,
        cart: state.catalogueLocation.locId !== info.locId ? {} : state.cart,
        cartModified: !state.cartModified,
        catalogueLocation: {
          locId: info.locId,
          locName: info.locName,
          parentLocation: info.parentLocation,
          biz: info.biz,
        },
      };
  
      case UPDATE_BIZ_DETAILS:
      
      return {
        ...state,
       
        catalogueLocation: {
          biz: action.payload,
        },
      };
  
  
  
      case CART_DELETED: {
      let cart = state.cart;
      delete cart[action.payload];
      return { ...state, cart, cartModified: !state.cartModified };
    }
    case CART_INVOICED:
      return { ...state, cart: {}, cartModified: !state.cartModified };
    case CART_LOADED:
      return {
        ...state,
        cart: action.payload.cart,
        catalogueLocation: action.payload.catalogueLocation,
        catalogue: action.payload.catalogue,
        cartModified: !state.cartModified,
        invoiceBeingEdited: action.payload.inv,
      };
    case CART_STARTED:
      return {
        ...state,
        cart: {},
        invoiceBeingEdited: {},
        cartModified: !state.cartModified,
      };
    case LOCOFFER:
      return {
        ...state,
        locOffers: action.payload,
      };

    case LOCQR:
      return {
        ...state,
        locQR: action.payload,
      };
    default:
      return state;
  }
};

export default CatalogueReducer;
