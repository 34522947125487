import React from "react";
import { Modal } from "antd";

const ConfirmModal = ({ visible, title, description, onConfirm, onCancel }) => (
  <Modal onOk={onConfirm} onCancel={onCancel} title={title} visible={visible}>
    <div>
      <p>{description}</p>
    </div>
  </Modal>
);

export default ConfirmModal;
