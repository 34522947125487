import React from "react";
import{ compose, withProps } from "recompose";
import getEnvVars from "../environment";

import {
  withGoogleMap,
  GoogleMap
} from "react-google-maps";

const { app: {GOOGLE_API_KEY}} = getEnvVars();

const Map = compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&libraries=places&callback=Function.prototype`,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `100%`, width: "100%" }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withGoogleMap
)((props) => {
  const { zoom, defaultCenter } = props;
  return (
    <GoogleMap
      defaultZoom={zoom}
      zoom={zoom}
      center={defaultCenter}
      defaultCenter={defaultCenter}
    >
      {props.children}
    </GoogleMap>
  );
});
    
  
export default Map;

Map.defaultProps = {
  defaultCenter: { lat: 33.6629442, lng: -112.0182329 },
  list: [],
};

