/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Row,
  Col,
  Input,
  Button,
  Select,
  Table,
  message,
  Space,
  Typography,
  Modal,
} from "antd";
import Calendar from "react-calendar";
import { getFormattedDt, getFormattedTm } from "../../common/util";
import UserActions from "../../actions";
import ContainerWrapper from "../../components/ContainerWrapper";
import { PAGINATION_LIMIT, MINIMUM_SEARCH_LIMIT } from "../../common/config";
import Loader from "../../components/Loading/Loader";
import { SearchOutlined } from "@ant-design/icons";
import HelmetFyndr from "../../components/HelmetFyndr";
import getEnvVars from "../../environment";

const { Option } = Select;
const { Text } = Typography;
const web = getEnvVars();

const TermsReport = (props) => {
  const [startDate, setStartDate] = useState(
    new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000)
  );

  const [endDate, setEndDate] = useState(new Date());
  const [location, setLocation] = useState();
  const [isStartDate, setIsStartDate] = useState(true);
  const [showCalendar, setShowCalendar] = useState(false);
  const [regex, setRegex] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [filterLocation, setFilterLocation] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const {
    terms: { active },
    indv: { bizid, locations },
  } = useSelector(({ auth }) => {
    return auth;
  });

  useEffect(() => {
    doSearch();
  }, []);

  useEffect(() => {
    UserActions.getTerms(bizid);
    let locationAddress = [];
    Array.isArray(locations) &&
      locations.map((item) => {
        locationAddress.push({ text: item.locName, value: item.locName });
        return null;
      });
    setFilterLocation(locationAddress);
  }, []);

  const onSelect = (value) => {
    if (isStartDate) {
      setStartDate(new Date(value));
    } else {
      setEndDate(new Date(value));
    }
    setShowCalendar(false);
  };

  const changeHandler = (page, pageSize) => {};

  const responseSetter = (response) => {
    response.map((item, index) => {
      item.key = index;
      item.objid = { item: item.objid, status: item.status };
      return null;
    });
    setSearchData(response);
  };

  const doSearch = async () => {
    if (
      regex.length < MINIMUM_SEARCH_LIMIT &&
      (location === undefined || location === null) &&
      (startDate === undefined || startDate === null) &&
      (endDate === undefined || endDate === null)
    ) {
      message.error(
        `Please set the criteria for the search (min search characters must be ${MINIMUM_SEARCH_LIMIT})`
      );
      return;
    }
    setIsLoading(true);
    let response = await UserActions.searchBizTerms({
      regex,
      startDate,
      endDate: endDate ? endDate : "",
      location: location ? location : null,
      pageNumber: pageNumber,
      bizId: bizid,
    });

    setIsLoading(false);
    if (response.success) {
      responseSetter(response.data);
    }
  };

  const columns = [
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
    },

    {
      title: "Date",
      dataIndex: "timeStamp",
      key: "timeStamp",
      sorter: (a, b) => {
        return (
          new Date(a.timeStamp.replace(/\s/, "T")) -
          new Date(b.timeStamp.replace(/\s/, "T"))
        );
      },
      sortDirections: ["descend"],

      render: (date) => {
        return (
          <>
            <p>{getFormattedDt(new Date(date.replace(/\s/, "T")))}</p>
            <p>{getFormattedTm(new Date(date.replace(/\s/, "T")))}</p>
          </>
        );
      },
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (item) => {
        if (item) {
          return <Text>Accepted</Text>;
        } else {
          return <Text type="danger">Declined</Text>;
        }
      },
    },

    {
      title: "Agreement",
      dataIndex: "objid",
      key: "objid",
      render: ({ item, status }) => {
        return (
          status === true && (
            <Button
              type="primary"
              onClick={async () => {
                UserActions.DownloadFileTwo(item);
              }}
            >
              Download
            </Button>
          )
        );
      },
    },
  ];
  return (
    <ContainerWrapper
      title={`Business Terms Active Since ${active ? active.updatedDt : "-"}`}
    >
      {
        <HelmetFyndr
          title="Report"
          imgUrl={""}
          url={`${web}/reports/terms`}
          descr="report"
        />
      }
      <div>
        <Space direction="horizontal" size="large">
          <Input
            onChange={(e) => setRegex(e.target.value)}
            prefix={<SearchOutlined className="prefix" />}
            placeholder="Search"
            style={{ width: 300 }}
          />

          <Button
            onClick={() => {
              setIsStartDate(true);
              setShowCalendar(true);
            }}
          >
            {startDate ? getFormattedDt(startDate) : " Select Start Date"}
          </Button>

          <Button
            onClick={() => {
              setIsStartDate(false);
              setShowCalendar(true);
            }}
          >
            {endDate ? getFormattedDt(endDate) : "Select End Date"}
          </Button>

          <Select
            defaultValue="Location"
            onChange={(value) => {
              setLocation(value);
            }}
            style={{ width: 200 }}
          >
            {filterLocation.map((item, index) => {
              return (
                <Option key={index} value={item.value}>
                  {item.text}
                </Option>
              );
            })}
          </Select>

          <Button type="primary" onClick={doSearch}>
            Search
          </Button>

          {showCalendar && (
            <div>
              <Button
                onClick={() => {
                  isStartDate ? setStartDate() : setEndDate();
                  setShowCalendar(false);
                }}
              >
                Clear
              </Button>
            </div>
          )}
        </Space>
      </div>
      <Modal
        visible={showCalendar}
        footer={null}
        onCancel={() => setShowCalendar(false)}
        title={isStartDate ? "Start Date" : "End Date"}
      >
        <Row justify="center">
          <Calendar
            onChange={onSelect}
            value={isStartDate ? startDate : endDate}
          />
        </Row>
      </Modal>
      <div style={{ marginTop: "2rem" }}>
        <Table
          pagination={{
            pageSize: PAGINATION_LIMIT,
            onChange: changeHandler,
            disabled: true,
            hideOnSinglePage: true,
          }}
          dataSource={searchData}
          columns={columns}
          rowClassName="smallrow"
        />

        {isLoading && <Loader />}

        <div style={{ marginTop: "2rem" }}>
          <Row align="middle" justify="center">
            <Col span={4}>
              {searchData.length > 0 && pageNumber !== 0 && (
                <Button
                  style={{ borderRadius: "5rem" }}
                  disabled={!regex.length}
                  onClick={() => {
                    pageNumber > 0
                      ? setPageNumber(pageNumber - 1)
                      : setPageNumber(pageNumber);
                    doSearch();
                  }}
                >
                  Previous
                </Button>
              )}
            </Col>
            <Col span={4}>
              {searchData.length >= PAGINATION_LIMIT && (
                <Button
                  style={{ borderRadius: "5rem" }}
                  disabled={!regex.length}
                  onClick={() => {
                    searchData >= PAGINATION_LIMIT
                      ? setPageNumber(pageNumber + 1)
                      : setPageNumber(pageNumber);
                    doSearch();
                  }}
                >
                  Next
                </Button>
              )}
            </Col>
          </Row>
        </div>
      </div>
    </ContainerWrapper>
  );
};

export default TermsReport;
