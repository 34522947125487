import React, { useEffect, useState } from "react";
import {
  Button,
  Input,
  Select,
  message,
  Form,
  Space,
  Row,
  Col,
  Image,
} from "antd";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import { FormSchema } from "../../../common";
import UserActions from "../../../actions";
import Loader from "../../../components/Loading/Loader";
import mailIcon from "../../../assets/images/mailIcon.svg";
import phoneIcon from "../../../assets/images/phoneIcon.svg";
import { email, phoneNumber } from "../../../common/config";
import ReactFlagsSelect from "react-flags-select";
import DropdownComponent from "../../Dashboard/DropdownComponent";
const { ContactFormMsg } = FormSchema;
const { Option } = Select;
const FormItem = Form.Item;
const ContactUs = (props) => {
  const { control, handleSubmit, errors, reset, setError } = useForm({
    resolver: yupResolver(ContactFormMsg),
    mode: "onSubmit",
  });
  const [isloading, setIsLoading] = React.useState(false);
  const [selectedCountry, setSelectedCountry] = useState('+1');
  const { onCancel } = props;

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  const onSubmit = async (data) => {
    setIsLoading(true);

    const gmailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.([a-zA-Z0-9-]+)*$/;

    if (!gmailRegex.test(data.email)) {
      setError("email", {
        type: "manual",
        message: "Please enter a valid email address",
      });
      setIsLoading(false);
      return;
    }

    const prefixedPhone =  data.phone;
    if (!/^[0-9]{10}$/.test(prefixedPhone)){
      setError("phone", {
        type: "manual",
        message: "Please enter a valid phone number",
      });
      setIsLoading(false);
      return;
    }

    const response = await UserActions.ContactUs({
      ...data,
      phone: selectedCountry+prefixedPhone,
    });

    setIsLoading(false);

    if (response.success) {
      message.success(response.msg);
      reset({
        name: "",
        email: "",
        phone: "",
        subject: "",
        message: "",
      });
      onCancel(false);
    } else {
      message.warning(response.msg);
    }
  };

  const country = [
    { label: "US", value: "+1" },
    { label: "IN", value: "+91" },
    { label: "AU", value: "+61" },
    { label: "GB", value: "+44" },
    { label: "NZ", value: "+64" },
  ];
  const handleCountryChange = (value) => {
    setSelectedCountry(value);
  };
  return (
    <div className="contact-form-area">
      <Form>
        <Row gutter={5} justify="center" className="bizHome_footer-row">
          <Col align="middle">
            <Space>
              <img
                style={{ height: "20px", width: "20px" }}
                src={mailIcon}
                alt="mailIcon"
              />
              <a
                style={{ marginRight: "10px" }}
                className="phoneNumber"
                href={`mailto:${email}`}
              >
                {email}
              </a>
            </Space>
          </Col>
          <Col align="middle">
            <Space>
              <img
                style={{ height: "20px", width: "20px" }}
                src={phoneIcon}
                alt="phoneIcon"
              />
              <a className="phoneNumber" href={`tel:${phoneNumber}`}>
                {phoneNumber}
              </a>
            </Space>
          </Col>
        </Row>
        <FormItem
          {...formItemLayout}
          label="Name"
          help={errors.name?.message}
          validateStatus={errors.name ? "error" : "success"}
        >
          <Controller
            as={
              <Input
                style={{
                  background: "#fff",
                  overflow: "hidden",
                  borderRadius: 5,
                }}
                type="text"
                className="form-control "
                placeholder="Name"
              />
            }
            name="name"
            control={control}
            rules={{ required: true }}
          />
        </FormItem>
        <FormItem
          {...formItemLayout}
          label="Email"
          help={errors.email?.message}
          validateStatus={errors.email ? "error" : "success"}
        >
          <Controller
            as={
              <Input
                style={{
                  background: "#fff",
                  overflow: "hidden",
                  borderRadius: 5,
                }}
                type="email"
                className="form-control "
                placeholder="Email"
              />
            }
            name="email"
            control={control}
            rules={{ required: true }}
          />
        </FormItem>
        <FormItem
          {...formItemLayout}
          label="Phone"
          help={errors.phone?.message}
          validateStatus={errors.phone ? "error" : "success"}
        >
           <Controller
            render={({ onChange, value }) => (
              <Space>
                <Select  value={selectedCountry} onChange={handleCountryChange}>
                  {country.map((country) => (
                    <Option key={country?.label} value={country?.value}>
                     {country?.value}
                    </Option>
                  ))}
                </Select>
                <Input
                  style={{
                    background: "#fff",
                    overflow: "hidden",
                    borderRadius: 5,
                  }}
                  type="text"
                  className="form-control "
                  placeholder="Phone"
                  value={value}
                  onChange={(e) => onChange(e.target.value)}
                />
              </Space>
            )}
            name="phone"
            control={control}
            rules={{ required: true }}
          />
        </FormItem>
        <FormItem
          {...formItemLayout}
          label="Subject"
          help={errors.subject?.message}
          validateStatus={errors.subject ? "error" : "success"}
        >
          <Controller
            as={
              <Input
                style={{
                  background: "#fff",
                  overflow: "hidden",
                  borderRadius: 5,
                }}
                type="text"
                className="form-control "
                placeholder="Subject"
              />
            }
            name="subject"
            control={control}
            rules={{ required: true }}
          />
        </FormItem>
        <FormItem
          {...formItemLayout}
          label="Message"
          help={errors.message?.message}
          validateStatus={errors.message ? "error" : "success"}
        >
          <Controller
            as={
              <Input.TextArea
                style={{
                  background: "#fff",
                  overflow: "hidden",
                  borderRadius: 5,
                }}
                className="form-control  "
                rows={5}
                placeholder="Message"
                defaultValue={""}
              />
            }
            name="message"
            control={control}
            rules={{ required: true }}
          />
        </FormItem>
      </Form>
      <Row align="middle" justify="end">
        <Col>
          <Space direction="horizontal" size="large">
            <Button
              onClick={() => {
                reset({
                  name: "", // Clear the value for each field individually
                  email: "",
                  phone: "",
                  subject: "",
                  message: "",
                });
                onCancel(false);
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                handleSubmit(
                  (data) => onSubmit(data, onCancel)
                  // (err) => message.error(err)
                )();
              }}
              type="primary"
              disabled={isloading}
            >
              Submit {isloading && <Loader />}
            </Button>
          </Space>
        </Col>
      </Row>
    </div>
  );
};
export default ContactUs;
