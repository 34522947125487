import React, { useEffect, useState } from "react";
import "./floatingButton.scss";
import "antd/dist/antd.css"

import { Image, Modal, Row, Col } from "antd";
import { isMobile } from "../../common/util";
import closeBtn from "../../assets/images/closeBtn.svg";
import modalImage from "../../assets/images/modalImage.png";
import referImg from "../../assets/images/referImg.svg";
import copyImg2 from "../../assets/images/copyImg2.svg";
import clipboard_black from "../../assets/images/clipboard_black.png";
import floatingBtnBackground from "../../assets/images/floatingBtnBackground.svg";
import { useSelector } from "react-redux";
import { Actions } from "../../common";
import getEnvVars from "../../environment";

const { ROLE_FYNDR } = Actions;
const { web } = getEnvVars();
const FloatingButton = (props) => {
  const { hideButton } = props
  console.log("" + window.location.pathname);

  const {
    indv,
    indv: { entityType },
    referral: { business_referral_amount, customer_referral },
  } = useSelector(({ auth }) => auth && auth);

  const { isMasquerade } = useSelector(({ admin }) => admin);
  const isBottom = useSelector(state => state.isBottom.isBottom);
  console.log(
    "business_referral_amount",
    business_referral_amount,
    customer_referral
  );
  const [isCopied, setIsCopied] = useState(false);
  const [allVisible, setAllVisible] = useState(false);
  const [modelOpen, setModalOpen] = useState(false);
  const [isFloatingVisible, setIsFloatingVisible] = useState(true);

  const copyTextToClipboard = () => {
    const referralCode = decodeURIComponent(indv?.referralCode);
    const urlWithReferralCode = `${web}/register?referralCode=${referralCode}`;

    navigator.clipboard
      .writeText(urlWithReferralCode)
      .then(() => {
        console.log("Text copied to clipboard:", urlWithReferralCode);
      })
      .catch((error) => {
        console.error("Error copying text:", error);
      });
    setIsCopied(true);

    setTimeout(() => {
      setIsCopied(false);
    }, 5000);
  };

  const handleImageClick = () => {
    setIsFloatingVisible(false);
  };

  useEffect(() => {
    console.log("modelOpen", modelOpen);
  }, [modelOpen])

  const closeIcon = (
    <img src={closeBtn} alt="closeBtn" className="closeIconBtnAllReviewPopUp" />
  );
  return (
    <div className="floating-button-container no-print" style={{bottom: isBottom ? "83px" : "60px"}} >
      {indv.indvid && entityType !== ROLE_FYNDR && !isMasquerade && !modelOpen && !allVisible && isFloatingVisible &&(
       <div className="relative-container">
        <img src={floatingBtnBackground} alt="" className="floating-background-image" onClick={handleImageClick}/> 
        <div>
          <button
            className="floating-button"
            onClick={() => {
              setModalOpen(true);
              setAllVisible(true);
              // hideButton(true)
            }}
          >
            <div>
              <img src={referImg} alt="Button Image" className="floatingImg" />
              <div className="discountNumber">
                <p>{`Get up to`}<br />{`$`}{`${business_referral_amount?.value[0]?.countryValue}`}</p>
              </div>
            </div>
          </button>
          <div className="referName_FloatButton">
            <p>Refer</p>
          </div>
        </div>
        </div>
        
      )}

      {!isMobile() ? (
        <Modal
          open={allVisible}
          footer={null}
          closeIcon={closeIcon}
          onCancel={() => {
            setAllVisible(false);
            setModalOpen(false);
            // hideButton(false)
          }}
          title="Refer"
          className="referModal"
        >
          <div>
            <Row className="referModalRow" justify={"center"}>
              <Col className="referModalCol">
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Image
                    src={modalImage}
                    preview={false}
                    className="modalImage"
                  />
                </div>
                <div className="referModalText">
                  <p
                    className="referModalPara"
                    style={{ paddingLeft: "1rem", paddingRight: "1rem" }}
                  >
                    {`Earn $${customer_referral?.value[0]?.countryValue} Fyndr cash instantly when you refer a friend and $${business_referral_amount?.value[0]?.countryValue} when you refer a business.`}</p>

                </div>
              </Col>
            </Row>
            {isCopied ? (
              <div className="referModalCopyRefer2">
                <Image src={copyImg2} className="copyTextIcon2" preview={false} />
                <p className="referModalPara2">Copied Succesfully</p>
              </div>
            ) : (
              <Row className="referModalCopyRefer">
                <Col xs={15} sm={15} md={15} lg={15} xl={15} xxl={15}>
                  <p className="referModalParaCopyText">
                    Copy to share referral code URL
                  </p>
                </Col>
                <Col
                  style={{ display: "flex", justifyContent: "flex-end" }}
                  xs={5} sm={5} md={5} lg={5} xl={5} xxl={5}
                >
                  <Image
                    src={clipboard_black}
                    className="copyTextIcon"
                    preview={false}
                    onClick={() => copyTextToClipboard()}
                  />
                </Col>
              </Row>
            )}
          </div>
        </Modal>
      ) : (
        <Modal
          // transitionName="none"
          // maskTransitionName="none"
          open={allVisible}
          footer={null}
          closeIcon={closeIcon}
          onCancel={() => {
            setAllVisible(false);
            setModalOpen(false);
          }}
          title="Refer"
          className="referModalMObileView"
        >
          <div>
            <Row className="referModalRow" justify={"center"}>
              <Col className="referModalCol">
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Image
                    src={modalImage}
                    preview={false}
                    className="modalImage"
                  />
                </div>
                <div className="referModalTextMobileView">
                  <p>
                    {`Earn $${customer_referral?.value[0]?.countryValue} Fyndr cash instantly when you refer a friend and $${business_referral_amount?.value[0]?.countryValue} when you refer a business.`}</p>
                </div>
              </Col>
            </Row>
            {isCopied ? (
              <div className="referModalCopyRefer2_MobileView">
                <Image src={copyImg2} className="copyTextIcon2" preview={false} />
                <p className="referModalPara2_MobileView">Copied Succesfully</p>
              </div>
            ) : (
              <div className="referModalCopyRefer_MobileView">
                <p className="referModalPara_MobileView">
                  Copy to share referral code URL
                </p>
                <Image
                  src={clipboard_black}
                  className="copyTextIcon"
                  preview={false}
                  onClick={() => copyTextToClipboard()}
                />
              </div>
            )}
          </div>
        </Modal>
      )}
    </div>
  );
};

export default FloatingButton;