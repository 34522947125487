/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Row, Col, Space, Select, Input, Button, message } from "antd";
import UserAction from "../../actions";
import { Theme } from "../../common";
import { ConfirmationModal } from "../../components";

const { Option } = Select;
const PriceRow = ({
  options,
  callback,
  key,
  item,
  bizid,
  catalogueItemId,
  editPress,
  hideEdit,
  currencySymbol,
  deletePress,
}) => {
  const [payload, setPayload] = useState({
    objid: item?.modifier.objid,
    price: item?.price,
  });
  const [valueChanged, setValueChanged] = useState(false);
  const [visible, setVisible] = useState(false);

  const handleChange = (value) => {
    setPayload({ ...payload, objid: value });
  };
  useEffect(() => {
    callback(payload);
  }, [payload]);
  const handleSave = async () => {
    if (payload.objid === undefined) {
      message.error("Please select an item");
      return;
    }
    if (payload.price === undefined) {
      message.error("Price can not be empty");
      return;
    }

    let dispatchPayload = {
      bizid,
      catalogueItemId: catalogueItemId,
      modifierId: payload.objid,
      price: payload.price,
      objid: item ? item.objid : null,
    };
    let resp = await UserAction.addCatalogueModifier(dispatchPayload);
    resp.success ? message.success(resp.message) : message.error(resp.message);
  };

  const handlePriceChange = ({ target: { value } }) => {
    setPayload({ ...payload, price: value });
    if (item) {
      setValueChanged(true);
    }
  };

  return (
    <div style={{ marginTop: "1rem" }} key={catalogueItemId}>
      <Row justify="space-between" gutter={[20, 20]}>
        <Col span={8}>
          <Select
            disabled={item ? true : false}
            defaultValue={item?.modifier?.objid}
            style={{ width: "100%" }}
            onChange={handleChange}
          >
            {options.map((item, key) => {
              return (
                <Option key={key} value={item.objid}>
                  {item.modName} ({item.modType === "addon" ? "Addon" : "Whole"}
                  )
                </Option>
              );
            })}
          </Select>
        </Col>
        <Col span={5}>
          <Input
            prefix={
              <span style={{ color: Theme.greyLight }}>{currencySymbol}</span>
            }
            onChange={(e) => handlePriceChange(e)}
            value={payload?.price}
          />
        </Col>
        <Col span={5}>
          {item && (
            <Input
              bordered={false}
              style={{ color: "#000" }}
              disabled
              value={item?.modifier?.modType === "addon" ? "Addon" : "Whole"}
            />
          )}
        </Col>
        <Col span={5}>
          <Space>
            {!hideEdit && (
              <i
                style={{
                  fontSize: "1.5rem",
                  color: Theme.blue,
                  cursor: "pointer",
                }}
                onClick={editPress}
                class="fas fa-edit"
              />
            )}
            {!hideEdit && (
              <i
                style={{
                  fontSize: "1.5rem",
                  color: Theme.red,
                  cursor: "pointer",
                }}
                onClick={() => setVisible(true)}
                class="fas fa-trash-alt"
              ></i>
            )}
            {(item === undefined || valueChanged) && (
              <Button
                type="primary"
                onClick={() => {
                  handleSave();
                }}
              >
                {valueChanged ? "Update" : "Save"}
              </Button>
            )}
          </Space>
        </Col>
      </Row>
      <ConfirmationModal
        title={`Delete`}
        visible={visible}
        description={"Are you sure, you want to delete it ?"}
        onConfirm={() => {
          try {
            deletePress();
          } catch (error) {
            console.log(error);
          } finally {
            setVisible(false);
          }
        }}
        onCancel={() => setVisible(false)}
      />
    </div>
  );
};

export default PriceRow;
