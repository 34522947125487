/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Row, Col } from "antd";
import { Element } from "react-scroll";
import Images from "../../assets/images/Images";
import { isMobile } from "../../common/util";

const appScheme = "fyndr://";

const isMobileCheck = {
  Android: function () {
    return navigator.userAgent.match(/Android/i);
  },
  BlackBerry: function () {
    return navigator.userAgent.match(/BlackBerry/i);
  },
  iOS: function () {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i);
  },
  Opera: function () {
    return navigator.userAgent.match(/Opera Mini/i);
  },
  Windows: function () {
    return (
      navigator.userAgent.match(/IEMobile/i) ||
      navigator.userAgent.match(/WPDesktop/i)
    );
  },
  any: function () {
    return (
      isMobileCheck.Android() ||
      isMobileCheck.BlackBerry() ||
      isMobileCheck.iOS() ||
      isMobileCheck.Opera() ||
      isMobileCheck.Windows()
    );
  },
};

const App = () => {
  // const [showStore, setShowStore] = useState(false);
  // const [qrid, setQRId] = useState(null);
  // const [catalogueDetails, setCatalogueDetails] = useState(null);
  // const [, setIsLoading] = useState(false);
  // const QRCODEPATH = "/qr/:qrCode";

  if (isMobileCheck.any()) {
    window.location.href = appScheme;
  }
  return (
    <section>
      <Element name="download">
        <div className="download">
          <div className="download_background">
            <Row flex={1} align="middle" justify="center">
              <Col xs={0} lg={12} span={12}>
                <img
                  className="download_image"
                  alt="mobile"
                  src={Images.fyndrMobile}
                />
              </Col>
              <Col
                align={isMobile() ? "left" : "left"}
                xs={24}
                lg={12}
                span={12}
              >
                <div className="download_box">
                  <h1>Download Fyndr</h1>
                  {/* <ul style={{ textAlign: "left" }}>
                    <li>Create as many interaction points</li>
                    <li>
                      Minimize risks by presenting business terms & obtain
                      touch-less consents.
                    </li>
                    <li>Create and present online or in-store offers.</li>
                  </ul> */}
                  <Row justify="start" gutter={6} style={{ marginTop: "3rem" }}>
                    <Col className="download_col" xs={24} lg={12}>
                      {/* <a href="https://play.google.com/store/apps/details?id=fyndr.us&hl=en"> */}
                      <a href="https://play.google.com/store/apps/details?id=com.fyndr.us">
                    
                        <img
                          alt="download"
                          src={Images.googlePlayLogo}
                          style={{ maxWidth: "100%" }}
                        />
                      </a>
                    </Col>
                    <Col className="download_col" xs={24} lg={12}>
                      <a href="https://apps.apple.com/in/app/fyndr/id1528140419">
                        <img
                          alt="download"
                          src={Images.appleLogo}
                          style={{ maxWidth: "100%" }}
                        />
                      </a>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Element>
    </section>
  );
};

export default App;
