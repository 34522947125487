import React,{useRef,useState} from "react";
import { Modal } from "antd";
import JsxParser from "react-jsx-parser";
import { useSelector } from "react-redux";
import { getCurrentDateTime, parseAddress } from "../../common/util";


const Preview = (props) => {
  const refrence = useRef(null)
  const {
    indv: { bizName, address, mainLogo },
    settings: { terms_header, terms_footer },
  } = useSelector(({ auth }) => auth);

  const { web_list } = useSelector(({ core }) => core);
  const { visible, callBack } = props;
  const [,setvisibleref] = useState(false)

  
  return (
    <Modal
      width={900}
      onCancel={() => callBack(false)}
      onOk={() => callBack(false)}
      title="Businees Terms Preview"
      visible={visible}
    >
      <div>
        <div className="bussiness_header" >
          <img
            alt="Main logo"
            src={`${mainLogo}?m=${new Date().getTime()}`}
            className="bussiness_mainLogo"
          />
        </div>
        <div className="bussiness_header-text" >
          <h1>Business Terms of Service - Agreement</h1>
        </div>

        <div>
          Business Name: <strong>{bizName} </strong> <br />
          Registered Business Address: {parseAddress(address)} <br />
          Business Terms Effective: {getCurrentDateTime()} <br />
          <br />
        </div>
        <div ref={el=>{refrence.current=el 
        setvisibleref(!!el)}} id="desc">
       
       
          {terms_header&&<JsxParser jsx={terms_header.replace(/\\n/g,"<br/>")} />}
        
          <br />
        </div>
        <div className="bussiness_weblist" >
          <div id="terms">
            <JsxParser jsx={web_list} />
          </div>
        </div>
        <div>
          <br />
        
        {terms_footer &&   <JsxParser jsx={terms_footer.replace(/\\n/g,"<br/>")} />}
        </div>
      </div>
    </Modal>
  );
};

export default Preview;
