/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Col,
  List,
  Alert,
  Row,
  Input,
  message,
  Typography,
  Radio,
  Space,
} from "antd";
import Button from "antd-button-color";
import Loader from "../../components/Loading/Loader";
import { useSelector, useDispatch } from "react-redux";

import { Actions } from "../../common";
import { Container as ContainerWrapper, ListItem } from "../../components";
import UserActions from "../../actions";
import Edit from "./Guide";
import ModalVerifyMobileUI from "../../components/ModalVerifyPhone/ModalVerifyMobileUI";
import HelmetFyndr from "../../components/HelmetFyndr";
import getEnvVars from "../../environment";
const { SELECTED_COLLECTION, FETCH_ITEMS } = Actions;
const web = getEnvVars();
const CatalogueList = (props) => {
  const { Text } = Typography;

  const { catalogue } = useSelector(({ catalogue }) => {
    return catalogue;
  });
  const {
    indv: { bizid, merchantId },
  } = useSelector(({ auth }) => auth);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [showedit, setShowEdit] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [selectedCatalogues, setSelectedCatalogues] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [catalogueId, setCatalogueId] = useState();
  const [editUrlVisible, setEditUrlVisible] = useState(false);
  const [fetchLocation, setFetchLocation] = useState(false);
  const [newUrl, setNewUrl] = useState("");
  const [locations, setLocations] = useState([]);
  const [value, setValue] = useState();

  const dispatch = useDispatch();

  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };
  const getCatalogue = async () => {
    setIsLoading(true);
    await UserActions.fetchCatalogue({ bizid });
    setIsLoading(false);
  };

  const onCatalogue = async () => {
    if (value !== null) {
      const resp = await UserActions.getCatalogueByLoc({
        locId: value,
      });
      if (!resp.success) {
        message.error("Error retrieving catalogue");
      } else {
        //  onCart(details.locId);

        props.history.push(`/catalogue/store/${selectedCatalogues.url}`, {
          catalogueDetail: resp.payload,
          // fromBizAccount: true,
        });
      }
    }
  };
  useEffect(() => {
    setData([]);
    dispatch({
      type: FETCH_ITEMS,
      payload: [],
    });
    getCatalogue();
  }, []);
  useEffect(() => {
    if (catalogue.length > 0) {
      catalogue?.sort((a, b) => a?.name?.localeCompare(b?.name));

      setData(catalogue);
    }
  }, [catalogue && catalogue]);
  const updateUrl = async () => {
    let resp = await UserActions.UpdateStoreURL({
      catalogueId: catalogueId,
      newUrl: newUrl,
    });
    if (resp.success === true) {
      message.success(resp.msg);
      getCatalogue();
    } else {
      message.error(resp.msg);
      setEditUrlVisible();
    }
  };
  const fetchStorelocations = async (url) => {
    let resp = await UserActions.fetchStoreLoc(url);
    if (resp.success) {
      console.log("fetchStorelocations>>>", resp.data);
      setLocations(resp.data.locations);
      setFetchLocation(true);
    }
  };
  const handleOnClick = () => {
    let regex = new RegExp(/^[a-zA-Z0-9-" "]+$/);
    if (newUrl === "") {
      message.error("Please type new url to proceed");
    } else if (!regex.test(newUrl)) {
      message.error("Special Characters not allowed");
    } else {
      updateUrl();
      setEditUrlVisible(false);
    }
  };
  return (
    <>
      {merchantId == null && (
        <Alert
          message="Stripe Integration Required"
          showIcon
          description="Please complete your payment integration with Stripe to enable payments for catalogue items."
          type="info"
          action={
            <Button
              onClick={() => {
                props.history.push("/merchant/stripe_connect");
              }}
              size="large"
              type="warning"
            >
              Setup Stripe
            </Button>
          }
        />
      )}
      {showedit ? (
        <Edit
          isEdit={isEdit}
          item={selectedItem}
          callback={(value) => setShowEdit(value)}
        />
      ) : (
        <ContainerWrapper
          headerOption={
            <Row>
              <Space direction="horizontal">
                <Col span={3}>{isLoading && <Loader />}</Col>
                <Col span={4}>
                  <Button
                    type="primary"
                    onClick={() => {
                      setShowEdit(true);
                      setSelectedItem(null);
                      setIsEdit(false);
                    }}
                    // disabled={isLoading}
                  >
                    Add Store
                  </Button>
                </Col>
              </Space>
            </Row>
          }
          title={"Store"}
        >
          {
            <HelmetFyndr
              title="Store"
              imgUrl={""}
              url={`${web}/catalogue/list`}
              descr="catalogue list"
            />
          }
          {data?.length > 0 && (
            <List
              itemLayout="horizontal"
              dataSource={data}
              renderItem={(item, key) => (
                <ListItem
                  hideDelete={true}
                  onClick={() => {
                    setSelectedItem(item);
                    dispatch({
                      type: SELECTED_COLLECTION,
                      payload: item,
                    });
                    setShowEdit(true);
                    setIsEdit(true);
                  }}
                  onStoreUrlClick={() => {
                    setCatalogueId(item.objid);
                    setSelectedCatalogues(item);
                    fetchStorelocations(item.url);
                  }}
                  onEditClick={() => {
                    setNewUrl("");
                    setCatalogueId(item.objid);
                    setSelectedCatalogues(item);
                    setEditUrlVisible(true);
                  }}
                  item={item}
                />
              )}
            />
          )}
        </ContainerWrapper>
      )}
      <ModalVerifyMobileUI
        visible={editUrlVisible}
        width="20%"
        className="editUrl_modal"
        title={<h5 style={{ textAlign: "left" }}>Edit URL</h5>}
        onCancel={() => setEditUrlVisible(false)}
        onOk={() => handleOnClick()}
        modalText={`URL: ${selectedCatalogues.url}`}
        secondModalText={
          <Row
            justify="space-between"
            align="middle"
            style={{ marginTop: "-1.5rem" }}
          >
            <Input
              onChange={(e) => setNewUrl(e.target.value)}
              value={newUrl}
              placeholder="Enter URL"
              className="verifymobilemodal_input"
            />
          </Row>
        }
      />

      <ModalVerifyMobileUI
        visible={fetchLocation}
        title={<h5 style={{ textAlign: "center" }}>Locations</h5>}
        onCancel={() => setFetchLocation(false)}
        onOk={() => onCatalogue()}
        modalText={`URL: ${selectedCatalogues.url}`}
        input={
          <Row justify="space-between" align="middle">
            {locations.length > 0 ? (
              locations.map((item) => (
                <Col style={{ width: "100%", padding: "1%" }}>
                  <h6 className="storeUrl_text">
                    <Radio.Group onChange={onChange} value={value}>
                      <Radio value={item.objid}>
                        <Text className="storeUrl_text">{`${item?.addressLine1}, ${item?.addressLine2}, ${item?.city}, ${item?.postalCode}`}</Text>
                      </Radio>
                    </Radio.Group>
                  </h6>
                </Col>
              ))
            ) : (
              <Text>{"No location found"}</Text>
            )}
          </Row>
        }
      />
    </>
  );
};

export default CatalogueList;
