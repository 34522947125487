import {
  Row,
  Col,
  Breadcrumb,
  Typography,
  Input,
  Space,
  Select,
  Divider,
  List,
  Card,
  Switch,
} from "antd";
import React, { useState } from "react";
import PulseLoader from "react-spinners/PulseLoader";
import "../../../sass/styles/fyndr/_promo.scss";
import { useHistory } from "react-router-dom";
import PromoCode1 from "../../../assets/images/PromoCode1.png";
import PromoCode2 from "../../../assets/images/PromoCode2.png";
import { SearchOutlined } from "@material-ui/icons";
import { EditOutlined } from "@ant-design/icons";
const { Search } = Input;
const { Text } = Typography;

const PromoCode = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const data = [
    {
      title: "FYNRD35PROMO",
      expiryDate: "24 August 2023",
      cash: "$35",
      users: "256",
    },
    {
      title: "FYNRD35PROMO",
      expiryDate: "24 August 2023",
      cash: "$35",
      users: "256",
    },
    {
      title: "FYNRD35PROMO",
      expiryDate: "24 August 2023",
      cash: "$35",
      users: "256",
    },
  ];
  const onSearch = () => {};
  const handleSelectChange = (e) => {
    if (e === "registration") {
      history.push("/admin/promo/registration");
    } else if (e === "promotion") {
      history.push("/admin/promo/promotion");
    }
  };

  return (
    <div className="admin_container no-breadcrumb container-mw-xl chapter">
      <div className="quickLoader">
        <PulseLoader color={"#3F9AF7"} loading={loading} size={10} />
      </div>

      <article className="article">
        <Row justify="space-between">
          <Col>
            <Breadcrumb>
              <Breadcrumb.Item>Admin</Breadcrumb.Item>
              <Breadcrumb.Item>Promo Code</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>

        <div
          style={{
            background: "#fff",
            marginTop: "2rem",
            padding: "24px 24px 24px 24px",
          }}
        >
          <Row>
            <Col span={10}>
              <span className="promo-heading">Active Promo Codes</span>
            </Col>
            <Col
              span={14}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Space>
                <Input
                  style={{ borderRadius: "10px", height: "46px" }}
                  placeholder="Search"
                  suffix={
                    <SearchOutlined
                      style={{
                        color: "#999999",
                        height: "2rem",
                        width: "2rem",
                      }}
                    />
                  }
                />
                <Select
                  className="promo-drop-down"
                  style={{ width: "200px" }}
                  onChange={handleSelectChange}
                  placeholder="Create promo"
                  options={[
                    {
                      value: "registration",
                      label: "For Registration",
                    },
                    {
                      value: "promotion",
                      label: "For Promotion",
                    },
                  ]}
                />
              </Space>
            </Col>
          </Row>

          <div
            style={{
              marginTop: "2rem",
            }}
            // className="horizontal-div-active"
          >
            <Row>
              <div
                // style={{
                //   width: "185px",
                //   height: "256px",
                //   background: "#ffffff",
                //   top: "7px",
                //   gap: "0px",
                //   borderRadius: "10px",
                //   border: "1px 0px 0px 0px",
                //   opacity: "0px",
                //   border: "1px solid #d3d6e1",
                //   position: "relative",
                //   overflow: "hidden",
                //   marginBottom: "12px",
                // }}
                className="active-promo"
              >
                <div
                  style={{
                    // position: "absolute",
                    // top: "10px",
                    // left: "0",
                    // right: "0",
                    // display: "flex",
                    // justifyContent: "space-between",
                    // padding: "0 10px",
                    // opacity: "0",
                    // transition: "opacity 0.3s ease",
                  }}
                  className="promo-buttons"
                >
                  <button
                    // style={{
                    //   background: "transparent",
                    //   color: "white",
                    //   border: "none",
                    //   padding: "5px 10px",
                    //   cursor: "pointer",
                    //   transform: "translateX(-100%)",
                    //   transition: "transform 0.3s ease",
                    // }}
                    className="edit-button"
                  >
                    {" "}
                    <EditOutlined />{" "}
                  </button>
                  <button
                    // style={{
                    //   background: "transparent",
                    //   color: "white",
                    //   border: "none",
                    //   padding: "5px 10px",
                    //   cursor: "pointer",
                    //   transform: "translateX(-100%)",
                    //   transition: "transform 0.3s ease",
                    // }}
                     className="active-button"
                  >
                    <Switch defaultChecked style={{ background: "#30D158" }} />{" "}
                  </button>
                </div>
                <img
                  style={{
                    width: "184px",
                    height: "185px",
                    bordeRadius: "10px",
                    zIndex: "999",
                  }}
                  // className="active-promo-image"
                  src={PromoCode1}
                />
                <div style={{ padding: "12px" }}>
                  <Row>
                    <Col span={24}>
                      <Text
                        // style={{ color: "#257cdb",
                        //   color: "#257cdb",
                        //   fontFamily: "Roboto",
                        //   fontSize: "16px",
                        //   fontWeight: "500",
                        //   lineHeight: "18.75px",
                        //   textAlign: "left",
                        //   color: "#257cdb",
                        //  }}
                        className="active-promo-heading"
                      >
                        FYNDR35PROMO
                      </Text>
                    </Col>
                    <Col span={24}>
                      <Text
                      // style={{
                      //   fontFamily: "Roboto",
                      //   fontSize: "14px",
                      //   fontWeight: "400",
                      //   lineHeight: "20px",
                      //   textAlign: "left",
                      //   color: "#666666",
                      // }}
                       className="active-promo-date"
                       >
                        Valid till : 24 August 2024
                      </Text>
                    </Col>
                  </Row>
                </div>
              </div>

              <div
                className="active-promo"
                style={{
                //   width: "185px",
                //   height: "256px",
                //   background: "#ffffff",
                //   top: "7px",
                //   gap: "0px",
                //   borderRadius: "10px",
                //   border: "1px 0px 0px 0px",
                //   opacity: "0px",
                //   border: "1px solid #d3d6e1",
                //   position: "relative",
                //   overflow: "hidden",
                //   marginBottom: "12px",
                  marginLeft: "16px",
                }}
              >
                <div
                  // style={{
                  //   position: "absolute",
                  //   top: "10px",
                  //   left: "0",
                  //   right: "0",
                  //   display: "flex",
                  //   justifyContent: "space-between",
                  //   padding: "0 10px",
                  //   opacity: "0",
                  //   transition: "opacity 0.3s ease",
                  // }}
                  className="promo-buttons"
                >
                  <button
                    // style={{
                    //   background: "transparent",
                    //   color: "white",
                    //   border: "none",
                    //   padding: "5px 10px",
                    //   cursor: "pointer",
                    //   transform: "translateX(-100%)",
                    //   transition: "transform 0.3s ease",
                    // }}
                    className="edit-button"
                  >
                    {" "}
                    <EditOutlined />{" "}
                  </button>
                  <button
                    // style={{
                    //   background: "transparent",
                    //   color: "white",
                    //   border: "none",
                    //   padding: "5px 10px",
                    //   cursor: "pointer",
                    //   transform: "translateX(-100%)",
                    //   transition: "transform 0.3s ease",
                    // }}
                    className="active-button"
                  >
                    <Switch defaultChecked style={{ background: "#30D158" }} />{" "}
                  </button>
                </div>
                <img
                  // style={{
                  //   width: "184px",
                  //   height: "185px",
                  //   bordeRadius: "10px",
                  //   zIndex: "999",
                  // }}
                  className="active-promo-image"
                  src={PromoCode2}
                />
                <div style={{ padding: "12px" }}>
                  <Row>
                    <Col span={24}>
                      <Text
                        // style={{
                        //   color: "#257cdb",
                        //   fontFamily: "Roboto",
                        //   fontSize: "16px",
                        //   fontWeight: "500",
                        //   lineHeight: "18.75px",
                        //   textAlign: "left",
                        //   color: "#257cdb",
                        // }}
                        className="active-promo-heading"
                      >
                        FYNDR35PROMO
                      </Text>
                    </Col>
                    <Col span={24}>
                      <Text
                        // style={{
                        //   fontFamily: "Roboto",
                        //   fontSize: "14px",
                        //   fontWeight: "400",
                        //   lineHeight: "20px",
                        //   textAlign: "left",
                        //   color: "#666666",
                        // }}
                        className="active-promo-date"
                      >
                        Valid till : 24 August 2024
                      </Text>
                    </Col>
                  </Row>
                </div>
              </div>
            </Row>
          </div>

          <Divider style={{ background: "#D3D6E1", margin: "12px 0px" }} />

          <div>
            <Text className="history-heading">History</Text>
            <List
              itemLayout="horizontal"
              className="list-history"
              dataSource={data}
              renderItem={(item) => (
                <List.Item>
                  <Card
                    className="history-card"
                    style={{
                      width: "100%",
                      borderRadius: "10px",
                      border: "1px solid #D3D6E1",
                    }}
                  >
                    <List.Item.Meta
                      avatar={
                        <img
                          src={PromoCode2}
                          alt="promo"
                          style={{
                            width: "74px",
                            height: "74px",
                            borderRadius: "10px",
                          }}
                        />
                      }
                      description={
                        <div
                          style={{
                            padding: "12px 16px 0px 0px",
                            height: "74px",
                          }}
                        >
                          <Row>
                            <Col span={12}>
                              <Text
                                style={{ color: "#257cdb" }}
                                className="active-promo-heading"
                              >
                                {item.title}
                              </Text>
                            </Col>
                            <Col
                              span={12}
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <Text className="history-content">
                                Sign up using this promo code and get{" "}
                                <Text style={{ color: "blue" }}>
                                  {item.cash}
                                </Text>{" "}
                                Fyndr cash
                              </Text>
                            </Col>
                          </Row>
                          <Row style={{ marginTop: "1.7vh" }}>
                            <Col span={12}>
                              <Text 
                              className="active-promo-date">
                                Expired on: {item.expiryDate}
                              </Text>
                            </Col>
                            <Col
                              span={12}
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <Text className="active-promo-date">
                                Users Registered: {item.users}
                              </Text>
                            </Col>
                          </Row>
                        </div>
                      }
                    />
                  </Card>
                </List.Item>
              )}
            />
          </div>
        </div>
      </article>
    </div>
  );
};

export default PromoCode;