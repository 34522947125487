/* eslint-disable array-callback-return */
import { Actions } from "../common";

const {
  ACCOUNT_FOUND,
  LOG_OUT,
  LOADER_VISIBLE,
  SETTINGS_FOUND,
  LOCATION_EDITED,
  LOCATION_SELECTED,
  TERMS_FOUND,
  TERMS_NOT_FOUND,
  UPDATE_MAIN_LOGO,
  UPDATE_QR_LOGO,
  ACCOUNT_EDITED,
  CARDS_LISTED,
  SET_ROUTE,
  UPDT_ONBOARDED,
  LOGIN_SUCCESS,
  UPDATE_CATALOGUE_LOCATION,
  ISACTIVE,
  STRIPE_DETAILS_UPDATE,
  REFFERAL_WALLET,
  REFFERAL_CODE,
  END_DATE,
  ROLE_TYPE,
  SETTINGS_FOUND_PLATFORM
} = Actions;

const INITIAL_STATE = {
  indv: {
    accountStatus: "ACTIVE",
    entityRole: null,
    qrcode: null,
    linkrefferalcode: null,
    token: null,
    mode: null,
    email: null,
    displayName: null,
    bizName: null,
    bizid: null,
    address: null,
    locations: [],
    indvid: null,
    fyndrHandle: null,
    fname: null,
    lname: null,
    website: null,
    addonUrl: null,
    bizType: null,
    mainLogo: null,
    qrLogo: null,
    deviceToken: null,
    subs: {
      maxLoc: 0,
      subscribedTo: [],
    },
    cards: null,
    qrPrefix: null,
    days_default: {},
    referralCode: null,
    currency: "USD",
    currencySymbol: "$",
    showBiz: true,
    onBoarded: false,
    merchantId: null,
    payoutsEnabled: false,
    chargesEnabled: false,
    merchantAllowed: false,
    googleCalendarPermissionGranted: false,
    userTimeZone : ""
  },
  biz: false,
  activeLocation: {},
  terms: { active: null, draft: null, review: null },
  settings: {
    days_default: {},
    free_limits: { loc: 1, interaction: 100 },
  },
  referral: {
    business_referral_amount: {
      value: [{}]
    },
    customer_referral: {
      value: [{}]

    }
  },
  endDate: null,
  roleType: null,
  route: null,
  loader: false,
};

function AuthReducer(state = INITIAL_STATE, action) {
  if (action.type === "undefined") {
    return state;
  }
  let indv = {};

  switch (action.type) {
    case LOGIN_SUCCESS:
      return { ...state, indv: action.payload };
    case ACCOUNT_FOUND: {
      indv = action.payload;
      indv.days_default = action.payload.days_default
        ? action.payload.days_default
        : state.indv.days_default;
      return { ...state, indv };
    }
    case ACCOUNT_EDITED:
      indv = action.payload;
      indv.days_default = action.payload.days_default
        ? action.payload.days_default
        : state.indv.days_default;
      indv.subs = action.payload.subs ? action.payload.subs : state.indv.subs;
      return { ...state, indv };
    case LOCATION_EDITED:
      return { ...state, indv: { ...state.indv, locations: action.payload } };
    case LOCATION_SELECTED:
      return { ...state, activeLocation: action.payload };
    case SETTINGS_FOUND:
      return { ...state, settings: action.payload };
    case SETTINGS_FOUND_PLATFORM:
      return { ...state, settingsPlatform: action.payload };
    case REFFERAL_WALLET:
      return { ...state, referral: action.payload };
    case REFFERAL_CODE:
      return { ...state, linkrefferalcode: action.payload };
    case END_DATE:
      return { ...state, endDate: action.payload };
    case ROLE_TYPE:
      return { ...state, roleType: action.payload };

    case LOG_OUT:
      return INITIAL_STATE;
    case SET_ROUTE:
      return { ...state, route: action.payload };
    case ISACTIVE:
      return {
        ...state,
        indv: { ...state.indv, accountActive: action.payload },
      };
    case LOADER_VISIBLE:
      return { ...state, loader: action.payload };
    case TERMS_FOUND:
      return { ...state, terms: action.payload };
    case CARDS_LISTED:
      return { ...state, indv: { ...state.indv, cards: action.payload } };
    case TERMS_NOT_FOUND:
      return { ...state, terms: INITIAL_STATE.terms };
    case UPDATE_MAIN_LOGO:
      return { ...state, indv: { ...state.indv, mainLogo: action.payload } };
    case UPDATE_QR_LOGO:
      return { ...state, indv: { ...state.indv, qrLogo: action.payload } };
    case UPDT_ONBOARDED:
      const { detailsSubmitted, chargesEnabled, payoutsEnabled, merchantID } =
        action.payload;
      return {
        ...state,
        indv: {
          ...state.indv,
          onBoarded: detailsSubmitted,
          merchantID,
          chargesEnabled,
          payoutsEnabled,
        },
      };
    case UPDATE_CATALOGUE_LOCATION: {
      let tempLocation = state.indv.locations;
      const {
        payload: { locations, name },
      } = action;

      let withNameLocation = tempLocation.filter(
        (row, key) => row.catalogueName === name
      );
      let otherLocations = tempLocation.filter(
        (row, key) => row.catalogueName !== name
      );
      withNameLocation.map((row, key) => {
        if (locations.includes(row.objid)) {
          row.catalogueName = name;
        } else {
          row.catalogueName = null;
        }
      });
      otherLocations.map((row, key) => {
        if (locations.includes(row.objid)) {
          row.catalogueName = name;
        }
      });
      let finalLocations = [...withNameLocation, ...otherLocations];
      finalLocations.sort((a, b) => a.catalogueName < b.catalogueName);
      return { ...state, indv: { ...state.indv, locations: finalLocations } };
    }
    case STRIPE_DETAILS_UPDATE: {
      const { detailsSubmitted, chargesEnabled, payoutsEnabled, merchantId } =
        action.payload;
      return {
        ...state,
        indv: {
          ...state.indv,
          onBoarded: detailsSubmitted,
          chargesEnabled: chargesEnabled,
          payoutsEnabled: payoutsEnabled,
          merchantId: merchantId,
        },
      };
    }
    default:
      return state;
  }
}
export default AuthReducer;
