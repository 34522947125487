import React, { useState, useEffect, useRef } from "react";
import {
  Breadcrumb,
  Col,
  Divider,
  Row,
  message,
} from "antd";

import { useSelector } from "react-redux";
import CardComponent from "../../../components/Dashboard/Card";
import DropdownComponent from "../../../components/Dashboard/DropdownComponent";
import ApiEndpoints from "../../../common/ApiEndpoints";
import ActionsStrings from "../../../common/ActionsStrings";
import { useHistory } from "react-router-dom"
import { isMobile } from "../../../common/util";
import Tour from "reactour";

import { selector } from "recoil";

export default function AdminDashboard(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);

  const [country, setCountry] = useState([
    { value: "US", label: "US" },
    { value: "India", label: "INDIA" },
  ]);
  const [date, setDate] = useState([
    { value: "Today", label: "Today" },
    { value: "Month", label: "Month" },
    { value: "Year", label: "Year" }])
  const history = useHistory();
  const { entityType } = useSelector(({ auth }) => {
    return auth.indv ? auth.indv : auth;
  });

  useEffect(() => {

    if (entityType !== ActionsStrings.ROLE_FYNDR) {
      history.push("/billing/transactions")
    }
    else if (isMobile()) {
      history.push("/");
      message.info("To access account please switch to desktop view")
    }

  }, [])
  const dashboardDetails = [
    { title: "Campaign", url: ApiEndpoints.campaignStatistics },
    { title: "Customers", url: ApiEndpoints.userStatistics },
    { title: "Revenue", url: ApiEndpoints.revenueStatistics },
    { title: "" }
  ]

  const cardContentDat = [
    "You can view details like total number of active campaigns, online and in-store campaigns from here", 
    "You can view all details related to Customers from here. You can click on these links and see a detailed list about the same.", 
    "The revenue details are displayed here like Total revenue, Offer revenue and Fyndr Cash"
  ]

  const tourConfig = [
    {
      selector:'[adminDashBoard-tour="dropDown"]',
      content:"Select Country from here"
    },
    ...dashboardDetails
      .filter(item => item.title !== "" && item.url !== "" && item.value !== "")
      .map((item, index) => ({
        selector: `[adminDashBoard-tour="${item.title}"]`,
        content: `This is ${item.title} card. ${cardContentDat[index]}`
      }))
      ,
  ];
  
  useEffect(() => {
    const isTourOpen = JSON.parse(localStorage.getItem("tourOpenDashboard"));
    if (isTourOpen !== null) {
      setIsOpen(JSON.parse(isTourOpen));
    } else {
      localStorage.setItem("tourOpenDashboard", JSON.stringify(true));
      setIsOpen(true);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("tourOpenDashboard", JSON.stringify(isOpen));
    // if(currentStep===4){
    //   setIsOpen(false);
    // }
  }, [isOpen]);

  const closeTour = () => {
    setIsOpen(false);
  };

  const skipTour = () => {
    setIsOpen(false);
  };
  return (
    <div className="admin_container no-breadcrumb container-mw-xl chapter">
       <Tour
        steps={tourConfig}
        isOpen={isOpen}
        onRequestClose={closeTour}
        showCloseButton={true}
        showSkipButton={true}
        onAfterOpen={() => setIsOpen(true)}
        onRequestSkip={skipTour}
        getCurrentStep={() => currentStep}
      />
      <article className="article">
        <Breadcrumb>
          <Breadcrumb.Item>Admin</Breadcrumb.Item>
          <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
        </Breadcrumb>
        <h2
          className="article-title"
          style={{
            marginBottom: "-2rem",
            marginTop: "-2rem",
            paddingTop: "3rem",
            paddingBottom: "2rem",
          }}
        >
          Dashboard
        </h2>
        <Divider />
        <div style={{ marginBottom: "2rem" }}>
          <Row justify="space-between">
            <Col style={{ width: "70%" }}>
              <Row gutter={[8,]}> 
                <Col adminDashboard-tour="dropDown" style={{ width: "50%" }}>
                  <DropdownComponent  disabled={true} value={"US"} placeholder="Country" options={country} style={{
                    minWidth: "100%", boxShadow: "0.25rem 0.25rem 1rem rgba(0, 0, 0, 0.17)", backgroundColor: "transparent", borderRadius: "5rem"
                  }} className="dropdown-country" />
                </Col>
                {/* <Col style={{ width: "15%" }}>
                  <DropdownComponent disabled={true} placeholder="Filter" style={{
                    minWidth: "100%", boxShadow: "0.25rem 0.25rem 1rem rgba(0, 0, 0, 0.17)", backgroundColor: "transparent", borderRadius: "5rem"
                  }} className="dropdown-country" />
                </Col> */}
              </Row>
            </Col>
            {/* <Col style={{ width: "10%" }}>
              <DropdownComponent disabled={true} placeholder="Today" options={date} style={{
                minWidth: "100%", boxShadow: "0.25rem 0.25rem 1rem rgba(0, 0, 0, 0.17)", backgroundColor: "transparent", borderRadius: "5rem"
              }} className="dropdown-country" />
            </Col> */}
          </Row>
        </div>
        <div style={{display: "flex", justifyContent: "center", flexDirection: "column", alignContent: "center" }}>
          <Row justify="center">

            {dashboardDetails?.map((item, key) => {
              if (item.title !== "" && item.url !== "") {
                return (        
                  <>
               
                  <Col span={9} adminDashBoard-tour={item.title}  key={key}  style={{display:"flex",flexDirection:"column",alignItems:"center"}} >
                    <CardComponent
                     title={item.title} 
                     url={item.url} 
                     value={item.value}
                    />
                  </Col>
                  </>
                )
              } else {
                return (
                  <Col span={9} key={key}></Col>
                )
              }
            })
            }
          </Row>
        </div>
      </article>
    </div>
  );
}
