/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import InvoiceFooter from "../../components/InvoiceFooter.jsx";
import UserActions from "../../actions";
import { DownOutlined, SearchOutlined } from "@ant-design/icons";
import { Modal, List, Divider, message } from "antd";
import { FaFileInvoiceDollar, FaGift, FaTag } from "react-icons/fa";
import { getTotal, getFormattedDt, isMobile, getFormattedDtNew } from "../../common/util.js";

import {
  Row,
  Col,
  Tabs,
  Slider,
  Input,
  Dropdown,
  Menu,
  Typography,
  Button,
  Space
} from "antd";
import { useHistory, useLocation } from "react-router-dom";
import InvoiceEdit from "../Invoice/Edit";
import InvoiceView from "../Invoice/View";
import InvoicePay from "../Invoice/Pay";
import InvoiceCataloguePending from "../Invoice/CatalogPending";
import Loader from "../../components/Loading/Loader";
import HelmetFyndr from "../../components/HelmetFyndr";
import Summary from "./Summary";
import Orders from "./Orders";
import { Actions, Theme } from "../../common";
import actions from "../../actions";
import ContainerWrapper from "../../components/ContainerWrapper";
import getEnvVars from "../../environment.js";
import globe from "../../assets/images/globe.svg";
import mail from "../../assets/images/mail (2).svg";
import phone2 from "../../assets/images/phone2.svg";

const { TabPane } = Tabs;
const { Text } = Typography;
const { ROLE_FYNDR } = Actions;
const web = getEnvVars();
// const { LOG_OUT } = Actions;

const Transactions = (props) => {
  const {
    indv: { userTimeZone },
  } = useSelector(({ auth }) => auth);
  const history = useHistory();
  const location = useLocation();
  const {
    indv: { merchantId, bizid, indvid, biz, entityType },
  } = useSelector(({ auth }) => auth);

  const { receivables, receivableModified, payables, payableModified } =
    useSelector(({ fin }) => fin);
  const {
    indv: { email },
  } = useSelector(({ admin }) => admin);

  const [rcptlist, setRcptList] = useState([]);
  const [paylist, setPayList] = useState([]);
  const [receivableText, setReceivableText] = useState(null);
  const [receivableCtr, setReceivableCtr] = useState(1);
  const [payableText, setPayableText] = useState(null);
  const [payableCtr, setPayableCtr] = useState(1);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [tab, setTab] = useState(biz ? "1" : "2");
  const [, setModified] = useState(false);
  const [filterType, setFilterType] = useState("All");
  const [filterChannel, setFilterChannel] = useState("All");
  const [isLoading, setIsloading] = useState(false);
  const [invobj, setinvobj] = useState(null);
  const [transactionData, setTransactionData] = useState({
    invoiceId: null,
    buyerId: null,
  });

  const showInvoice = (inv, status, type) => {
    console.log("inv>>", inv);
    if (inv !== undefined && status !== undefined && type !== undefined) {
      setSelectedInvoice(inv);
      setSelectedStatus(status);
      setSelectedType(type);
      setModalVisible(true);
    }
  };

  const onSearchReceivable = () => {
    doRefresh(receivableText, receivableCtr);
  };

  const onSearchPayable = () => {
    doRefresh(payableText, payableCtr);
  };

  function callback(key) {
    setTab(key);
  }

  const doRefresh = async (text, ctr) => {
    setIsloading(true);
    if (tab === "1") {
      await UserActions.fetchReceivables({
        criteria: "merchant",
        text,
        bizid,
        merchantId,
        days: 30 * ctr, //assuming 30 days/month
      });
    } else if (tab === "2") {
      await UserActions.fetchPayables({
        criteria: "individual",
        buyerId: indvid,
        text : invobj,
        days: 30 * ctr,
      });
    }
    setIsloading(false);
  };

  useEffect(() => {
    if (tab === "1") {
      let tmp = [];
      let filteredList = [];

      Object.keys(receivables)
        .sort((a, b) => parseInt(a) > parseInt(b))
        .reverse()
        .forEach((key) => {
          let rec = receivables[key];
          rec.totalAmount = getTotal(rec);
          tmp.push(rec);
        });

      if (filterType.toLowerCase() !== "all") {
        filteredList = tmp.filter((item) => item.status === filterType);
      } else {
        filteredList = tmp;
      }

      if (filterChannel.toLowerCase() !== "all") {
        filteredList = filteredList.filter(
          (item) => item.channel === filterChannel
        );
      }
      setRcptList(filteredList);
      setModified(!setModified);
    }

    if (tab === "2") {
      let tmp = [];
      Object.keys(payables)
        .sort((a, b) => parseInt(a) > parseInt(b))
        .reverse()
        .forEach((key) => {
          let rec = payables[key];
          if (!(rec.status === "pending" && rec.channel === "offers")) {
            rec.totalAmount = getTotal(rec);
            tmp.push(rec);
          }
        });

      setPayList(tmp);

      if (transactionData?.invoiceId !== null && transactionData?.invoiceId !== undefined && tmp.length !== 0) {
        let data = tmp?.filter((item) => item?.objid === transactionData?.invoiceId)
        showInvoice(data[0], data[0]?.status, "payable")
        history.replace("/billing/transactions", {});

      }

      setModified(!setModified);
    }
  }, [receivableModified, payableModified]);

  useEffect(() => {
    if (tab === "1") onSearchReceivable();
    else if (tab === "2") onSearchPayable();
  }, [tab, filterType, filterChannel , payableCtr, receivableCtr]);

  useEffect(() => {
    const { invoiceId, buyerId , invid } = location?.state || {};
    setinvobj(invid);
    setTransactionData({
      invoiceId,
      buyerId,
    });
    setTab("2");
    // if (transactionData !== null) {
    //   setModalVisible(true);
    // }
  }, [location?.state]);
  // useEffect(() => {
  //   if (tab === "1") onSearchReceivable();
  // }, []);

  useEffect(() => {
    if (isMobile()) {
      if (biz) {
        // history.push("/merchant/stripe_connect");
        history.push("/billing/transactions");
      }
      else {
        history.push("/");
        message.info("To access account please switch to desktop view")
      }
    }

    window.onpopstate = function () {
      if (entityType == ROLE_FYNDR) {
        backToAdminFunction();
      }
    };
  }, []);
  let backToAdminFunction = async () => {
    let response = await actions.BackToAdmin({ email });
    if (response?.success === true) {
      history.push("/admin/dashboard");
    }
  };
  // const Logout = async() =>{
  //   dispatch({
  //     type: LOG_OUT,
  //   });
  //   await localStorage.clear();
  //   window.location.reload();
  // }

  const handleStatusClick = (e) => {
    setFilterType(e.key);
  };

  const handleChannelClick = (e) => {
    setFilterChannel(e.key);
  };

  const setFilters = (data) => {
    const { channel, type, months } = data;
    setFilterChannel(channel);
    setFilterType(type);
    setReceivableCtr(months);
    setTab("1");
  };

  const status_list = [
    { key: "all", value: "All" },
    { key: "paid", value: "Paid" },
    { key: "pending", value: "Pending" },
  ];

  const channel_list = [
    { key: "all", value: "All" },
    { key: "offers", value: "Offers" },
    { key: "interaction", value: "Invoices" },
  ];
  const menu = (
    <Menu onClick={handleStatusClick}>
      {status_list.map((row) => {
        const { key, value } = row;
        return <Menu.Item key={key}>{value}</Menu.Item>;
      })}
    </Menu>
  );

  const menuchannel = (
    <Menu onClick={handleChannelClick}>
      {channel_list.map((row) => {
        const { key, value } = row;
        return <Menu.Item key={key}>{value}</Menu.Item>;
      })}
    </Menu>
  );

  const CommonHeader = (type) => {
    const months = type === "receivable" ? receivableCtr : payableCtr;
    return (
      <>
        {<HelmetFyndr
          title="Transaction"
          // imgUrl={imgLink}
          url={`${web}/billing/transactions`}
          descr="transaction"
        />}
        <Row
          gutter={20}
          align="middle"
          justify="space-between"
          className="transaction_selection-row"
        >
          <Col xs={24} md={8} lg={7} span={8}>
            <Input
              size="large"
              value={invobj}
              onPressEnter={
                type === "receivable" ? onSearchReceivable : onSearchPayable
              }
              onChange={(e) => {
                const value = e.target.value;
                setinvobj(value);
                if (type === "receivable") {
                  setReceivableText(value);
                } else {
                  setPayableText(value);
                }
              }}
              prefix={<SearchOutlined className="prefix" />}
              placeholder="Search"
            />
          </Col>

          <Col xs={24} md={8} lg={7} span={8} align="center">
            <Text strong>
              Past {months} {months > 1 ? `Months` : "Month"}
            </Text>
            <Slider
              railStyle={{ backgroundColor: Theme.grey, height: ".2rem" }}
              style={{ color: Theme.blue }}
              onChange={(value) =>
                type === "receivable"
                  ? setReceivableCtr(value)
                  : setPayableCtr(value)
              }
              value={months}
              max={12}
              min={1}
              onAfterChange={
                type === "receivable" ? onSearchReceivable : onSearchPayable
              }
            />
          </Col>

          <Col xs={24} md={8} lg={10} span={8}>
            {type === "receivable" && (
              <Row gutter={[20, 10]}>

                <Col xs={24} md={24} lg={12} xl={12} xxl={12} span={9}>
                  <Dropdown overlay={menu}>
                    <Button style={{ width: isMobile() ? "-webkit-fill-available" : "" }}>
                      {`Status: ${filterType}`} <DownOutlined />
                    </Button>
                  </Dropdown>
                </Col>

                <Col xs={24} md={24} lg={12} xl={12} xxl={12} span={4}>
                  <Dropdown overlay={menuchannel}>
                    <Button style={{ width: isMobile() ? "-webkit-fill-available" : "" }}>
                      {`Channel: ${filterChannel}`}
                      <DownOutlined />
                    </Button>
                  </Dropdown>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </>
    );
  };

  const renderItem = (item, type) => {
    const colors = {
      pending: "#2663CD",
      canceled: "#8898AA",
      paid: "#2DCE89",
      fulfilled: "#11CDEF",
    };

    const {
      totalAmount,
      currencySymbol,
      invoiceDt,
      status,
      buyerFname,
      buyerLname,
      invoiceDetails: { business_name, invoice_nbr, title, cmpn_title },
      channel,
      isOfferGifted
    } = item;
    console.log("isOfferGifted", isOfferGifted);
    let rowTitle, body;
    if (type === "payable") {
      rowTitle = business_name;
      body = title || cmpn_title || "Campaign Promotion";
    } else if (type === "receivable") {
      rowTitle = `${buyerFname} ${buyerLname}`;
      body =
        channel === "offers"
          ? cmpn_title
          : channel === "interaction"
            ? `${invoice_nbr}: ${title}`
            : title;
    }

    return (
      <List.Item
        onClick={() => {
          showInvoice(item, status, type)
        }}
        className="transaction_receivables"
      >
        <div className="list-style-v1">
          <div className="list-item">
            <div
              className={`icon-btn mr-4`}
              style={{
                borderRadius: 20,
                backgroundColor: item.fulfiled
                  ? colors.fulfilled
                  : colors.hasOwnProperty(status)
                    ? colors[status]
                    : Theme.orange,
                minWidth: isMobile() ? "30px" : "35px",
              }}
            >
              {channel === "offers" ? (
                <FaTag className="transaction" />
              ) : (
                <FaFileInvoiceDollar className="transaction" />
              )}
            </div>
            <div className="list-item__body" style={{ fontSize: isMobile() ? "9px" : "14px" }}>
              <div className="list-item__title list-sep">
                <div>
                  <h5>{rowTitle}</h5>
                </div>

                <div style={{ width: isMobile() ? 156 : 400, fontWeight: isMobile() ? 500 : 0 }}>
                  <Text>{body}</Text>
                </div>
              </div>
              <div className="list-sep cc-padding" style={{ marginRight: 60, fontWeight: isMobile() ? 500 : 0 }}>
                {/* {getFormattedDt(invoiceDt)} */}
                {getFormattedDtNew(invoiceDt, userTimeZone)}
              </div>
              <div className="list-sep cc-padding" style={{ marginRight: 60 }}>
                <h5>{`${currencySymbol}${totalAmount}`}</h5>
              </div>
              {isOfferGifted && <div className="list-sep cc-padding">
                <FaGift size={20} color={Theme.blue} />

              </div>}
            </div>
          </div>
        </div>
      </List.Item>
    );
  };

  return (
    <div>
      <ContainerWrapper title={"My Orders"}>
        <section className="page-with-tabs">
          <Row
            className="transaction_page"
            align="middle"
            justify="center"
          >
            {biz ?
              <Col span={24}>
                <div>
                  <Tabs
                    size={isMobile() ? "small" : "large"}
                    onChange={callback}
                    tabBarGutter={isMobile() ? 20 : 120}
                    activeKey={tab}
                  >
                    {biz && (
                      <TabPane tab="Sales Summary" key="3" >
                        <Summary setFilters={setFilters} />
                      </TabPane>
                    )}
                    {biz && (
                      <TabPane tab="Receivables" key="1">
                        {CommonHeader("receivable")}
                        {isLoading && (
                          <Row justify="center">
                            <Loader />
                          </Row>
                        )}
                        {!isLoading && (
                          <List
                            itemLayout="horizontal"
                            dataSource={rcptlist}
                            renderItem={(item) =>
                              renderItem(item, "receivable")
                            }
                          />
                        )}
                      </TabPane>
                    )}
                    <TabPane tab="My Purchases" key="2">
                      {CommonHeader("payable")}
                      {isLoading && (
                        <Row justify="center">
                          <Loader />
                        </Row>
                      )}
                      {!isLoading && (
                        <List
                          itemLayout="horizontal"
                          dataSource={paylist}
                          renderItem={(item) =>
                            renderItem(item, "payable")
                          }
                        />
                      )}
                    </TabPane>
                    {biz && (
                      <TabPane tab="Orders" key="4">
                        <Orders />
                      </TabPane>
                    )}
                  </Tabs>
                </div>
              </Col>

              :
              <Row style={{ width: "100%" }} >
                <Col span={24} >
                  {CommonHeader("payable")}
                </Col>
                <Col span={24}>
                  {isLoading && (
                    <Row justify="center">
                      <Loader />
                    </Row>
                  )}
                  {!isLoading && (
                    <List
                      itemLayout="horizontal"
                      dataSource={paylist}
                      renderItem={(item) =>
                        renderItem(item, "payable")
                      }
                    />
                  )}
                </Col>

              </Row>
            }
          </Row>
        </section>
      </ContainerWrapper>
      <Modal
        className="modal_invoice"
        title={`${selectedStatus === "pending"
          ? selectedType === "receivable"
            ? "Edit Invoice"
            : "Pay Invoice"
          : "Invoice Details"
          }  `}
        visible={modalVisible}
        footer={<InvoiceFooter />}
        onCancel={() => {
          setTransactionData({
            invoiceId: null,
            buyerId: null,
          });
          setModalVisible(false);
        }}
        centered
        width={500}
      >
        {selectedStatus !== "pending" && (
          <InvoiceView
            inv={selectedInvoice}
            type={selectedType}
            callback={() => {
              setTransactionData({
                invoiceId: null,
                buyerId: null,
              });
              setModalVisible(false);
            }
            }
          />
        )}
        {selectedStatus === "pending" && selectedType === "payable" && (
          <InvoicePay
            inv={selectedInvoice}
            type={selectedType}
            callback={() => {
              setTransactionData({
                invoiceId: null,
                buyerId: null,
              });
              setModalVisible(false);
            }
            }
          />
        )}
        {selectedStatus === "pending" &&
          selectedType === "receivable" &&
          selectedInvoice.channel !== "catalog" && (
            <InvoiceEdit
              inv={selectedInvoice}
              callback={() => {
                setTransactionData({
                  invoiceId: null,
                  buyerId: null,
                });
                setModalVisible(false);
              }
              }
            />
          )}
        {selectedStatus === "pending" &&
          selectedType === "receivable" &&
          selectedInvoice.channel === "catalog" && (
            <InvoiceCataloguePending
              inv={selectedInvoice}
              type={selectedType}
              callback={() => {
                setTransactionData({
                  invoiceId: null,
                  buyerId: null,
                });
                setModalVisible(false);
              }
              }
            />
          )}
      </Modal>
    </div>
  );
};

export default Transactions;
