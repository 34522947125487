import React, { useEffect, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { Row, Col, Button, Input, Typography, message } from "antd";
import actions from "../actions";
import CommentButtons from "./ReviewComments/CommentButtons";
import flagLight from "../assets/images/ph_flag-light.svg";
import flag from "../assets/images/flag.svg"
import msgIcon from "../assets/images/msg-icon.svg";
import comment from "../assets/images/commment.svg"
import CustomizedModal from "./CustomizedModal/CustomizedModal";
import moment from "moment";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { isMobile } from "../common/util";
import { useSelector } from "react-redux";
import PulseLoader from "react-spinners/PulseLoader";
import { useDispatch } from "react-redux";
import { Actions } from "../common";
import ParticularComments from "./ParticularComment/ParticularComments";
import Loader from "./Loading/Loader";
const { TextArea } = Input;
const { Text } = Typography;

export default function Review(props) {
  const { SET_ROUTE } = Actions;
  const currentRoute = useLocation().pathname;
  let history = useHistory();

  const dispatch = useDispatch();
  const {
    indv,
    indv: { indvid, merchantId, bizid },
  } = useSelector(({ auth }) => auth);
  const { preview } = useSelector(({ preview }) => preview);
  const [reviewComment, setReviewComment] = useState();
  const [isvisible, setIsVisible] = useState(false);
  const [text, setText] = useState("");
  const [action, setAction] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [expandedComments, setExpandedComments] = useState([]);
  const [expandedReplies, setExpandedReplies] = useState([]);
  const [reportedCommentVisible, setReportedCommentVisible] = useState(false);
  const { commentId } = useParams();

  const toggleExpandComment = (index) => {
    setExpandedComments((prevExpandedComments) => {
      const newExpandedComments = [...prevExpandedComments];
      newExpandedComments[index] = !newExpandedComments[index];
      return newExpandedComments;
    });
  };

  const toggleExpandReply = (index) => {
    setExpandedReplies((prevExpandedReplies) => {
      const newExpandedReplies = [...prevExpandedReplies];
      newExpandedReplies[index] = !newExpandedReplies[index];
      return newExpandedReplies;
    });
  };

  const LongText = (text, index, isReply) => {
    const MAX_LENGTH = 38;
    const isLongText = text?.length > MAX_LENGTH;
    const displayText = isLongText
      ? `${text.substring(0, MAX_LENGTH)}...`
      : text;

    const isExpanded = isReply
      ? expandedReplies[index]
      : expandedComments[index];

    const toggleExpand = isReply ? toggleExpandReply : toggleExpandComment;

    return (
      <Text>
        {isExpanded ? text : displayText}
        {isLongText && (
          <Text
            style={{ color: "#1890ff", cursor: "pointer" }}
            onClick={() => toggleExpand(index)}
          >
            {isExpanded ? " Read Less" : "Read More"}
          </Text>
        )}
      </Text>
    );
  };

  const fetchById = async () => {
    setLoading(true);
    const resp = await actions.fetchCommentsById({ commentId: commentId });
    if (resp.success) {
      setLoading(false);
      setReviewComment(resp.data);
    }
  };
  const replyToTheComment = async () => {
    setIsLoading(true);
    let res = await actions.addReply({
      commentId: commentId,
      businessId: bizid,
      reply: text,
    });
    if (res.success) {
      setIsLoading(false);
      message.success(res.msg);
      setText("");
      setIsVisible(false);
      fetchById(commentId);
    } else {
      setIsLoading(false)
      message.error(res?.msg)
      setText("");
      setIsVisible(false)
    }
  };
  const reportToTheComment = async () => {
    setIsLoading(true);
    let response = await actions.addReport({
      commentId: commentId,
      businessId: bizid,
      reportMessage: text,
    });
    if (response.success) {
      setIsLoading(false);
      message.success(response.msg);
      setText("");
      setIsVisible(false);
      fetchById(commentId);
    }
    else {
      setIsLoading(false)
      message.error(response?.msg)
      setText("");
      setIsVisible(false)
    }
  };
  const onHandleSubmit = () => {
    if (text === "") {
      message.error("Please enter the text");
    } else if (action === "reply") {
      replyToTheComment();
    } else {
      reportToTheComment();
    }
  };
  const setRoute = async () => {
    dispatch({ type: SET_ROUTE, payload: currentRoute });
    history.push("/login");
  };
  useEffect(() => {
    fetchById(commentId);
  }, []);
  return (
    <div style={{ padding: isMobile() ? "0px 8px" : "0px 162px" }}>
      {!loading ? (
        <>
          {indvid && merchantId ? (
            <>
              <article className="article" style={{ paddingBottom: "115px" }}>
                <Row justify="start">
                  <Col>
                    <Button
                      type="link"
                      className="particular-review_gobackbtn"
                      onClick={() => history.push("/billing/transactions")}
                    >
                      <ArrowLeftOutlined style={{ verticalAlign: "middle" }} />
                      Go To Your Business
                    </Button>
                  </Col>
                </Row>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div className="particular-review_mainContainerRow">
                    <ParticularComments
                      name={`${reviewComment?.user?.firstName} ${reviewComment?.user?.lastName}`}
                      date={moment
                        .utc(reviewComment?.createdDt)
                        .local()
                        .format("YYYY-MM-DD h:mm A")}
                      images={reviewComment?.images}
                      review={reviewComment?.review}
                      rating={reviewComment?.rating}
                      overflowClassName="particular-review_replyOverflow"
                      className="particular-review_details"
                      imgClassName="particular-review_img"
                      style={{
                        backgroundColor: "#FFF",
                        marginTop: "12px",
                        padding: "12px",
                      }}
                      textClassNameForPopUp="userCommentText commentReplyDiv"
                      reviewComment={reviewComment}
                      btns={
                        <>
                          {isvisible && (
                            <>
                              <Row style={{ padding: "24px 0px" }}>
                                <TextArea
                                  value={text}
                                  onChange={(e) => {
                                    setText(e.target.value);
                                  }}
                                />
                              </Row>
                              <Row justify={"end"}>
                                <Button
                                  type="primary"
                                  disabled={isLoading}
                                  className="particular-review_submitbtn"
                                  onClick={() => {
                                    onHandleSubmit();
                                  }}
                                >
                                  Submit{isLoading && <Loader />}
                                </Button>
                              </Row>
                            </>
                          )}
                          {!isvisible && (
                            <Row
                              style={{ paddingTop: "24px" }}
                              justify={"center"}
                            >
                              <Col
                                sm={12}
                                lg={6}
                                md={12}
                                xs={10}
                                xl={8}
                                xxl={8}
                                align={"middle"}
                              >
                                <CommentButtons
                                  type="primary"
                                  commnetOutLined={
                                    <img
                                      src={comment}
                                      alt="msgIcon"
                                      className="reviewcomments_commentBtnIcon"
                                    />
                                  }
                                  buttonText={"Reply"}
                                  buttonOnClick={() => {
                                    setIsVisible(true);
                                    setAction("reply");
                                  }}
                                />
                              </Col>
                              <Col
                                sm={12}
                                lg={6}
                                md={12}
                                xs={10}
                                xl={8}
                                xxl={8}
                                align={"middle"}
                              >
                                <CommentButtons
                                  type="primary"
                                  style={{
                                    backgroundColor:
                                      (reviewComment?.isReportingAllowed ===
                                        true)
                                        ? "#ffffff"
                                        : "#CCCCC8",
                                    borderColor: reviewComment?.isReportingAllowed !== true && "#CCCCC8",
                                    color: reviewComment?.isReportingAllowed === true ? "#257CDB" : "#7f7f7f"

                                  }}
                                  commnetOutLined={
                                    <img
                                      alt="flag"
                                      src={reviewComment?.isReportingAllowed === true ? flag : flagLight}
                                      className="reviewcomments_commentBtnIcon"
                                    />
                                  }
                                  buttonText={
                                    reviewComment?.isReportingAllowed === true
                                      ? "Report"
                                      : "Reported"
                                  }
                                  buttonOnClick={() => {
                                    if (
                                      reviewComment?.isReportingAllowed === true
                                    ) {
                                      setIsVisible(true);
                                      setAction("report");
                                    } else {
                                      setReportedCommentVisible(true);
                                    }
                                  }}
                                  data={reviewComment}
                                />
                              </Col>
                            </Row>
                          )}
                        </>
                      }
                    />
                  </div>
                </div>
              </article>

              <CustomizedModal
                visible={reportedCommentVisible}
                onCancel={() => setReportedCommentVisible(false)}
                footer={null}
                title={"Reported Comments"}
                content={
                  <Row style={{ padding: "24px" }}>
                    {reviewComment?.reportedComments?.map((data,index) => {
                      return (
                        <Row className="particular-reviewModal_row">
                          <div className="particular-reviewModal_reportedComment">{LongText(data?.reportedComment,index, true)}</div>
                          <div className="particular-reviewModal_time">
                            {
                              moment
                                .utc(data?.createdDt)
                                .local()
                                .format("YYYY-MM-DD HH:mm A")
                            }</div>
                        </Row>
                      )
                    })}

                  </Row>
                }
              />
            </>
          ) : (
            <div
              style={{
                display: "flex",
                padding: "20%",
                justifyContent: "center",
              }}
            >
              <Text
                style={{
                  color: "#4D4D4D)",
                  fontSize: "24px",
                  lineHeight: "31px",
                  marginRight: "5%",
                }}
              >
                {"Please login to check reviews"}
              </Text>
              <Button
                type="primary"
                className="offer_cards--buy-btn"
                onClick={() => setRoute()}
              >
                Login
              </Button>
            </div>
          )}
        </>
      ) : (
        <div className="quickLoader">
          <PulseLoader color={"#3F9AF7"} loading={loading} size={10} />
        </div>
      )}
    </div>
  );
}
