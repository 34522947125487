/* eslint-disable no-unused-vars */
import React, { useRef, useState } from "react";
import {
  Row,
  Form,
  Input,
  Select,
  Button,
  message,
  Space,
  Switch,
  Popover,
} from "antd";
import {
  Container as ContainerWrapper,
  CatalogueForm as FormCatalogue,
} from "../../components";
import { useForm } from "react-hook-form";
import { FormSchema } from "../../common";
import { useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers";
import { Theme } from "../../common";

import UserAction from "../../actions";
import LoadingOverlay from "react-loading-overlay";
import Loader from "../../components/Loading/Loader";
import { AiFillInfoCircle } from "react-icons/ai";
const SelectOptions = [
  "each",
  "kg",
  "mg",
  "pound",
  "ounce",
  "ml",
  "litre",
  "set",
  "box",
  "pair",
];
const { Option } = Select;
const EditItem = (props) => {
  const { isEdit, callback, item } = props;

  const FormItem = Form.Item;
  const nameRef = useRef(null);
  const descRef = useRef(null);
  const skuRef = useRef(null);
  const taxRef = useRef(null);
  const { CatalogueForm } = FormSchema;

  const {
    indv: { bizid },
  } = useSelector(({ auth }) => auth);
  const [isLoading, setIsLoading] = useState(false);
  SelectOptions.sort((a, b) => a.localeCompare(b));
  const [payload, setPayload] = useState({
    name: item?.name || "",
    description: item?.description || null,
    images: item?.images || [],
    sku: item?.sku || "",
    unit: item?.unit || null,
    stdTax: item ? item.stdTax : true,
    taxPercent: item?.taxPercent || null,
  });
  const [descError, setDescError] = useState({
    status: false,
    message: null,
  });
  const [skuError, setSkuError] = useState({
    status: false,
    message: null,
  });
  const [taxError, setTaxError] = useState({
    status: false,
    message: null,
  });
  const [unitError, setUnitError] = useState({
    status: false,
    message: null,
  });
  const layout = {
    labelCol: {
      span: 4,
    },
    wrapperCol: {
      span: 16,
    },
  };
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };
  const { handleSubmit, errors, control } = useForm({
    mode: "onBlur",
    shouldUnregister: false,
    resolver: yupResolver(CatalogueForm),
  });
  const restFormValidation = async (data) => {
    if (payload.unit === null) {
      setUnitError({
        status: true,
        message: "Please select measurement unit for the item",
      });
      return;
    } else {
      setUnitError({ status: false, message: "" });
    }
    if (payload.stdTax !== true) {
      const value = taxRef?.current?.input?.defaultValue;
      if (value === undefined || value === null || value.length < 1) {
        setTaxError({
          status: true,
          message: "Please enter the tax percentage or enable standard tax",
        });
        return;
      }
      let regex = new RegExp(/^(?:100(?:\.0(?:0)?)?|\d{1,2}(?:\.\d{1,2})?)$/);

      if (!regex.test(value)) {
        setTaxError({
          status: true,
          message: "Tax percentage should be between 0 to 100",
        });
        return;
      }
      if (value > 100 || value < 0) {
        setTaxError({
          status: true,
          message: "Tax percentage should be between 0 to 100",
        });
        return;
      }
      setTaxError({ status: false, message: null });
    }

    let dispatchPayload = {
      images: payload?.images,
      description: payload?.description,
      name: data?.name,
      // sku: skuRef?.current?.state?.value,
      sku:payload?.sku,
      taxPercent:payload?.taxPercent,
      unit: payload?.unit,
      // taxPercent: taxRef?.current?.state?.value,
      stdTax: payload?.stdTax,

      bizid,
    };
    //API call for the Add category
    let ImageArray = dispatchPayload.images;

    ImageArray.map((item, index) => {
      let keys = Object.keys(item);
      if (keys.includes("img_url")) {
        ImageArray.splice(index, 1);
      }
      return null;
    });

    if (ImageArray.length < 1) {
      delete dispatchPayload.images;
    }
    if (ImageArray[0]?.img === null) {
      dispatchPayload.images = [];
    }
    if (item?.objid) {
      dispatchPayload.objid = item.objid;
    }
    setIsLoading(true);
    let resp = await UserAction.addItem([dispatchPayload]);
    resp.success ? message.success(resp.message) : message.error(resp.message);
    setIsLoading(false);
    // dispatch({ type: UPDATE_ITEM, payload: dispatchPayload });
    if (resp.success) {
      callback(false);
    }
  };
  const handleAddCategory = () => {
    handleSubmit(
      (data) => {
        restFormValidation(data);
      },
      (err) => console.log("error", err)
    )();
  };
  const handleChange = (value, option) => {
    setPayload({ ...payload, unit: value });
  };
  const TAXCONTENT = (
    <div style={{ maxWidth: "40vw" }}>
      <ol>
        <li>
          <p>
            <span style={{ fontWeight: "bold" }}>Standard Tax- </span>
            Fyndr allows the Businesses to define the tax percentage for
            the offers/goods/services. If you choose Standard Tax, Fyndr
            will calculate the tax based on the merchant location where the
            services/goods/offers are rendered. The responsibility of defining the
            correct tax % is solely the Business's responsibility
          </p>
        </li>
        <li>
          <p>
            <span style={{ fontWeight: "bold" }}>Custom Tax -</span>
            Businesses can define the Tax percentage for the offers, services
            and goods sold. The sole responsibility of collecting appropriate
            tax and reporting to the state and federal authorities lies with the
            business.
          </p>
        </li>
      </ol>
    </div>

  );
  return (
    <LoadingOverlay
      active={isLoading}
      spinner
      text=""
      styles={{
        overlay: (base) => ({
          ...base,
          background: Theme.overlay,
        }),
      }}
    >
      <ContainerWrapper title={isEdit ? `Edit : ${item?.name}` : "Add Item"}>
        <Row justify="start">
          <Form style={{ minWidth: "100%", padding: "2rem" }} {...layout}>
            <FormCatalogue
              formHook={{ errors, handleSubmit, control }}
              nameRef={nameRef}
              descRef={descRef}
              getMarkdownValue={(value) => {
                setPayload({ ...payload, description: value });
              }}
              desc={payload.description}
              name={payload.name}
              imgs={payload.images}
              descError={descError}
              imageCallback={(value) => {
                let imgs = payload.images.filter(
                  (row) => row.index !== value.index
                );
                imgs.push(value);
                imgs.sort((a, b) => a.index > b.index);
                setPayload({ ...payload, images: imgs });
              }}
              isMulti={false}
              formtype="items"
            />
            <FormItem
              help={unitError.message}
              validateStatus={unitError.status ? "error" : "success"}
              {...formItemLayout}
              label="Unit"
            >
              <Select
                onChange={handleChange}
                defaultValue={payload.unit}
                value={payload.unit}
                placeholder="Select Unit"
              >
                {SelectOptions.map((item, key) => (
                  <Option value={item} key={key}>
                    {item.charAt(0).toUpperCase() + item.slice(1)}
                  </Option>
                ))}
              </Select>
            </FormItem>
            <FormItem
              help={skuError.message}
              validateStatus={skuError.status ? "error" : "success"}
              {...formItemLayout}
              label="SKU"
            >
              <Input
                ref={skuRef}
                defaultValue={payload.sku}
                placeholder="Item-Sku-12345"
                onChange={(e)=>setPayload({ ...payload, sku: e.target.value })}
              />
            </FormItem>
            <FormItem {...formItemLayout} label="Standard Tax">
              <Space>
                <Switch
                  checked={payload.stdTax}
                  onChange={(value) => {
                    if (value) {
                      setTaxError({ status: false, message: null });
                      // taxRef.current.state.value = null;
                      setPayload({ ...payload, taxPercent: null });
                    }
                    setPayload({ ...payload, stdTax: value });
                  }}
                />
                <Popover
                  placement="rightBottom"
                  title="Standard Tax"
                  content={TAXCONTENT}
                >
                  <AiFillInfoCircle
                    style={{ color: Theme.blue, fontSize: "1.5rem" }}
                  />
                </Popover>
              </Space>
            </FormItem>
            <FormItem
              help={taxError.message}
              validateStatus={taxError.status ? "error" : "success"}
              {...formItemLayout}
              label="Tax Percentage"
            >
              <Input
                ref={taxRef}
                placeholder={"Tax percentage"}
                type="number"
                disabled={payload.stdTax}
                defaultValue={payload.taxPercent}
                onChange={(e)=>setPayload({...payload,taxPercent:e.target.value})}
              />
            </FormItem>
          </Form>
        </Row>

        <Row style={{ width: "100%" }} justify="end">
          <Space>
            <Button
              disabled={isLoading}
              type="warning"
              onClick={() => callback(false)}
            >
              Cancel
            </Button>
            <Button
              disabled={isLoading}
              type="primary"
              onClick={handleAddCategory}
            >
              {isEdit ? "Update" : "Save"}
              {isLoading && <Loader />}
            </Button>
          </Space>
        </Row>
      </ContainerWrapper>
    </LoadingOverlay>
  );
};

export default EditItem;
