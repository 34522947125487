import React from "react";
import { Row, Col, Divider } from "antd";
import Images from "../../assets";
import '../../sass/styles/fyndr/_catpremobrow.scss';
import { RichTextViewer } from "../../components";
import { isMobile } from "../../common/util";
const MobileRow = ({ item, onClick, type, symbol }) => {
  const ITEM = "ITEM";

  let listItem = type === ITEM ? item.item : item;
  return (
    <div style={{ padding: "0 2rem 2rem 2rem", width: '100%', maxWidth: '100vw' }}>
      <Row
        onClick={onClick}
         style={{ cursor: "pointer", margin: '1rem 0 1rem 0',}}
        // className="catalogue_row_item_list"
        // justify="space-between"
        align="middle"
        // gutter={[10, 0]}
     
      >
        <Col xs={12} sm={9} md={13} lg={13} xl={10} xxl={10}>
          <img
            alt="category"
            className={isMobile() ? "catalogue_company_logo_cat_updated_mob" : "catalogue_company_logo_cat_updated"}
            src={
              listItem?.images
                ? listItem?.images[0].img_url
                : Images?.Images?.PlaceHolder
            }


          />

        </Col>
        
        <Col xs={12} sm={15} md={11} lg={11} xl={14} xxl={14} className="catalogue_textRow">
          <div className="catalogue_row_text_updated">
            <text className="catalogue_text_categoriesdescription" >{listItem?.name}</text> 
            {/* className="catalogue_item_name" */}
            {listItem?.description && (
              <RichTextViewer
                className="catalogue_richtext_viewer"
                style={{ maxWidth: "100%" }}
                content={`${listItem?.description}`}

              />
            )}
          </div>
          <h5>
            {item?.price ? `${symbol} ${Number(item?.price).toFixed(2)}` : null}
          </h5>
        </Col>



        <Divider
          style={{
            margin: "12px 0",
          }}
        />
      </Row>
    </div>
  );
};

export default MobileRow;
