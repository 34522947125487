import React, { useEffect, useState } from 'react';
import { Row, Tabs, Col, Button, Divider, message, Input, Radio } from 'antd';
import PromoSearch from './Search';
import UserActions from "../../actions";
import FeaturedHistory from "../../components/FeaturedSubscription/FeaturedHistory"
import FeaturedActions from "../../components/FeaturedSubscription/FeaturedActions"
import UpdatePay from '../../components/FeaturedSubscription/UpdatePay';
import ContainerWrapper from "../../components/ContainerWrapper";
import actions from '../../actions';
import { useSelector } from "react-redux";
import CustomizedModal from "../../components/CustomizedModal/CustomizedModal";
import cancelConfirmation from "../../assets/images/cancelConfirmation.svg";
import cancelImage from "../../assets/images/cancelImage.svg";
import updateSubs from "../../assets/images/updateSubs.svg"
import {
    ArrowLeftOutlined,
} from "@ant-design/icons";
import { isTab } from '../../common/util';
const { TabPane } = Tabs;
const { TextArea } = Input;

const Promo = (props) => {
    const {
        indv: { qrcode },
        settingsPlatform: {
            featuredSubscription,
        }

    } = useSelector(({ auth }) => auth);
    const { history } = props;
    const { cmpn_id, title, featured, paymentSubscription } = history.location.state;
    const [updatepay, setUpdatePay] = useState(false);
    const [isSubscription, setIsSubscription] = useState(paymentSubscription !== null && paymentSubscription?.status);
    const [cancelSubs, setCancelSubs] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [cancelVisible, setCancelVisible] = useState(false);
    const [updateVisible, setUpdateVisible] = useState(false)
    const [reason, setReason] = useState(0);
    const [updateMonth, setUpdateMonth] = useState(0);
    const [updateSubsVisible, setUpdateSubsVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [updatedPaymentInfo, setUpdatedPaymentInfo] = useState(null);
    const [updatedduration, setUpdatedDuration] = useState(0);
    const [loader, setLoader] = useState(false)
    const [updatedMonthDuration, setUpdatedMonthDuration] = useState(paymentSubscription?.updatedDuration);
    const [cancelSubsOPtions, setCancelSubsOPtions] = useState([]);
    const [text, setText] = useState("")

    useEffect(() => {
        setReason(reason)
    }, [reason])
    const cancelreasons = async () => {
        setLoader(true)
        let resp = await actions.fetchCancelReason();
        if (resp.success) {
            setCancelSubsOPtions(resp?.data)
            setLoader(false)
        }
        else {
            console.log("not getting any reasons")
            setLoader(false)
        }
    }

    const CancelSubs = async () => {
        let payload = {
            reasonId: reason,
            additionalComments: text
        }
        let resp = await actions.cancelSubscription(paymentSubscription?.id, payload);
        if (resp.success) {
            setCancelSubs(true);
            setIsSubscription("CANCELED")
            setModalVisible(false)
            setCancelVisible(true)
        } else {
            message.error(resp?.msg);
            setModalVisible(false)
        }
    }
    const UpdateSubscription = async () => {
        setUpdateVisible(false);
        setUpdateSubsVisible(true);
        setIsLoading(true);
        let resp = await UserActions.updateSubscription({
            subscriptionId: paymentSubscription?.id,
            qrcode: qrcode,
            duration: parseInt(updatedduration)
        });
        if (resp.success) {
            setUpdateMonth(0);
            setUpdatedDuration(0);
            setIsLoading(false);
            setUpdateSubsVisible(true);
            setUpdatedMonthDuration(resp?.data?.duration)

        } else {
            message.error(resp?.msg);
            setIsLoading(false);
            setUpdateSubsVisible(false)

        }
    }

    return (
        <ContainerWrapper title={`Campaign Promotion/Promotions`}
            featured={true}
            headerOption={!updatepay &&
                <Row justify="end">
                    <Col>
                        <Button
                            type="link"
                            style={{ fontSize: "16px" }}
                            onClick={() => history.goBack()}
                        >
                            <ArrowLeftOutlined />
                            Go Back
                        </Button>
                    </Col>
                </Row>
            }>
            {!updatepay ?
                <section className="page-with-tabs" style={{ padding: "12px" }}>
                    <Tabs
                        size="large"
                        tabBarGutter={!isTab && 400}
                        className="featuredSubs_customtabs"
                        defaultActiveKey="1">
                        <TabPane tab="New Promotion" key="1">
                            <div className="tab-content">
                                <PromoSearch cmpn_id={cmpn_id} title={title} featured={featured} cancelSubs={cancelSubs} />
                            </div>
                        </TabPane>
                        <TabPane tab="Completed Promotions" key="2">
                            <div className="tab-content">
                                <FeaturedHistory cmpn_id={cmpn_id} />
                            </div>
                        </TabPane>
                        <TabPane tab="Ongoing Promotion" key="3" >
                            <div className="tab-content">
                                <FeaturedActions onUpdateClick={() => setUpdatePay(true)} isSubscription={isSubscription} cmpn_id={cmpn_id}
                                    onUpdateSubsClick={(duration) => { setUpdateMonth(duration); setUpdateVisible(true); }}
                                    onCancelClick={() => { cancelreasons(); setModalVisible(true) }} updatedMonthDuration={updatedMonthDuration} paymentSubscription={paymentSubscription?.status} />
                            </div>
                        </TabPane>
                    </Tabs>
                </section>
                :
                <div style={{ paddingBottom: "24px" }}>
                    <Button
                        type="link"
                        className='featuredSubs_pay-btn'
                        style={{ padding: "12px" }}
                        onClick={() => { setUpdatePay(false) }}
                    >
                        <ArrowLeftOutlined />
                        Update Payment Method For Auto Renew
                    </Button>
                    <Divider className='featuredSubs_divider' />
                    <UpdatePay defaultPaymentId={updatedPaymentInfo} paymentSubscription={paymentSubscription} onPaymentUpdated={(updatedId) => setUpdatedPaymentInfo(updatedId?.subscription_default_payment)} />
                </div>
            }
            <CustomizedModal
                visible={modalVisible}
                title={"Cancel Auto Pay"}
                modalWidth="460px"
                onCancel={() => { setModalVisible(false); setReason(0); setText(""); }}
                btn1="Keep Subscription"
                btn1Style={{
                    backgroundColor: "#257CDB",
                    color: "white",
                    border: "1px solid #257CDB",
                    padding: "10px 16px",
                    borderRadius: "10px",
                    height: "46px",
                    fontSize: "16px",
                    width: "205px"

                }}
                onBtn1Click={() => { setModalVisible(false); setReason(0); setText("") }}
                onBtn2Click={() => {
                    if (reason === 7 && text === "") {
                        message.error("Please enter the reason before proceeding");
                    } else {
                        CancelSubs()
                    }
                }
                }
                btn2Style={{
                    backgroundColor: "white",
                    color: reason === 0 ? "#999999" : "#ED0C10",
                    border: reason === 0 ? "1px solid #999999" : "1px solid #ED0C10",
                    padding: "10px 16px",
                    borderRadius: "10px",
                    height: "46px",
                    fontSize: "16px",
                    width: "185px"

                }}
                btn2Disabled={reason === 0}
                btn2="Cancel Subscription"
                content={
                    <Row style={{ padding: "12px 12px 0px 12px" }}>
                        <Col span={24} align="middle" className='featuredSubs_cancelPop '>We're sad to see you go!</Col>
                        <Col span={24} align="middle" style={{ marginTop: "12px" }}><img src={cancelConfirmation} alt='fyndr'></img></Col>
                        <Col span={24} className='featuredSubs_cancelPara'>
                        Your presence has been invaluable to us, and we regret to see you go. 
                        After cancellation, your business will no longer be featured to Fyndr users. 
                        However, it will remain featured until the end of the current billing cycle. 
                        If there is anything we can do to enhance your experience, please let us know. Your feedback is essential for our improvement.
                        </Col>

                        <Col style={{ padding: "0px 12px" }}>
                            <Radio.Group onChange={(e) => { setReason(e.target.value);}} value={reason}>
                                <Col span={24}>
                                    <Row gutter={[12, 12]}>
                                        {cancelSubsOPtions?.map((item, key) => (
                                            <>
                                                <Col span={1}></Col>
                                                <Col span={11} key={key} >
                                                    <Radio value={item.id} style={{ fontSize: "12px", color: "#333333", fontWeight: "400" }}>{item.reason}</Radio>
                                                </Col>
                                            </>
                                        ))}
                                    </Row>
                                </Col>
                            </Radio.Group>
                            {!loader &&
                                <Col span={24} style={{ marginTop: "12px" }}>
                                    <TextArea
                                        style={{ height: 100, borderRadius: "10px", border: "1px solid #D3D6E1" }}
                                        onChange={(e) => {
                                            setText(e.target.value)
                                        }}
                                    />
                                </Col>
                            }
                        </Col>

                    </Row>}
            />
            <CustomizedModal
                visible={cancelVisible}
                title={null}
                modalWidth="450px"
                footer={null}
                onCancel={() => setCancelVisible(false)}
                content={<div style={{ padding: "12px 12px 24px 12px" }}>
                    <Row justify={"center"}>
                        <Col span={24} align="middle"><img src={cancelImage} alt='fyndr'></img></Col>
                        <Col span={24} style={{
                            textAlign: "center",
                            fontSize: "14px",
                            fontWeight: "400",
                            lineHeight: "20px",
                            textAlign: "center"
                        }} >
                            <Col>
                                Your subscription has been successfully canceled. We hope to see you again in the future.
                            </Col>
                        </Col>
                    </Row>
                </div>}
            />
            < CustomizedModal
                visible={updateVisible}
                title={"Update Subscription"}
                modalWidth="400px"
                footer={null}
                onCancel={() => { setUpdateMonth(0); setUpdatedDuration(0); setUpdateVisible(false); }}
                content={< div style={{ padding: "24px" }}>
                    <Col span={24} className='updateSubspopup'>Current Plan</Col>
                    <Col span={24} style={{ marginBottom: "24px", fontSize: "14px", color: "#333333", fontWeight: "400", paddingLeft: "12px" }}> {`${updateMonth} ${updateMonth === 1 ? 'Month' : 'Months'}`} <span className="promotion_priceSubs" style={{ paddingLeft: "8px" }}>{`$ ${featuredSubscription.find((item) => item.month === `${updateMonth} ${updateMonth === 1 ? 'Month' : 'Months'}`)?.value}`}</span></Col>
                    <Col span={24} className='updateSubspopup'>Other Options</Col>
                    <Radio.Group value={updatedduration} onChange={(e) => {
                        setUpdatedDuration(e.target.value);
                    }} style={{ marginBottom: "24px", width: "100%" }}>
                        <Col span={24} >
                            <Row gutter={[12, 12]} >
                                {featuredSubscription?.filter((item) => item.month !== `${updateMonth} ${updateMonth === 1 ? 'Month' : 'Months'}`).map((item, key) => (
                                    <>
                                        {console.log("item", item)}
                                        <Col span={1}></Col>
                                        <Col span={11} key={key} >
                                            <Radio value={item.month} className="promotion_monthsSubs" style={{ fontSize: "14px", color: updateMonth === item.value ? "#333333" : "#999999" }}>
                                                {item.month}{" "}<span className="promotion_priceSubs" style={{ paddingLeft: "8px" }}>{`$${item.value}`}</span> </Radio>
                                        </Col>
                                    </>
                                ))}
                            </Row>
                        </Col>
                    </Radio.Group>
                    <Col className='updateSubspopup_note'>
                        Note : Your Plan will be updated after current billing cycle ends.</Col>
                    <Row justify={"center"}>
                        <Button disabled={updatedduration === 0 ? true : false}
                            onClick={() => { UpdateSubscription(); setIsLoading(true); }}
                            style={{
                                backgroundColor: updatedduration === 0 ? "#999999" : "#257CDB",
                                color: "white",
                                border: updatedduration === 0 ? "1px solid #999999" : "1px solid #257CDB",
                                padding: "10px 16px",
                                borderRadius: "10px",
                                height: "46px",
                                fontSize: "16px",
                                width: "205px",
                                justifySelf: "center"

                            }}>
                            Save & Update
                        </Button>
                    </Row>
                </div >}
            />
            <CustomizedModal
                visible={updateSubsVisible}
                title={null}
                modalWidth="450px"
                footer={null}
                onCancel={() => setUpdateSubsVisible(false)}
                content={<div style={{ padding: "12px 12px 24px 12px" }}>
                    <Row justify={"center"}>
                        <Col span={24} align="middle"><img src={updateSubs} alt='fyndr'></img></Col>
                        <Col span={24} className='updateSubspopup_confirm' >
                            {isLoading ? "Updating Subscription... " : "Your Subscription has been sucessfully updated!!!"}
                        </Col>
                    </Row>
                </div>}
            />
        </ContainerWrapper >
    )
}

export default Promo