/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Col, List, message, Space, Row } from "antd";
import Button from "antd-button-color";
import Loader from "../../components/Loading/Loader";
import { useSelector } from "react-redux";
import { Container as ContainerWrapper, ListItem } from "../../components";
import Edit from "./Edit";
import UserActions from "../../actions";
import LoadingOverlay from "react-loading-overlay";
import { Theme } from "../../common";
import HelmetFyndr from "../../components/HelmetFyndr";
import getEnvVars from "../../environment";

const web = getEnvVars();
const Categories = (props) => {
  const { categories } = useSelector(({ catalogue }) => {
    return catalogue;
  });
  const {
    indv: { bizid },
  } = useSelector(({ auth }) => auth);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(categories);
  const [showedit, setShowEdit] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const getCategories = async () => {
    setIsLoading(true);
    await UserActions.fetchCategories({ bizid });
    setIsLoading(false);
  };
  useEffect(() => {
    getCategories();
    // setData(categories);
  }, []);
  useEffect(() => {
    categories.sort((a, b) => a.name.localeCompare(b.name));

    setData(categories);
  }, [categories]);
  const handleDeleteItem = async (item) => {
    let dispatchPayload = {
      objid: item.objid,
      bizid,
      name: item.name,
      description: item.description,
    };
    setIsLoading(true);
    let resp = await UserActions.deleteCategory(dispatchPayload);

    setIsLoading(false);
    resp.success ? message.success(resp.message) : message.error(resp.message);
    if (resp.success) {
      getCategories();
    }
  };
  return (
    <>
      <LoadingOverlay
        active={isLoading}
        spinner
        text=""
        styles={{
          overlay: (base) => ({
            ...base,
            background: Theme.overlay,
          }),
        }}
      >
        {showedit ? (
          <Edit
            isEdit={isEdit}
            item={selectedItem}
            callback={(value) => setShowEdit(value)}
          />
        ) : (
          <ContainerWrapper
            headerOption={
              <Row>
                <Space direction="horizontal">
                  <Col span={3}>{isLoading && <Loader />}</Col>
                  <Col span={4}>
                    <Button
                      type="primary"
                      onClick={() => {
                        setShowEdit(true);
                        setSelectedItem(null);
                        setIsEdit(false);
                      }}
                    >
                      Add Category
                    </Button>
                  </Col>
                </Space>
              </Row>
            }
            title={"Categories"}
          >
            {
              <HelmetFyndr
                title="Store"
                imgUrl={""}
                url={`${web}/catalogue/categories`}
                descr="catalogue categories"
              />
            }
            <List
              itemLayout="horizontal"
              dataSource={data}
              renderItem={(item, key) => (
                <ListItem
                  onClick={() => {
                    setSelectedItem(item);
                    setShowEdit(true);
                    setIsEdit(true);
                  }}
                  item={item}
                  deletePress={() => {
                    handleDeleteItem(item);
                  }}
                />
              )}
            />
          </ContainerWrapper>
        )}
      </LoadingOverlay>
    </>
  );
};

export default Categories;
