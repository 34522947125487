import React from "react";
import { Bar } from "react-chartjs-2";
export default function BarChart(props) {
  return (
    <div style={{height:"210px", width:"250px"}}>
      <Bar
        data={props.data}
        options={props.options}
      />
       </div>
    
  );
}