import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Form, Input, DatePicker, Button, Row, Col, Breadcrumb } from "antd";
import PulseLoader from "react-spinners/PulseLoader";
import { ArrowLeftOutlined } from "@ant-design/icons";
import ImageUploader from "./ImageUploader";
import PromoCode1 from "../../../assets/images/PromoCode1.png";

// import ImageUploader from "./ImageUploader";

const { TextArea } = Input;

const PromoPromotion = () => {
  const [uploadedImage, setUploadedImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const history = useHistory();

  const onFinish = (values) => {
    console.log("Received values of form: ", values);
  };

  const handleSubmit = (values) => {
    console.log("Form values:", values);
  };
  const handleImageUpload = (imageData) => {
    setUploadedImage(imageData.imgUri);
  };

  return (
    <div className="admin_container no-breadcrumb container-mw-xl chapter">
      <div className="quickLoader">
        <PulseLoader color={"#3F9AF7"} loading={loading} size={10} />
      </div>
      <article className="article">
        <Row justify="space-between">
          <Col style={{ display: "flex", alignItems: "center" }}>
            <Breadcrumb>
              <Breadcrumb.Item>Admin</Breadcrumb.Item>
              <Breadcrumb.Item>Promo code</Breadcrumb.Item>
              <Breadcrumb.Item>Create promo for Promotion</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          <Col>
            <Button
              type="link"
              style={{ fontSize: "16px", color: "#000" }}
              onClick={() => history.goBack()}
            >
              <ArrowLeftOutlined />
              Go Back
            </Button>
          </Col>
        </Row>
        <div style={{ padding: "24px", background: "#fff" }}>
          <Form
            form={form}
            layout="vertical"
            onFinish={handleSubmit}
            style={{
              width: 500,
              margin: "0 auto",
              padding: "20px",
              borderRadius: "4px",
            }}
          >
            <Form.Item>
              <ImageUploader />
            </Form.Item>
            <Form.Item name="promoCode">
              <Input
                style={{ borderRadius: "10px", height: "46px" }}
                placeholder="Promo Code"
              />
            </Form.Item>

            <Form.Item name="discount">
              <Input
                style={{ borderRadius: "10px", height: "46px" }}
                placeholder="Discount %"
              />
            </Form.Item>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item name="fromDate">
                  <DatePicker
                    placeholder="From"
                    style={{
                      borderRadius: "10px",
                      width: "100%",
                      height: "46px",
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="toDate">
                  <DatePicker
                    placeholder="To"
                    style={{
                      borderRadius: "10px",
                      width: "100%",
                      height: "46px",
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item name="description">
              <TextArea rows={4} placeholder="Add Description" />
            </Form.Item>

            <Form.Item style={{ display: "flex", justifyContent: "center" }}>
              <Button
                style={{ borderRadius: "10px", width: "200px", height: "46px" }}
                type="primary"
                htmlType="submit"
                block
              >
                Generate Promo Code
              </Button>
            </Form.Item>
          </Form>
        </div>
      </article>
    </div>
  );
};

export default PromoPromotion;
