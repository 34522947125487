import React, { useEffect } from "react";
import { Card, Row, Col, Typography, Space } from "antd";
import { EditOutlined } from "@ant-design/icons";
const Title = { Typography };

const OfferSummary = (props) => {
  useEffect(() => {
    console.log("All Data:", props);
  }, [props]);
  return (
    <div style={{ overflowY: "scroll", height: "70vh" }}>
      <Card title="Hair Color" style={{ borderColor: "#D3D6E1" }}>
        <Row>
          <Col span={8}>item1</Col>
          <Col style={{ textAlign: "center" }} span={8}>
            item2
          </Col>
          <Col style={{ textAlign: "right" }} span={8}>
            item3 <EditOutlined />
          </Col>
        </Row>
        <Card
          style={{
            borderRadius: "10px",
            overflow: "hidden",
            borderColor: "#D3D6E1",
          }}
          size="small"
        >
          <Row>
            <Col span={8}>item1</Col>
            <Col style={{ textAlign: "center" }} span={8}>
              item2
            </Col>
            <Col style={{ textAlign: "right" }} span={8}>
              item3
              <EditOutlined />
            </Col>
          </Row>
        </Card>
      </Card>

      <Card title="Hair Color" style={{ borderColor: "#D3D6E1" }}>
        <Row>
          <Col span={8}>item1</Col>
          <Col style={{ textAlign: "center" }} span={8}>
            item2
          </Col>
          <Col style={{ textAlign: "right" }} span={8}>
            item3 <EditOutlined />
          </Col>
        </Row>
        <Card
          style={{
            borderRadius: "10px",
            overflow: "hidden",
            borderColor: "#D3D6E1",
          }}
          size="small"
        >
          <Row>
            <Col span={8}>item1</Col>
            <Col style={{ textAlign: "center" }} span={8}>
              item2
            </Col>
            <Col style={{ textAlign: "right" }} span={8}>
              item3
              <EditOutlined />
            </Col>
          </Row>
        </Card>
      </Card>

      <Card title="Hair Color" style={{ borderColor: "#D3D6E1" }}>
        <Row>
          <Col span={8}>item1</Col>
          <Col style={{ textAlign: "center" }} span={8}>
            item2
          </Col>
          <Col style={{ textAlign: "right" }} span={8}>
            item3 <EditOutlined />
          </Col>
        </Row>
        <Card
          style={{
            borderRadius: "10px",
            overflow: "hidden",
            borderColor: "#D3D6E1",
          }}
          size="small"
        >
          <Row>
            <Col span={8}>item1</Col>
            <Col style={{ textAlign: "center" }} span={8}>
              item2
            </Col>
            <Col style={{ textAlign: "right" }} span={8}>
              item3
              <EditOutlined />
            </Col>
          </Row>
        </Card>
      </Card>
    </div>
  );
};

export default OfferSummary;
