import React from 'react'
import { Modal, Button, Row, Col, Divider } from 'antd'
import closeBtn from "../../assets/images/closeBtn.svg"
import Loader from '../Loading/Loader';
export default function CustomizedModal(props) {
  const closeIcon = <img src={closeBtn} alt="closeBtn" />;
  let footerContent = null;
  if (props.footer !== null) {
    footerContent = (
      <>
        <Divider className="customize_divider" />
        <Row justify="space-between" style={{ padding: "24px" }}>
          <Col span={12}>
            <Button
              style={props.btn1Style}
              onClick={props.onBtn1Click}
              type={props.btn1Type}
              className="customize_btn"
              icon={props.btn1Icon}
              disabled={props.btn1Disabled}
            >
              {props.btn1}
            </Button>
          </Col>
          <Col span={12}>
            <Button
              style={props.btn2Style}
              onClick={props.onBtn2Click}
              type={props.btn2Type}
              className={`customize_btn ${props.classNameBtn1}`}
              icon={props.btn2Icon}
              disabled={props.btn2Disabled}
            >
              {props.btn2}{props.isLoading && <Loader />}
            </Button>
          </Col>
        </Row>
      </>
    );
  }
  return (
    <Modal
      centered
      visible={props.visible}
      title={null}
      height={props.height}
      onCancel={props.onCancel}
      destroyOnClose={props.destroyOnClose}
      closeIcon={closeIcon}
      width={props.modalWidth}
      className="customize_modal"
      footer={footerContent}
    >
      <Row>
        <Col>
          <div className="customize_title">{props.title}</div>
        </Col>
      </Row>
      {props.title !== null && <Divider className="customize_divider" style={{margin: "0px"}}/>}
      {props.content}
    </Modal>
  );
}
