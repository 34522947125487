/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { Layout, Row, Col } from "antd";
import { useDispatch, useSelector } from "react-redux";
// import {useSelector} from "react-redux";
import { TopMenu } from "../../";
import Logo from "../../Logo";
import { Actions } from "../../../common";
import Notifications from "./FyndrNotification";
import { isMobile } from "../../../common/util";
import classNames from "classnames";
import actions from "../../../actions";
import { useHistory } from "react-router-dom";
const { TOGGLE_COLLAPSED_NAV, ROLE_TYPE } = Actions;
const { Header } = Layout;

const AppHeader = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  // const { collapsedNav } = useSelector(({ settings }) => settings);

  // const onToggleCollapsedNav = () => {
  //   dispatch({ type: TOGGLE_COLLAPSED_NAV, isCollapsedNav: !collapsedNav });
  // };
  const {
    indv: { indvid }
  } = useSelector(({ auth }) => auth)
  useEffect(() => {
    if (isMobile()) {
      dispatch({ type: TOGGLE_COLLAPSED_NAV, isCollapsedNav: true });
    }
  }, []);
  const findPath = (path) => {
    let index = path.lastIndexOf("/");
    index = index === 0 ? path.length : index;
    console.log(path.substr(0, index));
    return path.substr(0, index);
  };
  const {
    isMasquerade,
    indv: { email, regMode },
  } = useSelector(({ admin }) => admin);
  const menuClass = classNames({
    "bg-danger-fyndr": isMasquerade,
    "bg-info": !isMasquerade,
  });
  let DirectRoutes = [
    "/",
    "/offer-details",
    "/register",
    "/register/profile",
    "/qr",
    "/invoice-pay",
    "/voucher/",
    "/login",
    "/external",
    "/reset-password",
    "/offer-listing",
    "/offers",
    "/wallet",
    "/aboutus"
  ];
  // let NoHeader = ["/catalogue/user/preview"];
  let NoHeader = ["/catalogue/store/"];
  let backToAdminFunction = async () => {
    let response = await actions.BackToAdmin({ email, regMode });
    if (response.success === true) {
      history.push("/admin/dashboard");
      dispatch({ type: ROLE_TYPE, payload: null })
    }
  };
  return (
    <>
      {props.location.pathname.includes(NoHeader) === false ? (
        <Header className="app-header">
          <div className={`app-header-inner ${menuClass}`}>
            <div className="header-left">
              <Col style={{ paddingLeft: "5vh", verticalAlign: "middle" }} >
                <Logo />
              </Col>
            </div>

            <div className="header-right-common">
              <Row justify="start" align="middle">
                <Col
                  align="middle"
                  span={
                    DirectRoutes.includes(findPath(props.location.pathname))
                      ? 12
                      : 4
                  }
                  style={{ paddingRight: "3rem" }}
                >
                  {indvid !== null && !DirectRoutes.includes(
                    findPath(props.location.pathname)
                  ) && <Notifications />}
                </Col>
                <Col
                  span={
                    DirectRoutes.includes(findPath(props.location.pathname))
                      ? 18
                      : 14
                  }
                  style={{ paddingRight: "3rem" }}
                >
                  <TopMenu {...props} />
                </Col>
              </Row>

              <div className="list-unstyled list-inline"> </div>
            </div>
            {isMasquerade && (
              <div
                onClick={() => {
                  backToAdminFunction();
                }}
                className="admininfo"
              >
                <h5>Back to Admin </h5>
              </div>
            )}
          </div>
        </Header>
      ) : null}
    </>
  );
};

export default AppHeader;
