/* eslint-disable import/no-anonymous-default-export */
import { Actions } from "../common";
const { ADMIN_ACCOUNT,LOG_OUT,MASQUERADE,ADMIN_BACK } = Actions;

const initialState = {
  indv: {},
  isMasquerade:false
};

export default function (state = initialState, action) {
  if (action.type === "undefined") {
    return state;
  }
  switch (action.type) {
    case ADMIN_ACCOUNT: {
      return { ...state, indv: action.payload };
    }
    case MASQUERADE: {
      return { ...state, isMasquerade: action.payload };
    }
    case ADMIN_BACK:{
      return{...state,isMasquerade:false}
    }
    case LOG_OUT: {
      // localStorage.clear();
      var myItem = localStorage.getItem('tourOpen');
      var myItem2 = localStorage.getItem('tourOpenDashboard')
      var navTour = localStorage.getItem('tourSideNav')
      var interactionsTour = localStorage.getItem('tourOpenInteractions');
      var indicatorTour = localStorage.getItem("tourOpenIndicators")

     localStorage.clear();
     localStorage.setItem('tourOpen',myItem);
     localStorage.setItem('tourOpenDashboard', myItem2);
     localStorage.setItem('tourSideNav', navTour)
     localStorage.setItem('tourOpenInteractions', interactionsTour)
     localStorage.setItem('tourOpenIndicators', indicatorTour)

      return initialState;
    }
    default: {
      return state;
    }
  }
}
