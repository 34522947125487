import React from "react";
import { Col, Row, Typography, Image } from "antd";
import ReviewStars from "../ReviewStars/ReviewStars";
const { Text } = Typography;
export default function ParticularComments(props) {
  return (
    <>
      <Row style={{ width: "100%", paddingLeft: "12px" }}>
        <Col>
          <Row>
            <div className="particular-review_username">{props.name}</div>

            <div className="particular-review_time">{props.date}</div>
          </Row>
          <div style={{ paddingLeft: "12px", paddingTop: "4px" }}>
            <ReviewStars rating={props.rating} disabled={true} />
          </div>
        </Col>
      </Row>

      <Row style={{ padding: '24px 24px 36px 24px' }} gutter={[24, 24]} >
                {props?.images?.map((image, index) => {
                    return (
                    <Col key={index} xs={24} sm={12} md={8} lg={8} xl={8} xxl={8}>
                        <div >
                            <Image
                                resizeMode={"contain"}
                                preview={false}
                                src={image} alt="img" className={props.imgClassName} />
                        </div>
                    </Col>
                    )}
                )}
            </Row>
      <div className={props.className}>
        <Row>
          <Text
            style={{
              color: "#4D4D4D",
              fontSize: "16px",
              lineHeight: "31px",
            }}
          >
            {props.review}
          </Text>
        </Row>
        <div className={props.overflowClassName}>
          {props?.reviewComment?.commentThread?.map((data, index) => {
            return (
              <div className={props.textClassNameForPopUp} style={props.style}>
                <Text
                  style={{
                    color: "#4D4D4D",
                    fontSize: "16px",
                    lineHeight: "normal",
                  }}
                >
                  {data?.reply}
                </Text>
              </div>
            );
          })}
        </div>
        {props.btns}
      </div>
    </>
  );
}
