import React from 'react'
import { Rate } from 'antd';

import { isMobile } from '../../common/util'


function ReviewStars(props) {
  const { rating,disabled ,onChange,onStarsClick} = props;

  
  return (
    <>
      {!isMobile() ? (
        <>
          <div onClick={onStarsClick} style={{ cursor: "pointer" }}>
            <Rate disabled={disabled} value={rating} defaultValue={rating} {...props} onChange={onChange} allowHalf={disabled ? true : false} className='star'/>
          </div>
        </>
      ) : (
        <div  onClick={onStarsClick} style={{ cursor: "pointer" }}>
          <Rate  disabled={disabled} value={rating} defaultValue={rating} {...props} allowHalf={disabled ? true : false} onChange={onChange} />

        </div>
      )}
    </>

  )
}

export default ReviewStars
