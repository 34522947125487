
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { LikeOutlined, DislikeOutlined } from "@ant-design/icons";
import Button from "antd-button-color";
import UserActions from "../../actions";
import ImageViewer from "react-simple-image-viewer";
import { message, Typography, Row, Col, Space } from "antd";
import { Theme, Actions } from "../../common";
import Loader from "../../components/Loading/Loader";
import { isMobile } from "../../common/util";

const { Text } = Typography;

const ItemView = (props) => {
  const { ROLE_FYNDR } = Actions;

  const {
    offer: {
      objid,
      title,
      amount,
      discountType,
      couponCode,
      offerPrice,
      retailPrice,
      imgUri,
      offerType,
      usageLimit,
      currencySymbol,
    },
    image,
    isPreview,
  } = props;
  const {
    indv: { indvid, entityType },
  } = useSelector(({ auth }) => auth);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [notUsefulloader, setNotUsefulloader] = useState(false);
  const [disablebtns, setDisabledBtns] = useState(false)

  let Barcode = require("react-barcode");

  const offerFeedback = async (useful) => {
    let resp = await UserActions.offerFeedback({
      objid: null,
      indvId: indvid,
      offerId: objid,
      useful,
    });
    if (resp.success) {
      setLoading(false);
      setNotUsefulloader(false)
      message.success("Thank you for your Feedback!");
      setDisabledBtns(false)
    }
    else {
      message.error(resp?.msg)
    }

  };
  return (
    <Row justify="center">
      <Col justify="center" align="center">
        <div style={{ marginBottom: "1.25rem" }}>
          <img
            alt="offer"
            onClick={() => {
              setIsViewerOpen(true)
              props.callback()
            }
            }
            className="itemview--img"
            src={imgUri ? imgUri : image}
          />

          {isViewerOpen && (
            <ImageViewer
              onClose={() => {
                setIsViewerOpen(false)
                props.viewClose()
              }}
              activeIndex={0}
              src={[imgUri ? imgUri : image]}
            />
          )}
        </div>
        <div>
          <Text className="itemview--title">{title}</Text>

          <div>
            <div>
              {offerType === "coupons" && (
                <div>
                  <Barcode
                    width={1.8}
                    height={50}
                    value={couponCode}
                    format="CODE128"
                  />
                </div>
              )}

              {offerType === "offers" && (
                <div style={{ marginTop: "1.25rem" }}>
                  <Space direction="horizontal" size="middle">
                    <Text delete style={{ color: "#A1A1A1", fontSize: "14px", fontWeight: "400" }}>
                      {currencySymbol}
                      {retailPrice}
                    </Text>
                    <Text style={{ fontSize: "16px", fontWeight: "400" }}>
                      {currencySymbol}
                      {offerPrice}
                    </Text>
                    <Text
                      style={{
                        backgroundColor: Theme.yellow,
                        padding: "0.2rem 0.7rem",
                        borderRadius: "5px",
                      }}
                    >
                      {`${discountType === "%"
                        ? amount + "%"
                        : currencySymbol + amount
                        } OFF`}
                    </Text>

                    {usageLimit !== -1 && (
                      <Text style={{ color: "#FF0101", fontSize: "14px", fontWeight: "400" }} >Limit: {usageLimit}</Text>
                    )}
                  </Space>
                </div>
              )}
            </div>
          </div>
        </div>
        {indvid && entityType !== ROLE_FYNDR && (
          <div className="itemview--div-text-and-buttons">
            <h5>This Offer Was?</h5>
            <Space direction="horizontal">
              <Button
                disabled={disablebtns ? true : false}
                className="itemview--useful-btn"
                type="primary"
                onClick={() => {
                  setLoading(true);
                  isPreview
                    ? message.info("User will be able to like the offer")
                    : offerFeedback(true);
                  setDisabledBtns(true)

                }
                }
                size={isMobile() ? "small" : "large"}
              >
                Useful
                <LikeOutlined className="itemview--like-icon" />
                {loading && <Loader />}
              </Button>

              <Button
                disabled={disablebtns ? true : false}
                className="itemview--not-useful-btn"
                onClick={() => {
                  setNotUsefulloader(true);
                  isPreview
                    ? message.info("User will be able to dislike the offer")
                    : offerFeedback(false);
                    setDisabledBtns(true)

                }
                }
                type="warning"
                size={isMobile() ? "small" : "large"}
              >
                Not Useful
                <DislikeOutlined className="itemview--dislike-icon" />
                {notUsefulloader && <Loader />}
              </Button>
            </Space>
          </div>
        )}
      </Col>
    </Row>
  );
};

export default ItemView;