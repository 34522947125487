/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Col, List, message, Row, Space } from "antd";
import Button from "antd-button-color";
import Loader from "../../components/Loading/Loader";
import { useSelector } from "react-redux";

import { Theme } from "../../common";
import { Container as ContainerWrapper, ListItem } from "../../components";
import Edit from "./Edit";
import UserActions from "../../actions";
import LoadingOverlay from "react-loading-overlay";
import getEnvVars from "../../environment";
import HelmetFyndr from "../../components/HelmetFyndr";

const web = getEnvVars();
const Items = (props) => {
  const { items } = useSelector(({ catalogue }) => {
    return catalogue;
  });
  const {
    indv: { bizid },
  } = useSelector(({ auth }) => auth);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(items);
  const [showedit, setShowEdit] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const getItems = async () => {
    setIsLoading(true);
    await UserActions.fetchItems({ bizid });
    setIsLoading(false);
  };
  useEffect(() => {
    // setData(items);
    getItems();
  }, []);
  useEffect(() => {
    items.sort((a, b) => a.name.localeCompare(b.name));

    setData(items);
  }, [items]);
  const handleDeleteItem = async (item) => {
    let dispatchPayload = {
      bizid,
      objid: item.objid,
    };
    setIsLoading(true);
    let resp = await UserActions.deleteItem(dispatchPayload);
    setIsLoading(false);
    resp.success ? message.success(resp.message) : message.error(resp.message);
    if (resp.success) {
      getItems();
    }
  };
  return (
    <>
      <LoadingOverlay
        active={isLoading}
        spinner
        text=""
        styles={{
          overlay: (base) => ({
            ...base,
            background: Theme.overlay,
          }),
        }}
      >
        {showedit ? (
          <Edit
            isEdit={isEdit}
            item={selectedItem}
            callback={(value) => setShowEdit(value)}
          />
        ) : (
          <ContainerWrapper
            headerOption={
              <Row>
                <Space direction="horizontal">
                  <Col span={3}>{isLoading && <Loader />}</Col>
                  <Col span={4}>
                    <Button
                      type="primary"
                      onClick={() => {
                        setShowEdit(true);
                        setSelectedItem(null);
                        setIsEdit(false);
                      }}
                      // disabled={isLoading}
                    >
                      Add Item
                    </Button>
                  </Col>
                </Space>
              </Row>
            }
            title={"Items"}
          >
            {
              <HelmetFyndr
                title="Store"
                imgUrl={""}
                url={`${web}/catalogue/items`}
                descr="catalogue items"
              />
            }
            <List
              itemLayout="horizontal"
              dataSource={data}
              renderItem={(item, key) => (
                <ListItem
                  onClick={() => {
                    setSelectedItem(item);
                    setShowEdit(true);
                    setIsEdit(true);
                  }}
                  item={item}
                  deletePress={() => {
                    handleDeleteItem(item);
                  }}
                />
              )}
            />
          </ContainerWrapper>
        )}
      </LoadingOverlay>
    </>
  );
};

export default Items;
