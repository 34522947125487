import React from "react";
import { Modal, Row, Col, Button } from "antd";
export default function ModalRaiseADispute(props) {
  return (
    <div>
      <Modal
        destroyOnClose
        title={props.title || "Please let us know more"}
        centered
        width={props.width}
        visible={props.visible}
        footer={null}
        onCancel={props.onCancel}
        className={props.className}
      >
        <Row gutter={[0, 24]}>
          <Col xs={24}>{props.input}</Col>
          <Col span={24}>{props.description}</Col>
          <Row justify={"center"} style={{ width: "100%" }}>
            <Button type="primary" onClick={props.onClick} className={props.btnClassName}>
              {props.button}
            </Button>
          </Row>
        </Row>
      </Modal>
    </div>
  );
}
