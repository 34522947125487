/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Row, Col, Space, message } from "antd";
import { RichTextViewer } from "../../components";
import userActions from "../../actions/";
import {useLocation} from "react-router-dom"
import { ColorExtractor } from "react-color-extractor";
import GoogleMapNew from "../../components/GoogleMap";
import { Marker } from "react-google-maps";

import { isMobile, parseAddress } from "../../common/util";
import { PhoneOutlined, MailOutlined } from "@ant-design/icons";
import Images from "../../assets";

// const appScheme = "fyndr://";
const isMobileCheck = {
  Android: function () {
    return navigator.userAgent.match(/Android/i);
  },
  BlackBerry: function () {
    return navigator.userAgent.match(/BlackBerry/i);
  },
  iOS: function () {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i);
  },
  Opera: function () {
    return navigator.userAgent.match(/Opera Mini/i);
  },
  Windows: function () {
    return (
      navigator.userAgent.match(/IEMobile/i) ||
      navigator.userAgent.match(/WPDesktop/i)
    );
  },
  any: function () {
    return (
      isMobileCheck.Android() ||
      isMobileCheck.BlackBerry() ||
      isMobileCheck.iOS() ||
      isMobileCheck.Opera() ||
      isMobileCheck.Windows()
    );
  },
};
const ImageGrd = ({ src }) => {
  return (
    <img
      alt="biz"
      src={src}
      style={{
        objectFit: "contain",
        opacity: ".5",
        maxHeight: "50vw",
        width: "100%",
        maxWidth: "100%",
      }}
    />
  );
};
function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}
const Bhome = (props) => {
  const [showStore, setShowStore] = useState(false);
  const [showOffer, setShowOffer] = useState({ value: false, count: 0 });
let query= useQuery()
const[qrLocId,SetqrLocId] = useState(query.get("id"))
  const [qrid, setQRId] = useState(null);
  const [catalogueDetails, setCatalogueDetails] = useState(null);
  const [, setIsLoading] = useState(false);
  const [, setShowScreen] = useState(false);
  const [locationPayload, setLocationPayload] = useState(null);
  const [themeColors, setThemeColor] = useState([]);
  const QRCODEPATH = "/qr/";
  const getLocationDetails = async (locQR) => {
    
    setIsLoading(true);
    let result = await userActions.fetchLocationByQr({ qrcode: locQR });
    setIsLoading(false);

    if (result.success) {
      setQRId(result.payload.qrid);
      setIsLoading(true);
      setShowScreen(true);
      setLocationPayload(result.payload);
      let offerCount = await userActions.fetchLocOffers(result.payload.objid);

      if (offerCount.success && offerCount?.resp[0]?.count > 0) {
        setShowOffer({
          value: true,
          count: offerCount.resp[0]?.count,
          img: offerCount.resp[0]?.cmpnImg,
          activeCampaignCount: offerCount.resp[0]?.activeCampaignCount,
          cmpnQrCode:offerCount.resp[0]?.cmpnQrCode,

        });
      }
      let catalogue_details = await userActions.fetchCatalogueByQRLocation({
        qrcode: result.payload.qrid,
      });
      setIsLoading(false);

      if (catalogue_details.success) {
        setShowStore(true);
        setCatalogueDetails(catalogue_details.payload);

        if (props?.history?.location?.state?.isLogin) {
          message.success("Login successful");
          props.history.push(`/catalogue/store/${catalogue_details.payload.url}`, {
            catalogueDetail: catalogue_details.payload,
            loc: props.match.params.qrCode,
            isLogin: false,
          });
        }
      }

      if (
        (offerCount?.resp?.length < 1 ||
          (Array.isArray(offerCount?.resp) &&
            offerCount?.resp[0]?.count < 1)) &&
        catalogue_details?.success === false
      ) {
        setShowStore(false);
        setShowOffer({ value: false, count: 0 });
        props.history.push("/app-download");
      }
    } else {
      message.error(result.message);
      props.history.push("/app-download");
    }
  };
  // if (isMobileCheck.any()) {
  //   window.location.href = appScheme;
  // }
  // React.useEffect(() => {
  //   if (props.match.path === QRCODEPATH) {
  //     getLocationDetails(qrLocId);
  //   }
  // }, []);
  React.useEffect(() => {
    if (props.match.path === QRCODEPATH) {
      getLocationDetails(qrLocId);
    }
  }, []);

  const getColors = (colors) => {
    if (themeColors.length !== colors.length) setThemeColor(colors);
  };

  // if (catalogueDetails) {
  return (
    <Row className="bizHome" justify="center">
      <Col style={{ background: "#fff" }} xs={24} md={18}>
        <div
          style={
            {
              // background: `linear-gradient(120deg,${
              //   themeColors && themeColors[1]
              // } 40%,${themeColors && themeColors[3]} 100%)`,
            }
          }
          className="bizHome-layer"
        >
          <ColorExtractor
            cors={true}
            maxColors={2}
            src={`${catalogueDetails?.biz?.mainLogo}?t=${new Date().getTime()}`}
            getColors={(colors) => getColors(colors)}
          />

          <ImageGrd
            src={
              catalogueDetails
                ? catalogueDetails?.catalogue.images[0].img_url +
                  `?t=${new Date().getTime()}`
                : showOffer?.img + `?t=${new Date().getTime()}` ||
                  locationPayload?.biz?.mainLogo +
                    `?t=${new Date().getTime()}` ||
                  Images.Images.PlaceHolder
            }
          />
        </div>
        <Row>
          <Col className=" bizHome-dpimage" align="center" xs={5} md={4}>
            <img
              alt="Store"
              style={{
                width: "100%",
                objectFit: "contain",
                height: isMobile() ? "8rem" : "15rem",
                borderRadius: "50%",
                border: ".5px solid #bbb",
                background: "#fff",
              }}
              src={`${
                catalogueDetails?.biz?.mainLogo ||
                locationPayload?.biz?.mainLogo
              }?t=${new Date().getTime()}`}
            />
          </Col>
          <Col
            style={{ paddingTop: "1rem", paddingLeft: "2.5rem" }}
            xs={18}
            md={20}
          >
            <h3 className="businessHome_headermain-title">
              {locationPayload?.biz?.bizName}
            </h3>
          </Col>
        </Row>
        <Col
          style={{ padding: "0  1rem  0 1rem " }}
          span={24}
          className="businessHome_headermain"
        >
          <h2 className="businessHome_headermain-title-sub">
            {catalogueDetails?.catalogue?.name}
          </h2>
          <div
            style={{
              textAlign: "justify",
              marginBottom: "2rem",
              marginTop: "2rem",
            }}
          >
            <RichTextViewer
              content={catalogueDetails?.catalogue?.description}
            />
          </div>
        </Col>
        <Col
          style={{ padding: "1rem" }}
          span={24}
          className="businessHome_headermain"
        >
          <h2 className="businessHome_headermain-title-sub">{`Explore`}</h2>

          <Row align="middle" justify="space-around">
            {showOffer.value && (
              <Col align={"center"} xs={24} md={8} span={8}>
                <div
                  onClick={() => props.history.push("/offers", { locQRId: qrid })}
                  style={{
                    cursor: "pointer",
                    background: `linear-gradient(135deg,${
                      themeColors && themeColors[0]
                    } 40%,${themeColors && themeColors[2]} 100%)`,
                  }}
                  className="businessHome_offerBox"
                >
                  <h3 className="businessHome_headermain-title-sub">
                    {`Offers (${showOffer.count})`}
                  </h3>
                </div>
              </Col>
            )}
            {showStore && (
              <Col
                align="center"
                xs={showStore ? 24 : 0}
                md={showStore ? 8 : 0}
              >
                <div
                  onClick={() => {
                    props.history.push(`/catalogue/store/${catalogueDetails.url}`, {
                      catalogueDetail: catalogueDetails,
                      loc: props.match.params.qrCode,
                    });
                  }}
                  style={{
                    cursor: "pointer",
                    background: `linear-gradient(135deg,${
                      themeColors && themeColors[0]
                    } 40%,${themeColors && themeColors[2]} 100%)`,
                  }}
                  className="businessHome_offerBox"
                >
                  <h3 className="businessHome_headermain-title-sub">Store</h3>
                </div>
              </Col>
            )}
          </Row>
        </Col>
        <Col
          style={{ padding: "1rem" }}
          span={24}
          className="businessHome_headermain"
        >
          <h2 className="businessHome_headermain-title-sub">{`Find us`}</h2>
          <Row align="middle" justify="space-between">
            <Col style={{ marginTop: "1rem" }} xs={showStore ? 24 : 0} md={12}>
              <div>
                <Space direction="horizontal">
                  <MailOutlined
                    style={{ fontSize: "1.5rem" }}
                    className="act"
                  />

                  <h6>{`Address: ${parseAddress(
                    catalogueDetails?.biz || locationPayload?.biz
                  )} `}</h6>
                </Space>
              </div>
              <Space direction="horizontal">
                <a
                  href={`tel:${
                    catalogueDetails?.biz?.phone || locationPayload?.biz?.phone
                  }`}
                >
                  <PhoneOutlined
                    style={{ fontSize: "1.5rem" }}
                    className="act"
                  />
                </a>
                <h6 className="businessHome_header-heading">{`${
                  catalogueDetails?.biz?.phone || locationPayload?.biz?.phone
                } `}</h6>
              </Space>
              {catalogueDetails?.biz.website && (
                <h6 className="businessHome_header-heading">{`website: ${
                  catalogueDetails?.biz?.website ||
                  locationPayload?.biz?.website
                } `}</h6>
              )}
            </Col>
            <Col xs={24} md={12}>
              {
                <div style={{ width: "100%", height: "20rem" }}>
                  <GoogleMapNew
                    zoom={11}
                    defaultCenter={{
                      lat: locationPayload?.lat,
                      lng: locationPayload?.lng,
                    }}
                  >
                    <Marker
                      position={{
                        lat: locationPayload?.lat,
                        lng: locationPayload?.lng,
                      }}
                    />
                  </GoogleMapNew>
                </div>
              }
            </Col>
          </Row>
        </Col>
      </Col>
    </Row>
  );
  // } else {
  //   return (
  //     <Row
  //       justify="center"
  //       align="middle"
  //       style={{ height: "100vh", width: "100%" }}
  //     >
  //       <h6>loading</h6>;
  //     </Row>
  //   );
  // }
};

export default Bhome;
