/* eslint-disable no-redeclare */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { QRCode } from "react-qrcode-logo";
import { Checkbox, Row, Col, message, Button } from "antd";
import Theme from "../../common/Theme";
import Loader from "../../components/Loading/Loader";
import getEnvVars from "../../environment";
import { UploadOutlined } from "@ant-design/icons";
import UserActions from "../../actions";
import { Uploader } from "../../components";
import ContainerWrapper from "../../components/ContainerWrapper";
import HelmetFyndr from "../../components/HelmetFyndr";
// import Resizer from "react-image-file-resizer";
const { web } = getEnvVars();
const UploadImage = (props) => {
  const {
    match: {
      params: { type },
    },
  } = props;
  const { bizName, bizid, qrLogo, mainLogo } = useSelector(
    ({ auth }) => auth.indv
  );

  const [isAgreed, setIsAgreed] = useState(false);
  const [loader, setLoader] = useState(false);
  const [imgUrl] = useState(null);
  const [resetUploader, setResetUploader] = useState(false);
  const [uploadImage, setUploadImage] = useState(
    type === "qr" ? qrLogo : mainLogo
  );
  const BIZNOTE =
    "Please upload your business logo here, it will be displayed on the pages like offer listings, business terms of service etc.";
  const QRNOTE =
    "Please upload the logo here to be placed on the center of QR image of your interaction venues.";

  useEffect(() => {
    setUploadImage(type === "qr" ? qrLogo : mainLogo);
  }, [type]);

  useEffect(() => {
    type === "qr" ? setUploadImage(qrLogo) : setUploadImage(mainLogo);
  }, [qrLogo, mainLogo]);
  const [extension, setExtension] = useState();

  const uploadImageFunction = async () => {
    if (!isAgreed) {
      message.error("Please agree to the terms before proceeding.");
      return;
    }
    if (uploadImage?.indexOf("s3-us-west-1.amazonaws.com") > 0) {
      message.error("Please upload an image before proceeding");
      return;
    }

    setLoader(true);

    // let img = await Image.load(uploadImage.toString());
    // if (type === "qr" && img.height > 140) {
    //   // var newImage = await img.resize({ height: 140, width: 140 }).toBase64();
    //   var newImage = await resize(file.originFileObj, 140, 140);
    // } else if (type !== "qr" && img.height > 384) {
    //   var newImage = await img.resize({ height: 384 }).toBase64();
    // } else {
    //   img = img.toBase64();
    // }

    const data = { bizName, bizid, extension: extension };
    // let base64data = newImage
    //   ? newImage
    //   : uploadImage.substr(uploadImage?.indexOf("base64,") + 7);
    let base64data = uploadImage.substr(uploadImage?.indexOf("base64,") + 7);

    type === "qr" ? (data.qrLogo = base64data) : (data.mainLogo = base64data);

    let response = await UserActions.uploadLogo(data, type);
    if (response.success) {
      setResetUploader(true);
      message.success("Logo uploaded successfully");
    } else {
      message.error(response.message);
    }
    setLoader(false);
  };

  // const resize = (file, width, height) => {
  //   return new Promise((resolve) => {
  //     Resizer.imageFileResizer(
  //       file,
  //       width,
  //       height,
  //       "JPEG",
  //       100,
  //       0,
  //       (uri) => {
  //         resolve(uri);
  //       },
  //       "base64"
  //     );
  //   });
  // };

  return (
    <ContainerWrapper title={type === "qr" ? "Customize QR logo" : "Business Logo Upload"}>
      {
          <HelmetFyndr
            title="Business "
            imgUrl={""}
            url={`${web}/account/upload_logo/main`}
            descr="account upload_logo main"
          />
        }
        <div className="callout callout-info mt-0 mb-5">
          {type === "qr" ? QRNOTE : BIZNOTE}
        </div>
            <Row align="middle" justify="space-around">
              {uploadImage && (
                <Col>
                  {type === "qr" ? (
                    <QRCode
                      style={{ maxWidth: "100%" }}
                      size={160}
                      logoWidth={40}
                      logoImage={
                        uploadImage?.indexOf("s3-us-west-1.amazonaws.com") > 0
                          ? uploadImage?.indexOf("qrLogo") > 0
                            ? `${uploadImage}?m=${new Date().getTime()}`
                            : ""
                          : uploadImage
                      }
                    />
                  ) : (
                    <img
                      style={{ maxWidth: "30rem" }}
                      alt="Fyndr"
                      src={
                        uploadImage?.indexOf("s3-us-west-1.amazonaws.com") > 0
                          ? uploadImage?.indexOf("mainLogo") > 0
                            ? `${uploadImage}?m=${new Date().getTime()}`
                            : ""
                          : uploadImage
                      }
                    />
                  )}
                </Col>
              )}
              {!uploadImage && (
                <div
                  style={{
                    width: "30rem",
                    height: "15rem",
                    background: Theme.overlay,
                    padding: "2rem",
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <UploadOutlined style={{ fontSize: "2rem" }} />
                  <h4>Upload Company Logo</h4>
                </div>
              )}
              <Col>
                <Uploader
                  uploaderType="text"
                  label={
                    <Button icon={<UploadOutlined />}>Select Image</Button>
                  }
                  allowedTypes={["image"]}
                  imgUrl={imgUrl}
                  callback={({ name, img }) => {
                    setUploadImage(img);
                    setExtension(name.substr(name.lastIndexOf(".") + 1));
                    setResetUploader(false);
                  }}
                  aspect={type === "qr" ? 1 : 2}
                  reset={resetUploader}
                />
              </Col>
            </Row>

            <Row
              style={{ marginTop: "10%" }}
              align="middle"
              justify="space-around"
            >
              <Col span={18}>
                {uploadImage ? (
                  <Checkbox
                    checked={isAgreed}
                    onChange={(change) => {
                      setIsAgreed(change.target.checked);
                    }}
                  >
                    I represent and warrant that I have full ownership and/or
                    licensing rights to the image and authorize Fyndr to use
                    this image as per:{" "}
                    <span>
                      <a target="_blank" rel="noopener noreferrer" href={`${web}/legal/agreement.html`}>
                        Fyndr Business Agreement
                      </a>
                    </span>
                  </Checkbox>
                ) : null}
              </Col>
              <Col span={4}>
                {loader ? (
                  <Loader />
                ) : (
                  <Button
                    disabled={
                      uploadImage?.indexOf("s3-us-west-1.amazonaws.com") > 0
                    }
                    type="primary"
                    size="large"
                    onClick={() => uploadImageFunction()}
                  >
                    Save
                  </Button>
                )}
              </Col>
            </Row>
    </ContainerWrapper>
  );
};

export default UploadImage;
