import { Actions } from '../common';

const { SET_IS_BOTTOM } = Actions;

export const setBottom = (isBottom) => {
  return {
    type: SET_IS_BOTTOM,
    payload: isBottom
  };
};
