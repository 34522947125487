import React from "react";
import { Card, Col, Row } from "antd";
const AppInformation = () => (
  <Row gutter={16}>
    <Col span={8}>
      <Card title="Name" bordered={false}>
        {process.env.REACT_APP_NAME}
      </Card>
    </Col>
    <Col span={8}>
      <Card title="Version" bordered={false}>
        {process.env.REACT_APP_VERSION}
      </Card>
    </Col>
    {/* <Col span={8}>
      <Card title="Card title" bordered={false}>
        Card content
      </Card>
    </Col> */}
  </Row>
);
export default AppInformation;
