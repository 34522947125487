// NotFoundPage.js

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Actions } from "../../common";
import { Row, Col, Button, Image } from "antd";
import gif from "../../assets/images/error_gifnew.gif";
import { useHistory } from 'react-router-dom';
import imggg from "../../assets/images/imgggg.svg"
import lsidemob from "../../assets/images/lsidemob.svg"
import Desk from "../../assets/images/desk.png"
const { LOADER_VISIBLE } = Actions;

const NotFoundPage = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    useEffect(() => {
        dispatch({
            type: LOADER_VISIBLE,
            payload: false,
        });
    }, [])
    return (
        <div style={{ justifyContent: 'center', height: "100%" }} >
            {/* <Row style={{
                backgroundImage: `url(${Desk})`, backgroundRepeat: "no-repeat",
            }} className='notfound_row'>
                <Col lg={12} xl={12} style={{display:"flex",flexDirection:"column",justifyContent:"center" ,textAlign:'start',paddingLeft:"6rem"}}>
                    <p className='notfound_para' style={{ lineHeight: ".8rem", marginBottom: "1.2rem" }}>Something went <strong style={{ fontSize: "30px" }}>Wrong.</strong></p>
                    <p className='notfound_para' style={{ lineHeight: "2rem", marginBottom: "2rem" }}>The page you are looking for does not exist.</p>
                    <Button type='primary' size='large' style={{ borderRadius: "10px", width: "200px", fontSize: "20px", fontWeight: "400", padding: "0", backgroundColor: "#257CDB", height: "46px" }}
                        onClick={() => {
                            history.push("/");
                        }}>Go to home</Button>
                </Col>
                <Col lg={12} xl={12}>
                    <Image src={gif} preview={false} />
                </Col>
            </Row> */}

            {/* <Row>
                <Col className='notfound_col' xs={0} sm={0} md={10} lg={8} xl={10} xxl={10} style={{ paddingTop: "5.5rem", border: "1px solid red" }}>
                    <div style={{ position: "absolute", paddingTop: "5.5rem" }}>
                        <Image src={imggg} preview={false}></Image>
                    </div>
                    <div style={{ position: "relative", paddingLeft: "6rem", border: "1px solid green" }}>
                        <p className='notfound_para' style={{ lineHeight: ".8rem", marginBottom: "1.2rem" }}>Something went <strong style={{ fontSize: "30px" }}>Wrong.</strong></p>
                        <p className='notfound_para' style={{ lineHeight: "2rem", marginBottom: "2rem" }}>The page you are looking for does not exist.</p>
                        <Button type='primary' size='large' style={{ borderRadius: "10px", width: "200px", fontSize: "20px", fontWeight: "400", padding: "0", backgroundColor: "#257CDB", height: "46px" }}
                            onClick={() => {
                                history.push("/");
                            }}>Go to home</Button>
                            </div>
                </Col>
                <Col xs={0} sm={0} md={14} lg={8} xl={14} xxl={14} className='notfound_rightcol'>
                    <Image src={gif} preview={false} />
                </Col>



                <Col xs={24} sm={24} md={0} lg={0} xl={0} className='notfound_rightcolPhone' style={{border:"1px solid green"}}>
                    <Image src={gif} preview={false} className='notfound_img' />
                </Col>
                <Col className='notfound_colPhone' xs={24} sm={24} md={24} lg={0} xl={0} xxl={0}>
                    <div style={{ position: "relative", alignSelf: "center", width: "100%" ,border:"1px solid yellow" }}>
                        <p className='notfound_para1'>Something went <strong className='notfound_strong'>Wrong.</strong></p>
                        <p className='notfound_para2'>The page you are looking for does not exist.</p>
                        <Button type='primary' size='large' className='notfound_btn' style={{ fontWeight: "400", padding: "0", backgroundColor: "#257CDB", height: "46px" }}
                            onClick={() => {
                                history.push("/");
                            }}>Go to home</Button>
                    </div>
                </Col>
            </Row> */}
            <Row justify={"center"}>
                <Col xs={0} sm={0} md={0} lg={12} xl={10} xxl={12} style={{textAlign:"start",paddingLeft:"6rem",alignSelf:"center"}}>
                    <p className='notfound_para' style={{ marginBottom: "1.2rem" }}>Something went <strong className='notfound_strong' >Wrong.</strong></p>
                    <p className='notfound_para' style={{ marginBottom: "2rem" }}>The page you are looking for does not exist.</p>
                    <Button type='primary' size='large' style={{ borderRadius: "10px", width: "200px", fontSize: "20px", fontWeight: "400", padding: "0", backgroundColor: "#257CDB", height: "46px" }}
                        onClick={() => {
                            history.push("/");
                        }}>Go to home</Button>
                </Col>
                <Col xs={0} sm={0} md={0} lg={12} xl={14} xxl={12}  style={{textAlign:"center"}}>
                    <Image src={gif} preview={false} />
                </Col>



                <Col xs={24} sm={24} md={24} lg={0} xl={0} style={{textAlign:"center"}}>
                    <Image src={gif} preview={false} />
                </Col>
                <Col xs={24} sm={24} md={24} lg={0} xl={0} xxl={0} style={{textAlign:"center"}}>
                    <p className='notfound_para1'>Something went <strong className='notfound_strong'>Wrong.</strong></p>
                    <p className='notfound_para2'>The page you are looking for does not exist.</p>
                    <Button type='primary' size='large' className='notfound_btn' style={{ fontWeight: "400", padding: "0", backgroundColor: "#257CDB", height: "46px" }}
                        onClick={() => {
                            history.push("/");
                        }}>Go to home</Button>
                </Col>
            </Row>
        </div >
    );
}

export default NotFoundPage;

