import React from 'react'
import globe from "../assets/images/globe.svg";
import mail from "../assets/images/mail (2).svg";
import phone2 from "../assets/images/phone2.svg"
import { Col, Row, Space } from 'antd';
import { phoneNumber, email } from "../common/config"

export default function InvoiceFooter() {
    return (
        <div>
            <Row style={{ color: "white", width: "100%" }} justify={"space-between"}>
                <Col align="start" xs={12} sm={8} md={8} lg={8} xl={8}>
                    <Space>
                        <img
                            style={{ height: "20px", width: "20px" }}
                            alt="mailIcon"
                            src={globe}
                        />
                        <a
                            style={{ marginRight: "10px", color: "white" }}
                            className="phoneNumber"
                            href="https://www.fyndr.us"
                            target="_blank"
                        >
                            www.fyndr.us
                        </a>
                    </Space>
                </Col>
                <Col align="start" xs={12} sm={8} md={8} lg={8} xl={8}>
                    <Space>
                        <img
                            style={{ height: "20px", width: "20px" }}
                            alt="mailIcon"
                            src={phone2}
                        />
                        <a
                            style={{ marginRight: "10px", color: "white" }}
                            className="phoneNumber"
                            href={`tel:${phoneNumber}`}
                        >
                            {phoneNumber}
                        </a>
                    </Space>
                </Col>
                <Col xs={12} sm={8} md={8} lg={8} xl={8} align="end">
                    <Space>
                        <img
                            style={{ height: "20px", width: "20px" }}
                            alt="mailIcon"
                            src={mail}
                        />
                        <a
                            style={{ marginRight: "10px", color: "white" }}
                            className="phoneNumber"
                            href={`mailto:${email}`}
                        >
                            {email}
                        </a>
                    </Space>
                </Col>

            </Row>
        </div>
    )
}
