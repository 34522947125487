import React from "react";
import { FormOutlined } from "@ant-design/icons";
import { Row, Col, Divider, Typography, Button, Table, Select } from "antd";
import RatingChart from "../../components/RatingChart/RatingChart";
import RatingAverage from "../../components/RatingChart/RatingAverage";
import ReviewStars from "../../components/ReviewStars/ReviewStars";
import { isMobile } from "../../common/util";

const { Text } = Typography;

export default function AllReviewPopUp(props) {
  return (
    <>
      {!isMobile() ? (
        <div style={{ padding: "0px 24px" }}>
          <Row align={"middle"}>
            <Col lg={6} md={6} xs={6} style={{ paddingLeft: "3rem" }}>
              {props.reviewsOverview && (
                <RatingAverage rating={props.rating} />
              )}
            </Col>
            <Col
              lg={5}
              md={4}
              xs={6}
              xl={5}
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Divider
                className="reviewrating_ratingReviewDivider"
                type="vertical"
              />
            </Col>
            <Col
              lg={13}
              md={14}
              xs={12}
              style={{
                marginTop: "2rem",
              }}
            >
              {props.reviewsOverview && (
                <RatingChart ratingMap={props.ratingMap} data={props.data} />
              )}
            </Col>
          </Row>
          {!props.indv?.biz && props.indv?.bizid !== props.businessId && (
            <>
              <Row style={{ marginTop: "3.5rem" }}>
                <Col lg={11} md={11} xs={12}>
                  <div>
                    <text className="reviewrating_writeReview">
                      {props.text}
                    </text>
                  </div>
                  <div className="reviewrating_writeReviewInput">
                    <Button
                      className="reviewrating_inputBox"
                      onClick={props.onClick}
                    >
                      <FormOutlined className="reviewrating_formOutLine" />
                    </Button>
                  </div>
                </Col>

                <Col lg={11} md={11}>
                  <div>
                    <Text className="reviewrating_writeReviewRateUs">
                      {props.starText}
                    </Text>
                  </div>
                  <div className="reviewrating_writeReviewStars">
                    {props.reviewsOverview && (
                      <ReviewStars
                        disabled={props.disabled}
                        rating={props.rating}
                      />
                    )}
                  </div>
                </Col>
              </Row>
            </>
          )}
          <Row style={{ marginTop: "1.68rem", marginBottom: "1.5rem" }}>
            <Col span={11}>
              <Select
                className="reviewrating_filterBTN"
                defaultValue={props.defValue}
                onChange={props.onDropdownChange}
                options={props.options}
                suffixIcon={props.suffixIcon}
                style={{
                  fontWeight: 400,
                  fontSize: "18px",
                  color: "#223369",
                  background: "#FFFFFF",
                  border: "1px solid #D3D6E1",
                  borderRadius: "5px",
                  height: "46px",
                  width: "18.688rem",
                }}
                placeholder={props.placeholder}
              />
            </Col>
          </Row>
        </div>
      ) : (

        <div>
          <Row>
            <Col
              span={9}
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {props.reviewsOverview && (
                <RatingAverage rating={props.rating} />
              )}
            </Col>
            <Col span={3}>
              <Divider
                type="vertical"
                style={{
                  border: "1px solid #CCCCCC",
                  height: "110px",
                  top: "1.5em",
                }}
              />
            </Col>
            <Col
              span={11}
              style={{
                marginTop: "2rem",
              }}
            >
              {props.reviewsOverview && (
                <RatingChart
                  ratingMap={props.ratingMap}
                  data={props.reviewdata}
                />
              )}
            </Col>
          </Row>

          {!props.indv?.biz && props.indv?.bizid !== props.businessId && (
            <>
              <Divider className="divider-mobile" />
              <Row style={{ paddingLeft: "10px" }}>
                <Col xs={9}>
                  <div className="reviewrating_rateTheStore-mobile">
                    {props.rateText}
                  </div>
                </Col>
                <Col xs={13} className="reviewrating_star-mobile">
                  {props.reviewsOverview && (
                    <ReviewStars
                      disabled={props.disabled}
                      rating={props.rating}
                    />
                  )}
                </Col>
              </Row>
              <Divider className="divider-mobile" />
              <Row style={{ paddingLeft: "10px" }}>
                <Col span={24}>
                  <div>
                    <text className="reviewrating_shareExp-mobile">
                      {props.shareText}
                    </text>
                  </div>

                  <div className="reviewrating_writeReviewInput">
                    <Button
                      className="reviewrating_inputBox-mobile"
                      onClick={props.shareClick}
                    >
                      <FormOutlined className="reviewrating_formOutLine-mobile" />
                    </Button>
                  </div>
                </Col>
              </Row>
            </>
          )}
          <Divider className="divider-mobile" />
          <Row
            style={{
              marginTop: "1.68rem",
              paddingLeft: "10px",
              paddingBottom: "10px",
            }}
          >
            <Col span={10}>
              <Select
                className="reviewrating_filterBTN"
                defaultValue={props.defValue}
                onChange={props.onDropdownChange}
                options={props.options}
                suffixIcon={props.suffixIcon}
                style={{
                  fontWeight: 400,
                  fontSize: "18px",
                  color: "#223369",
                  background: "#FFFFFF",
                  border: "1px solid #D3D6E1",
                  borderRadius: "5px",
                  height: "46px",
                  width: "160px",
                }}
                placeholder={props.placeholder}
              />
            </Col>
          </Row>
        </div>

      )
      }
      <Row>
        <Col
          style={{ maxHeight: "80vh" }}
          className="allReviewComments_scroll"
          span={24}
        >
          <Table
            pagination={props.pagination}
            showHeader={props.showHeader}
            columns={props.columns}
            dataSource={props.dataSource}
            onChange={props.handleTableChange}
            className="allReviewComments_table"
          />
        </Col>
      </Row>
    </>
  );
}
