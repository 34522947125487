import { Button, Modal, Input, Row, Col, Divider } from 'antd'
import React from 'react';
import moment from "moment";
import PulseLoader from "react-spinners/PulseLoader";
const { TextArea } = Input;
export default function Comments(props) {
    const getUserEntity = (entity) => {
        switch (entity) {
            case "BUSINESS":
                return "Business";
            case "FYNDR":
                return "Admin";
            case "INDIVIDUAL":
                return "Individual";
            default:
                break;
        }
    }
    console.log("props",props)
    return (
      <Modal
        title={props.title}
        centered
        width={800}
        onCancel={props.onCancel}
        destroyOnClose
        footer={null}
        visible={props.visible}
        className="admin-modal"
      >
        <>
          <div style={{ height: "80vh", overflow: "auto" }}>
            {props.commentslist.length > 0 ? (
              props.commentslist.map(
                ({ userName, date, comment, userEntity }) => (
                  <div className="modal-div">
                    <Row
                      style={{
                        width: "100%",
                        border: "solid .5px #3F9AF8",
                        padding: "1rem",
                        borderRadius: "1rem",
                      }}
                    >
                      <Row style={{ width: "100%" }} align="middle" justify="space-between">
                        <Col span={12}>
                          <h5>
                            {userName}{" "}
                            <span style={{ color: "#3F9AF8" }}>
                              ({getUserEntity(userEntity)})
                            </span>
                          </h5>
                        </Col>
                        <Col  style= {{textAlign:"end"}}span={10}>
                          <h6 style={{ fontSize: ".8rem" }}>
                            {moment(date).format("MMMM Do YYYY, h:mm:ss a")}
                          </h6>
                        </Col>
                      </Row>
                      <Divider style={{background:"#3F9AF8"}} />

                      <p>{comment}</p>
                    </Row>
                    <Divider />
                  </div>
                )
              )
            ) : (
              <div style={{ textAlign: "center", alignContent: "center" }}>
                {props.loading ? (
                  <PulseLoader
                    color={"#3F9AF7"}
                    loading={props.loading}
                    size={10}
                  />
                ) : (
                  <h6>No Comments added yet</h6>
                )}
              </div>
            )}
          </div>
          <Row justify={"space-between"}>
            <TextArea
              value={props.value}
              placeholder="Type here...."
              style={{
                overflow: "hidden",
                height: 100,
                borderRadius: ".8rem",
              }}
              onChange={props.onChange}
            />
          </Row>
          <Row className="cust-dashboard-modal">
            <Button type="primary" onClick={props.onClick}>
              Send
            </Button>
          </Row>
        </>
      </Modal>
    );
}
