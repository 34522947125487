import React, { useState } from "react";
import ImageGallery from "react-image-gallery";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

const AddImageSlider = (props) => {
  const { image } = props;
  let images = image.map((item, key) => {
    return {
      original: item,
    };
  });

  const [currentIndex, setCurrentIndex] = useState(0);

  const handleSlide = (currentIndex) => {
    setCurrentIndex(currentIndex);
  };

  const handlePrevClick = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleNextClick = () => {
    if (currentIndex < images.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  return (
    <div
      style={{ position: "relative", padding: "0 12px", marginBottom: "12px" }}
    >
      <ImageGallery
        autoPlay={true}
        useTranslate3D={false}
        showFullscreenButton={false}
        showPlayButton={false}
        showThumbnails={false}
        showBullets={true}
        showNav={false}
        items={images}
        slideInterval={5000}
        onSlide={handleSlide}
        startIndex={currentIndex}
        renderItem={(item) => {
          return (
            <img
              className="reviewcomments_ratingCards-mobile"
              alt="campaign image"
              src={item.original}
            />
          );
        }}
      />
      <div
        style={{
          position: "absolute",
          top: "50%",
          transform: "translateY(-50%)",
          left: "12px",
          zIndex: 1,
        }}
      >
        <button
          onClick={handlePrevClick}
          style={{
            background: "rgba(0, 0, 0, 0.4)",
            backdropFilter: "blur(2px)",
            border: "none",
            borderRadius: "0px 4px 4px 0px",
            height: "3rem",
            width: "2rem",
            color: "white",
          }}
        >
          <LeftOutlined style={{ verticalAlign: "0em" }} />
        </button>
      </div>
      <div
        style={{
          position: "absolute",
          top: "50%",
          transform: "translateY(-50%)",
          right: "12px",
          zIndex: 1,
        }}
      >
        <button
          onClick={handleNextClick}
          style={{
            background: "rgba(0, 0, 0, 0.4)",
            backdropFilter: "blur(2px)",
            border: "none",
            borderRadius: "4px 0px 0px 4px",
            height: "3rem",
            width: "2rem",
            color: "white",
          }}
        >
          <RightOutlined style={{ verticalAlign: "0em" }} />
        </button>
      </div>
    </div>
  );
};

export default AddImageSlider;
