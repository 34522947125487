/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import UserActions from "../../actions";
import {
  Row,
  Col,
  Radio,
  Input,
  Divider,
  message,
  Typography,
  Button,
  Card
} from "antd";
import { BizCard } from "../../components";
import {
  getFormattedDateTimeNew,
  capitalize,
  getTotal,
  parseAddress,
} from "../../common/util";
import { debug } from "../../actions";
import ImageViewer from "react-simple-image-viewer";
import CardPay from "../Payment/CardPay";
import Axios from "axios";
import { DownloadOutlined } from "@ant-design/icons";

import { BiDollar } from "react-icons/bi";
import ModalRaiseADispute from "../../components/ModalRaiseADispute/ModalRaiseADispute";
import Buttons from "../../components/Buttons";
import DropdownComponent from "../../components/Dashboard/DropdownComponent";
import moment from "moment";

const { Text } = Typography;
const { TextArea } = Input;
const InvoicePay = (props) => {
  const {
    indv: { userTimeZone },
  } = useSelector(({ auth }) => auth);
  const { inv, callback, type } = props;
  const { indvid } = useSelector(({ auth }) => auth.indv);

  const {
    objid,
    invoiceDt,
    baseAmount,
    taxAmount,
    currencySymbol,
    tipAmount,
    status,
    invoiceDetails,
    invoiceDetails: { title, cust_message, server_name, invoice_nbr, img_url },
  } = inv;
  const totalAmount = getTotal(inv);
  const [tip, setTip] = useState(tipAmount + "");
  const [total, setTotal] = useState(totalAmount + "");
  const [raiseDispute, setRaiseDispute] = useState(false);
  const [selectDispute, setSelectedDispute] = useState("");
  const [dispute, setDispute] = useState([])
  const [tipError, setTipError] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [biz, setBiz] = useState(null);
  const [text, setText] = useState("")
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [disputeStatus, setDisputeStatus] = useState("");
  const [fyndrCash, setFyndrCash] = useState(0)
  const [invoiceId, setInvoiceId] = useState();
  const [logo, setLogo] = useState("")
  // const [img, setImg] = useState(null);

  const buttons = [
    { label: "10%", value: 0.1 },
    { label: "15%", value: 0.15 },
    { label: "20%", value: 0.2 },
  ];

  useEffect(() => {
    fetchInvoice();
  }, [inv, disputeStatus]);

  const fetchInvoice = async () => {
    const { success, resp } = await UserActions.getInvoiceDetails({
      buyerId: indvid,
      invoiceId: objid,
    });

    if (success) {
      setBiz(resp.biz);
      setFyndrCash(resp.fyndrCash)
      setDisputeStatus(resp?.disputeStatus)
      setInvoiceId(resp?.invoiceId)
      setLogo(resp.fyndrLogo);

    }
  }
  useEffect(() => {
    setTip(tipAmount > 0 ? tipAmount : null);
    setSelectedIndex(null);
    setTotal(totalAmount);
  }, [objid]);

  let newobj = {}
  const result = [];
  const disputeReasons = async () => {
    let response = await UserActions.getDisputeReasons();
    for (let value of response?.payload) {
      newobj = { ...value }
      result.push(newobj)
    }
    const newArrayOfObj = result.map(({ '0': value, '1': label, ...rest }) => ({
      value, label,
      ...rest,
    }));
    if (response?.success === true) {
      setDispute(newArrayOfObj)

    } else {
      console.log("what response for dispute", response?.payload);
    }
  }
  const submitDispute = async () => {
    let payload = {
      disputeReasonId: parseInt(selectDispute),
      description: text
    }
    let invoiceId = objid;
    try {
      let response = await UserActions.raiseDispute(payload, invoiceId)
      if (response.success) {
        message.success(response?.msg)
        setRaiseDispute(false);
        fetchInvoice();
      } else {
        message.error(response?.msg);
        setRaiseDispute(false)
      }
    } catch (error) {
      debug && console.log("what is error >>>>>", error);
    }
  }
  const handleOnSubmit = () => {
    if (selectDispute === "") {
      message.error("Please select any one options from the dropdown below")
    }
    else if (text === "") {
      message.error("Description can't be empty")
    } else {
      submitDispute()
    }
  }
  const handleChangeTip = (value) => {
    setTipError(null);
    setTip(value);
    if (value && value < 0) {
      setTipError("Tip can't be less than zero");
    }
    setTotal(
      (Number(baseAmount) + taxAmount + Number(value ? value : 0)).toFixed(2)
    );
    setSelectedIndex(-1);
  };

  const onPress = (e) => {
    const value = e.target.value;
    setSelectedIndex(value);
    const val = value * baseAmount;
    setTip(val.toFixed(2) + "");
    setTotal((Number(baseAmount) + taxAmount + Number(val)).toFixed(2) + "");
  };

  const download = async (e, uri, title) => {
    e.stopPropagation();
    let fileDownload = require("js-file-download");
    const res = await Axios.get(uri, {
      responseType: "blob",
    });
    fileDownload(res.data, `${title}.pdf`);
  };

  let date1 = new Date(invoiceDt);
  let date2 = new Date();
  let Difference_In_Time = date2.getTime() - date1.getTime();
  let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

  const getChannelName = (channel) => {
    switch (channel) {
      case 'offers':
      case 'offer_appointment':
        return 'Offer';
      case 'events':
        return 'Event';
      case 'interaction':
        return 'Interaction';
      case 'catalog':
      case 'catalog_appointment':
        return 'Catalog';
      case 'cmpn_promo':
        case 'promo':
        return 'Campaign';
      default:
        return 'Unknown';
    }
  };


  const startDate = invoiceDetails?.featured_start_date && new Date(invoiceDetails?.featured_start_date);
  const endDate = invoiceDetails?.featured_end_date && new Date(invoiceDetails?.featured_end_date);


  return (
    <>
      {biz !== null && (
        <>
          <BizCard
            businessLogo={inv?.channel !== "cmpn_promo" ? biz.mainLogo : logo}
            businessName={biz.bizName}
            businessWebsite={biz.website}
            businessAddonUrl={biz.addonUrl}
            businessAddress={parseAddress(biz)}
            businessPhone={biz.phone}
            invoiceID={invoiceId}
            channel={inv.channel}
          />
        </>
      )}
      <Card className="invoice_offerCard" style={{ marginTop: "1rem" }}>
        <Row justify={"space-between"} style={{ marginBottom: "8px" }}>
          <Col span={12}>
            <Text className="invoice_text">{getChannelName(inv?.channel)} Name:</Text>
          </Col>
          <Col xl={12} align="end">
            <Text className="invoice_value">{inv?.channel === "cmpn_promo" ? title.split(':')[1]?.trim()||invoiceDetails?.cmpn_title : title}</Text>
          </Col>
        </Row>
        {invoiceDetails?.promo_channels && invoiceDetails?.promo_channels.includes("featured") &&
          <>
            <Row justify={"space-between"} style={{ marginBottom: "8px" }}>
              <Col span={8}>
                <Text className="invoice_text">Featured:</Text>
              </Col>
              <Col>
                <Text className="invoice_value">Yes</Text>
              </Col>
            </Row>
            <Row justify={"space-between"} style={{ marginBottom: "8px" }}>
              <Col span={8}>
                <Text className="invoice_text">Featured Duration:</Text>
              </Col>
              <Col>
                <Text className="invoice_value">{`${invoiceDetails?.duration} ${invoiceDetails?.duration === 1 ? "Month" : "Months"}`}</Text>
              </Col>
            </Row>

            <Row justify={"space-between"} style={{ marginBottom: "8px" }}>
              <Col span={8}>
                <Text className="invoice_text">Featured Start Date:</Text>
              </Col>
              <Col>
                <Text className="invoice_value">{moment.tz(startDate, userTimeZone).format("MMM DD, YYYY")} </Text>
              </Col>
            </Row>
            <Row justify={"space-between"} style={{ marginBottom: "8px" }}>
              <Col span={8}>
                <Text className="invoice_text">Featured End Date:</Text>
              </Col>
              <Col>
                <Text className="invoice_value">{moment.tz(endDate, userTimeZone).format("MMM DD, YYYY")}</Text>
              </Col>
            </Row>
          </>
        }

        {invoiceDetails?.promo_channels && (
          <>
            {(invoiceDetails?.promo_channels.includes("mobile_push") || invoiceDetails?.promo_channels.includes("email")) &&
              <Row justify={"space-between"} style={{ marginBottom: "8px" }}>
                <Col span={8}>
                  <Text className="invoice_text">Promotion type:</Text>
                </Col>
                <Col>
                  <Text className="invoice_value">
                    {invoiceDetails?.promo_channels.split(",").filter(channel => channel !== 'in_app' && channel !== 'featured')
                      .map(channel => channel === 'mobile_push' ? 'Phone' : capitalize(channel)).join(', ')}</Text>
                </Col>
              </Row>
            }

            <Row justify={"space-between"} style={{ marginBottom: "8px" }}>
              <Col span={8}>
                <Text className="invoice_text">Promotion Date:</Text>
              </Col>
              <Col>
                <Text className="invoice_value">{moment.tz(invoiceDt, userTimeZone).format("MMM DD, YYYY")}</Text>
              </Col>
            </Row>

            <Row justify={"space-between"} style={{ marginBottom: "8px" }}>
              <Col span={8}>
                <Text className="invoice_text">Promotion Time:</Text>
              </Col>
              <Col>
                <Text className="invoice_value">{moment.tz(invoiceDt, userTimeZone).format("hh:mm A")}</Text>
              </Col>
            </Row>
          </>
        )
        }
        <Row style={{ width: "100%" }}>
          <Col style={{ width: "100%" }}>
            <Row justify={"space-between"} style={{ marginBottom: "8px" }}>
              <Col span={8}>
                <Text className="invoice_text">Payment Status:</Text>
              </Col>
              <Col>
                <Text className="invoice_value">{capitalize(status)}</Text>
              </Col>
            </Row>
            {type === "payable" && disputeStatus !== null &&
              <Row justify={"space-between"} style={{ marginBottom: "8px" }}>
                <Col span={12}>
                  <Text className="invoice_text">Dispute Status:</Text>
                </Col>
                <Col>
                  <Text type="danger" className="invoice_value">{disputeStatus.charAt(0) + disputeStatus.slice(1).toLowerCase()}</Text></Col>
              </Row>
            }
            {cust_message !== null && cust_message !== undefined && (
              <Row justify={"space-between"} style={{ marginBottom: "8px" }}>
                <Col span={4}>
                  <Text className="invoice_text">Message:</Text>
                </Col>
                <Col>
                  <Text className="invoice_value">{cust_message}</Text>
                </Col>
              </Row>
            )}
            {server_name !== null && server_name !== undefined && server_name !== "" &&
              <Row justify={"space-between"} style={{ marginBottom: "8px" }}>
                <Col span={8}>
                  <Text className="invoice_text">Associate:</Text>
                </Col>
                <Col>
                  <Text className="invoice_value">{server_name}</Text>
                </Col>
              </Row>
            }

            <Row justify={"space-between"} style={{ marginBottom: "8px" }}>
              <Col span={4}>
                <Text className="invoice_text">Time:</Text>
              </Col>
              <Col>
                <Text className="invoice_value">{moment.tz(invoiceDt, userTimeZone).format("MMM DD, YYYY, hh:mm A")}</Text>
              </Col>
            </Row>
            {invoice_nbr !== null && invoice_nbr !== undefined && (

              <Row justify={"space-between"} style={{ marginBottom: "8px" }}>
                <Col span={4}>
                  <Text className="invoice_text">Invoice #:</Text>
                </Col>
                <Col>
                  <Text className="invoice_value">{invoice_nbr}</Text>
                </Col>
              </Row>
            )
            }
            <Row justify={"space-between"} style={{ marginBottom: "8px" }}>
              <Col span={4}>
                <Text className="invoice_text">Amount:</Text>
              </Col>
              <Col>
                <Text className="invoice_value">
                  {currencySymbol}
                  {baseAmount.toFixed(2)} {taxAmount <= 0 ? "(Tax included)" : ""}
                </Text>{" "}
              </Col>
            </Row>
            {fyndrCash > 0 &&
              <Row justify={"space-between"} style={{ marginBottom: "8px" }}>
                <Col span={8}>
                  <Text className="invoice_text">
                    Fyndr Cash:
                  </Text>
                </Col>
                <Col>
                  <Text className="invoice_value">
                    {currencySymbol}
                    {fyndrCash}
                  </Text>
                </Col>
              </Row>
            }
            {taxAmount > 0 && (
              <>
                <Row justify={"space-between"} style={{ marginBottom: "8px" }}>
                  <Col span={4}>
                    <Text className="invoice_text">Tax:</Text>
                  </Col>
                  <Col>
                    <Text className="invoice_value">
                      {currencySymbol}
                      {taxAmount?.toFixed(2)}
                    </Text>
                  </Col>
                </Row>
                <Row justify={"space-between"} style={{ marginBottom: "8px" }}>
                  <Col span={4}>
                    <Text className="invoice_text">Total:</Text>
                  </Col>
                  <Col>
                    <Text className="invoice_value">
                      {currencySymbol}
                      {((Number(baseAmount) + Number(taxAmount)) - Number(fyndrCash)).toFixed(2)}
                    </Text>
                  </Col>
                </Row>
              </>
            )}
          </Col>
          <Col>
            {img_url !== null &&
              img_url !== undefined &&
              img_url.indexOf("pdf") === -1 && (
                <div>
                  <img
                    alt="invoice"
                    onClick={() => setIsViewerOpen(true)}
                    className="invoice_img"
                    src={img_url}
                  />
                  {isViewerOpen && (
                    <ImageViewer
                      onClose={() => {
                        setIsViewerOpen(false);
                      }}
                      activeIndex={0}
                      src={[img_url]}
                    />
                  )}
                </div>
              )}
            {img_url !== null &&
              img_url !== undefined &&
              img_url.indexOf("pdf") !== -1 && (
                <Button
                  type="default"
                  icon={<DownloadOutlined />}
                  size="large"
                  onClick={(e) => download(e, img_url, title)}
                >
                  Download Invoice
                </Button>
              )}
          </Col>
        </Row>
        <Row align="middle" style={{ marginBottom: "8px" }} justify="space-between">
          <Col span={6}>
            <Text className="invoice_text">Tip</Text>
          </Col>
          <Col>
            <Radio.Group
              options={buttons}
              onChange={onPress}
              value={selectedIndex}
              optionType="button"
              buttonStyle="solid"
            />
          </Col>
          <Col span={6}>
            <Input
              prefix={<BiDollar className="prefix" />}
              onChange={(e) => handleChangeTip(e.target.value)}
              placeholder={"Custom"}
              defaultValue={tip}
              value={tip}
            />
          </Col>
        </Row>
        <Row>
          <Col offset={8}>
            {tipError && <Text type="danger">{tipError}</Text>}
          </Col>
        </Row>
        <Row style={{ marginBottom: "8px" }} justify="space-between">
          <Col span={8} className="invoice_text" >
            <Text>Grand Total:</Text>
          </Col>
          <Col>
            <Text className="invoice_value">{`${currencySymbol}${total}`}</Text>
          </Col>
        </Row>
      </Card >

      <Card style={{ width: "100%", marginTop: "1rem", paddingBottom: "12px" }} className="invoice_offerCard" >
        <Col span={24}>
          <CardPay
            total={total}
            tax={taxAmount}
            amount={baseAmount}
            params={{ tip, invoiceId: objid, channel: "interaction", biz }}
            callback={() => {
              message.success("Payment succeeded");
              callback();
            }}
          />
        </Col>
      </Card>
      <Buttons
        btn1="Print"
        onClick1={() => {
          window.print();
        }}
        classname1="invoice_dispute"
        classname="invoice_print"
        disputeStyle={{
          backgroundColor: "white",
          color: "#ED0C10",
          border: "1px solid #ED0C10",
          padding: "10px 16px",
          borderRadius: "10px",
          height: "46px",
          fontSize: "16px",
        }
        }
        btn2={(type === "payable" && disputeStatus === null && Math.round(Difference_In_Days) <= 30) && "Raise a dispute"}
        popvisible={Math.round(Difference_In_Days) > 30 || disputeStatus !== null ? true : false}
        onClick2={() => {
          disputeReasons()
          setText("");
          setSelectedDispute("")
          setRaiseDispute(true);
        }}
        type="link" />
      <ModalRaiseADispute
        width={400}
        visible={raiseDispute}
        className="transaction_raiseDisputeModal"
        button="submit"
        input={<DropdownComponent style={{ minWidth: "100%" }} placeholder="Please select from the options " options={dispute}
          onChange={(value) =>
            setSelectedDispute(value)} />}
        description={<TextArea value={text} placeholder="Enter here...."
          style={{
            overflow: "hidden",
            height: 120
          }} onChange={(e) => {
            setText(e.target.value)
          }} />}
        onCancel={() => setRaiseDispute(false)}
        onClick={() => handleOnSubmit()}
      />
    </>
  );
};

export default InvoicePay;