import React from "react";
import { Modal, Button, Row, Divider } from "antd";
import closeBtn from "../assets/images/closeBtn.svg";
export default function CustomizedModal(props) {
  const closeIcon = <img src={closeBtn} alt="closeBtn" />;
  let footerContent = null;
  if (props.footer !== null) {
    footerContent = (
      <Row justify="space-evenly">
        <Divider className="divider-mobile" />
        <Button
          style={props.btn1Style}
          onClick={props.onBtn1Click}
          type={props.btn1Type}
          icon={props.btn1Icon}
          disabled={props.btn1Disabled}
        >
          {props.btn1}
        </Button>
        <Button
          style={props.btn2Style}
          onClick={props.onBtn2Click}
          type={props.btn2Type}
          icon={props.btn2Icon}
          disabled={props.btn2Disabled}
        >
          {props.btn2}
        </Button>
      </Row>
    );
  }
  return (
    <Modal
      centered
      title={props.title}
      visible={props.visible}
      onCancel={props.onCancel}
      closeIcon={closeIcon}
      width={props.modalWidth}
      className={props.className}
      footer={footerContent}
    >
      {props.content}
    </Modal>
  );
}
