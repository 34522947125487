import React from "react";
import { Row, Col } from "antd";
import { getFormattedDt, isMobile, isTab } from "../../common/util";
export default function WalletDropDown(props) {
  return (
    <>
      <Row gutter={[0, 8]} className={props.classNameWalletRow}>
        <Col xs={4} sm={3} md={2} lg={2} xl={1} xxl={2}>
          <div>
            <img src={props.icon} alt="Custom Icon" />
          </div>
        </Col>
        <Col xs={15} sm={19} md={19} lg={3} xl={3} xxl={3}>
          <div className={props.classNameWalletDate}>{props.date}</div>
        </Col>
        {isMobile() && (
            <Col
            xs={2}
            sm={6}
           
            
           align={isMobile() ? "start" : "end"}
         >
           <div
             className={props.classNameWalletMoney}
             style={{ color: props.color }}
           >
             {props.money}
           </div>
         </Col>
        )}
        <Col
          xs={24}
          sm={24}
          md={props.value1 ? 14 : 24}
          lg={props.value1 ? 9 : 17}
          xl={props.value1 ? 10 : 18}
          xxl={props.value1 ? 9 : 17}
        >
          <div className={props.classNameWalletText}>
            <span>{props.text1}</span>&nbsp;<b>{props.text2}</b>
          </div>
        </Col>
        {props.value1 && (
          <Col
            xs={24}
            sm={12}
            md={10}
            lg={8}
            xl={8}
            xxl={8}
            align={isTab() ? "start" : "end"}
            style={props.style}
            
          >
            <div className={props.classNameWalletText}>
              <Row gutter={[0, 8]} justify={"end"}>
              <Col xs={12} sm={24} md={18} lg={18} xl={18} xxl={18} >
                <span>Buisness Name: {props.value1}</span>&nbsp;
              </Col>
              <Col xs={12} sm={24} md={6} lg={6} xl={6} xxl={6}>
                <b style={{ textDecorationLine: "underline" }}>
                  {props.value2}
                </b>
              </Col>
              </Row>
            </div>
          </Col>
        )}
        {!isMobile() && (
          <Col
          xs={24}
          sm={12}
          md={1}
          lg={2}
          xl={2}
          xxl={2}
         align={isMobile() ? "start" : "end"}
         
       >
         <div
           className={props.classNameWalletMoney}
           style={{ color: props.color }}
         >
           {props.money}
         </div>
       </Col>
        )}
        
      </Row>
    </>
  );
}
