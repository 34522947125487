export const landingpageEvent = () => {
    window.fbq('track', 'Lead-LandingPage')
}

export const loginPageView = () => {
    window.fbq('track', 'Login-Page-Viewed')
}

// export const googleloginDetails = (data) => {
//     window.fbq('trackCustom', 'GoogleLogin-Completed', data)
// }