import getEnvVars from "../environment";
import retry from "async-retry";
import Axios from "axios";

const { api_token } = getEnvVars();

export const getToken = async (endpoint) => {
  if (
    endpoint.indexOf("account") === -1 &&
    endpoint.indexOf("signin") === -1 &&
    endpoint.indexOf("signup") === -1 &&
    endpoint.indexOf("resetPassword") === -1 &&
    endpoint.indexOf("verifyToken") === -1 &&
    endpoint.indexOf("token") === -1 &&
    endpoint.indexOf("confirmIdentity") === -1 &&
    endpoint.indexOf("contact-us/mail") === -1 &&
    endpoint.indexOf("campaign/public/search") === -1 &&
    endpoint.indexOf("campaign/public/fetchByQR") === -1 &&
    endpoint.indexOf("campaign/v2/public/fetchByQR") === -1 &&
    endpoint.indexOf("/catalogue/catalogue/fetch") === -1 &&
    endpoint.indexOf("/identity/locationByQrcode") === -1 &&
    endpoint.indexOf("/catalogue/categories/fetch_by_location_qr") === -1 &&
    endpoint.indexOf("/catalogue/categories/fetch_by_location/") === -1 &&
    endpoint.indexOf("catalogue_item/search") === -1 &&
    endpoint.indexOf("/identity/verify/sendVerificationCode") === -1 &&
    endpoint.indexOf("/identity/verify/resendVerificationCode") === -1 &&
    endpoint.indexOf("/identity/verify/verifyVerificationCode") === -1 &&
    endpoint.indexOf("/catalogue/categories/fetch") === -1 &&
    endpoint.indexOf("/campaign/public/offers") === -1 &&
    endpoint.indexOf("/catalogue/search") === -1 &&
    endpoint.indexOf("/catalogue/v2/search") === -1 &&
    endpoint.indexOf("/campaign/public/indvreviews") === -1 &&
    endpoint.indexOf("/campaign/public/categories") === -1 &&
    endpoint.indexOf("/identity/businessTypes") === -1 &&
    endpoint.indexOf("catalogue/fetch_location") === -1 &&
    endpoint.indexOf("analytics/business/ratingSummary") === -1 &&
    endpoint.indexOf("/comments") === -1 &&
    endpoint.indexOf("/analytics/business/comment/") === -1 &&
    endpoint.indexOf("/admin/settings/fetch") === -1 &&
    endpoint.indexOf("/verify/referralCode") === -1 &&
    endpoint.indexOf("/admin/country/list") === -1 &&
    endpoint.indexOf("appointment/slots?locationId") === -1 &&
    endpoint.indexOf("campaign/v2/public/search") === -1 &&
    endpoint.indexOf("identity/frequentlyAskedQuestions") === -1 &&
    endpoint.indexOf("identity/background-image") === -1 &&
    endpoint.indexOf("identity/faq_category") === -1 &&
    endpoint.indexOf("identity/entity") === -1 &&
    endpoint.indexOf("identity/frequentlyAskedQuestions/category") === -1

   

  ) {
    let token = await localStorage.getItem("accessToken");
    console.log("accessToken", token);
    return token;
  } else {
    return api_token;
  }
};

export const setToken = async (headers, entityType) => {
  console.log("entityType", entityType);
  if (headers) {
    console.log("is it coming here", headers["x-auth-fyndr-code"]);
    localStorage.setItem("secureToken", headers.tokenheader);
    localStorage.setItem("accessToken", headers["x-auth-fyndr-code"]);
    localStorage.setItem("refreshToken", headers["x-auth-fyndr-refresh-code"]);
    if (entityType === "FYNDR") {
      localStorage.setItem("adminAccessToken", headers["x-auth-fyndr-code"]);

    }
  }
};

export const fetch_retry_post = async (endpoint, payload = {}, headers = {}) => {
  console.log("headers>>>", headers);
  try {
    return await retry(
      async () => {
        const response = await Axios({
          url: endpoint,
          method: "post",
          data: payload ? JSON.stringify(payload) : {},
          headers: headers ? headers : {}
        });
        return response;
      },
      {
        retries: 0,
      }
    );
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const fetch_retry_put = async (endpoint, payload) => {
  try {
    return await retry(
      async () => {
        const response = await Axios({
          url: endpoint,

          method: "put",
          data: payload ? JSON.stringify(payload) : {},
        });
        return response;
      },
      {
        retries: 0,
      }
    );
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const fetch_retry_patch = async (endpoint, payload, headers = {}) => {
  console.log("headers in patch", headers);
  try {
    return await retry(
      async () => {
        const response = await Axios({
          url: endpoint,

          method: "patch",
          data: payload ? JSON.stringify(payload) : {},
          headers: headers ? JSON.stringify(headers) : {}

        });
        return response;
      },
      {
        retries: 0,
      }
    );
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const fetch_retry_delete = async (endpoint, payload) => {
  try {
    return await retry(
      async () => {
        const response = await Axios({
          url: endpoint,

          method: "delete",
          data: payload ? JSON.stringify(payload) : {},
        });
        return response;
      },
      {
        retries: 0,
      }
    );
  } catch (error) {
    throw error;
  }
};

export const fetch_retry_get = async (endpoint, payload) => {
  try {
    return await retry(
      async () => {
        const response = await Axios({
          url: endpoint,

          data: {},
          method: "get",
        });
        return response;
      },
      {
        retries: 0,
      }
    );
  } catch (error) {
    console.error(error);
    throw error;
  }
};
// const setflag = async () => {
//   let resp = await localStorage.setItem("stop_flag", true);
//   return resp;
// };
export const generateFromRefreshToken = async (url) => {
  let RefreshToken = await localStorage.getItem("refreshToken");
  let flag = await localStorage.getItem("stop_flag");
  if (flag == true) {
    return { success: true };
  } else {
    // let settingflat = await setflag();
    const { auth } = getEnvVars();
    try {
      return await retry(
        async (bail, attempts) => {
          const endpoint = `${auth}/v1/token/generateFromRefreshToken`;

          const response = await fetch(endpoint, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            method: "post",
            body: JSON.stringify({ refreshToken: RefreshToken }),
          });
          if (response.ok) {
            const resp = await response.json();
            localStorage.setItem("accessToken", resp.accessCode);
            localStorage.setItem("refreshToken", resp.refreshToken);
            localStorage.setItem("stop_flag", false);

            return { success: true };
          } else {
            if (attempts > 1) {
              // To check how much attempt taken to execute successfull
              localStorage.setItem("accessToken", null);
              localStorage.setItem("refreshToken", null);
              return { success: false };
            }
            return { success: false };
          }
        },
        {
          retries: 0,
        }
      );
    } catch (error) {
      console.error(error);
      return { success: false };
    }
  }
};

export const generateToken = async (payload, entityType) => {
  const { auth } = getEnvVars();

  try {
    const endpoint = `${auth}/v1/token/generate`;
    const response = await fetch(endpoint, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "post",
      body: JSON.stringify(payload),
    });

    const resp = await response.json();
    console.log("resp?>>>", resp);
    if (response.ok === true) {
      //persist the tokens

      setToken({
        "x-auth-fyndr-code": resp.accessCode,
        "x-auth-fyndr-refresh-code": resp.refreshToken,
      }, entityType);
      return { success: true, ...resp };
    } else {
      return { success: false, ...resp };
    }
  } catch ({ message }) {
    return { success: false, msg: message };
  }
};
export const xmlGETRequest = async (endpoint) => {
  let token = await getToken(endpoint);
  var xhr = new XMLHttpRequest();
  xhr.open("GET", endpoint, true);
  xhr.setRequestHeader("X-Fyndr-Auth-Token", token);
  xhr.responseType = "arraybuffer";
  xhr.onload = function (e) {
    if (this.status === 200) {
      var blob = new Blob([this.response], { type: "application/pdf" });
      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "Report_" + new Date() + ".pdf";
      link.click();
    }
  };
  xhr.send();
};
