import React from "react";
// import { render } from "react-dom";
import { isExpired } from "react-jwt";
import Root from "./components/Root";
import { hydrate, render } from "react-dom";
import "./main.scss";
import Axios from "axios";
import { generateFromRefreshToken, getToken } from "./network/ApiManager";
import getEnvVars from "./environment";
import UserActions from "./actions";
import { message } from "antd";
// import * as serviceWorker from './serviceWorker';
const getHeaders = async (url, jwt) => {
  let passValue = await localStorage.getItem("base64Pass");
  let googleAccessToken = await localStorage.getItem("googleAccessToken");
  console.log("passValue", passValue);
  if (url.includes("secure/card?qrcode=")) {

    return {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${jwt}`,
    };
  } else if (url.includes("amazonaws")) {
    return {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
  } 
  else if (url.includes("appointment/googleCalendar/permission") || url.includes("secure/pay") || url.includes("appointment/googleCalendar/login")||url.includes("appointment/googleCalendar/bookEvent?businessId")) {
    return {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwt}`,
      "google_access_auth_token": googleAccessToken && googleAccessToken
    };
  }

  else if (url.includes("fyndr_entity/updatePassword") || url.includes("fyndr_entity/add")) {
    return {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwt}`,
      "E-Pass-Base64": passValue && passValue
    };
  }
  else {

    return {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwt}`,
      // "E-Pass-Base64 ": passValue ? passValue : ""
    };
  }
};

const getHeadersInvalidate = async (url, jwt) => {
  if (url.includes("token/invalidate")) {
    let accessToken = await localStorage.getItem("accessToken");
    return {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    };
  } else {
    console.log("returning.....normal");

    if (jwt === null) {
      jwt = await getToken(url);
    }
    return {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-Fyndr-Auth-Token": jwt,
    };
  }
};
Axios.interceptors.request.use(
  async (config) => {
    //fetching the jwt token from the storage;
    let jwt = await getToken(config.url);

    //this api token for public api
    const { api_token } = getEnvVars();
    //checking if jwt token is same as api token
    if (jwt !== api_token && jwt !== null) {
      //jwt check
      let jwtResponse = await isExpired(jwt);
      if (jwtResponse) {
        //jwt is expired call the refresh token api
        let response = await generateFromRefreshToken(config.url);

        if (response.success) {

          let jwt = await getToken(config.url);
          config.headers = {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwt}`,

          };
        } else {
          message.error("Your session has expired. Please login again");
          UserActions.logout();
        }
      } else {
        //create the headers for private api
        config.headers = await getHeaders(config.url, jwt);
      }
    } else {
      //create the headers for public api
      config.headers = await getHeadersInvalidate(config.url, jwt);

      // config.headers = {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      //   "X-Fyndr-Auth-Token": jwt,
      // };
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

Axios.interceptors.response.use(
  (response) => {
    response.ok = true;
    response.json = () => response.data;

    return response;
  },
  (error) => {
    return Promise.reject({
      ...error,
      message: error?.response?.data?.message || error?.data?.message,
    });
  }
);
//main render
// render(<Root />, document.getElementById("root"));
const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(<Root />, rootElement);
} else {
  render(<Root />, rootElement);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
