import React from 'react'
import Modal from 'antd/lib/modal/Modal'

export default function ModalIncognito(props) {
    return (
        <div >
            <Modal
                title={props.title}
                visible={props.visible}
                footer={props.footer}
                closable={props.closable}
                onCancel={props.onCancel}
                onOk={props.onOk}
                cancelButtonProps={false}
                centered
                className={props.className}
                width={props.width}>
                <span className='verifymobilemodal' >
                    {props.modalText}
                    <br />
                    {props.secondModalText}
                    &nbsp;
                    {props.thirdModaltext}
                </span>
               
                <div className='verifymobilemodal_verification_done'>
                    <p >{props.icon}</p>
                    <p>{props.text}</p>
                    {props.button}
                </div>
            </Modal>
        </div>
    )
}