import React from "react";
import AboutUsLeft from "../../components/AboutUsNew/about-us-left";
import AboutUsRight from "../../components/AboutUsNew/about-us-right";
import { Row, Col } from "antd";
import { useHistory } from "react-router-dom";

import images from "../../assets/images/Images";
import ourSide from "../../assets/images/AboutUs/side.png"
import newImg from "../../assets/images/AboutUs/Our-mission.png";
import howFyndrHelps from "../../assets/images/AboutUs/HowFyndrHelpsYou.png"

const AboutUsIndex = () => {
  let history = useHistory();

  const dataArray = [
    {
      title: "About Fyndr",
      content: `
      Fyndr is your trusted marketplace platform for businesses and users alike. Fyndr offers a comprehensive suite of tools to sell services online, create promotional campaigns, and attract customers with enticing deals and coupons. For individual users, Fyndr simplifies the process of booking appointments, accessing attractive offers, or organizing events. Whether you're a small business owner looking to expand your reach or an individual seeking convenience and savings, Fyndr has got you covered.        `,
      image: ourSide,
    },
    {
      title: "Our Mission",
      content: `
      At Fyndr, our mission is to empower businesses to thrive in the digital age. We're dedicated to providing a comprehensive platform that enables businesses to sell their services online, effortlessly create effective marketing campaigns, and offer enticing deals and coupons to their customers. Our goal is to make it easy for businesses to connect with their audience and grow their customer base. With our intuitive website and user-friendly application, customers can conveniently book appointments, ensuring a seamless experience for both businesses and their clients. At Fyndr, we're committed to helping businesses succeed by providing them with the tools and resources they need to flourish in today's competitive market landscape.
        `,
      image: newImg,
    },
  ];
  return (
    <Row>
      <Col>
        {dataArray.map((item, index) => {
          const isEvenIndex = index % 2 === 0;

          if (isEvenIndex) {
            return (
              <AboutUsLeft
                key={index}
                title={item.title}
                content={item.content}
                image={item.image}
                index={index}
                totalItems={dataArray.length}
              />
            );
          } else {
            return (
              <AboutUsRight
                key={index}
                title={item.title}
                content={item.content}
                image={item.image}
                buttonText={item.buttonText}
                buttonClick={()=>{
                  history.push("/register");
                }}
                index={index}
                totalItems={dataArray.length}
              />
            );
          }
        })}
      </Col>
    </Row>
  );
};

export default AboutUsIndex;