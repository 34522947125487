/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useRef, useEffect } from "react";
import ImageGallery from "react-image-gallery";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import ReactPlayer from 'react-player/lazy';
import Video from "../components/Video"
const ImageSlider = (props) => {
  const { cmpnImgs, cmpnVideos, imageClass } = props;
  const playerRef = useRef(null);
  let data = [];

  if (cmpnImgs && cmpnImgs.length > 0) {
    data.push(
      ...cmpnImgs.map((item) => ({
        original: item.imgUri,
        thumbnail: item.thumbnailUri,
        isVideo: false,
        alt: "campaign image",
      }))
    );
  }
  if (cmpnVideos && cmpnVideos.length > 0) {
    data.push(
      ...cmpnVideos.map((item) => ({
        original: item.videoUri,
        thumbnail: item.thumbnailUri,
        isVideo: true,
        alt: "",
      }))
    );
  }

  const [currentIndex, setCurrentIndex] = useState(0);


  useEffect(() => {
    if (playerRef.current) {
      playerRef.current.seekTo(0, "seconds");
    }
  }, [currentIndex]);

  const handleSlide = (currentIndex) => {
    setCurrentIndex(currentIndex);
  };

  const handlePrevClick = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleNextClick = () => {
    if (currentIndex < data.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  return (
    <div style={{ position: "relative", marginBottom: "12px", width: "100%"}}>
      <ImageGallery
        // autoPlay={true}
        useTranslate3D={false}
        showFullscreenButton={false}
        showPlayButton={false}
        showThumbnails={false}
        showBullets={true}
        showNav={false}
        items={data}
        slideInterval={5000}
        onSlide={handleSlide}
        startIndex={currentIndex}
        renderItem={(item) => {
          return (
            <>
              {!item.isVideo &&
                <img
                  className={imageClass}
                  style={{width:"100%",objectFit:"cover",}}
                  alt={item.alt}
                  src={item.original}
                />
              }
              {item.isVideo &&
                <ReactPlayer
                  ref={playerRef}
                  url={item.original}
                  width={"100%"}
                  height={"197px"}
                  controls
                  config={{
                    file: { attributes: { controlsList: 'nodownload' } },
                    attributes: { disablePictureInPicture: true },
                    playbackRateControls: false
                  }}
                />
              }
            </>
          );
        }}
      />
      <div
        style={{
          position: "absolute",
          top: "50%",
          transform: "translateY(-50%)",
          left: 0,
          zIndex: 1,
        }}
      >
        <button
          onClick={handlePrevClick}
          style={{
            background: "rgba(0, 0, 0, 0.4)",
            backdropFilter: "blur(2px)",
            border: "none",
            borderRadius: "0px 4px 4px 0px",
            height: "3rem",
            width: "2rem",
            color: "white",
          }}
        >
          <LeftOutlined style={{ verticalAlign: "0em" }} />
        </button>
      </div>
      <div
        style={{
          position: "absolute",
          top: "50%",
          transform: "translateY(-50%)",
          right: 0,
          zIndex: 1,
        }}
      >
        <button
          onClick={handleNextClick}
          style={{
            background: "rgba(0, 0, 0, 0.4)",
            backdropFilter: "blur(2px)",
            border: "none",
            borderRadius: "4px 0px 0px 4px",
            height: "3rem",
            width: "2rem",
            color: "white",
          }}
        >
          <RightOutlined style={{ verticalAlign: "0em" }} />
        </button>
      </div>
    </div>
  );
};

export default ImageSlider;
