import { Comment, Typography } from "antd";
import React, { useState } from "react";

const { Text } = Typography;

const UserCommentText = (props) => {
  const [expandedComments, setExpandedComments] = useState([]);
  const [expandedReplies, setExpandedReplies] = useState([]);

  const toggleExpandComment = (index) => {
    setExpandedComments((prevExpandedComments) => {
      const newExpandedComments = [...prevExpandedComments];
      newExpandedComments[index] = !newExpandedComments[index];
      return newExpandedComments;
    });
  };

  const toggleExpandReply = (index) => {
    setExpandedReplies((prevExpandedReplies) => {
      const newExpandedReplies = [...prevExpandedReplies];
      newExpandedReplies[index] = !newExpandedReplies[index];
      return newExpandedReplies;
    });
  };

  const LongText = (text, index, isReply) => {
    const MAX_LENGTH = 230;
    const isLongText = text?.length > MAX_LENGTH;
    const displayText = isLongText
      ? `${text.substring(0, MAX_LENGTH)}...`
      : text;

    const isExpanded = isReply
      ? expandedReplies[index]
      : expandedComments[index];

    const toggleExpand = isReply ? toggleExpandReply : toggleExpandComment;

    return (
      <Text>
        {isExpanded ? text : displayText}
        {isLongText && (
          <Text
            style={{ color: "#1890ff", cursor: "pointer" }}
            onClick={() => toggleExpand(index)}
          >
            {isExpanded ? " Read Less" : "Read More"}
          </Text>
        )}
      </Text>
    );
  };

  return (
    <div style={{ width: "100%" }}>
      <Comment
        className="userCommentText"
        content={<p>{LongText(props.userText, 0, false)}</p>}
      ></Comment>
      <Comment className="userCommentText commentReplyDiv">
        {props?.userReply &&
          props?.userReply?.map((item, index) => (
            <div className="commentReply" key={index}>
              {LongText(item?.reply, index, true)}
            </div>
          ))}
      </Comment>
    </div>
  );
};

export default UserCommentText;