import React from "react";
import { Row, Col, Image, Typography } from "antd";

const Text = Typography;
export default function AboutUsLeft(
  { title, content, image },
  index,
  totalItems
) {
  const isLastItem = index === totalItems - 1;
  return (
   <>
   {title && <Row className="aboutUs_aboutFyndr" justify={"center"}>
      <Col xs={24} sm={24} md={13} lg={14} xl={12} xxl={13} className="aboutUs_aboutFyndrText">
      <div className="unicode">&#9886;</div>
        <h1 style={{ color: "#257CDB" }}>{title}</h1>
        <p className="pararAboutus" style={{ fontSize: "1.4rem", fontWeight: "500", color:" #333333" }}>{content}</p>
      </Col>
      <Col xs={24} sm={24} md={7} lg={6} xl={5} xxl={4}className="aboutUs_aboutFyndrImg">
        <Image
          src={image}
          alt={title}
          preview={false}
          style={{
            height: isLastItem ? "customImageHeight" : "23rem",
            width: "25rem",
            objectFit:"contain"
          }}
        />
      </Col>
    </Row>}
   </>
  );
}