import React, { useState } from "react";
import { Upload, Button } from "antd";
import PromoCode2 from "../../../assets/images/PromoCode2.png";

const ImageUploader = () => {
  const [imageUrl, setImageUrl] = useState(PromoCode2);

  const handleChange = (info) => {
    if (info.file.status === "done") {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImageUrl(e.target.result);
      };
      reader.readAsDataURL(info.file.originFileObj);
    }
  };

  const beforeUpload = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      setImageUrl(e.target.result);
    };
    reader.readAsDataURL(file);
    return false;
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      <Upload
        name="image"
        listType="picture"
        showUploadList={false}
        beforeUpload={beforeUpload}
        onChange={handleChange}
      >
        <div
          style={{
            position: "relative",
            width: "200px",
            height: "200px",
            borderRadius: "10px",
            overflow: "hidden",
          }}
        >
          <img
            src={imageUrl}
            alt="Uploaded"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              borderRadius: "10px",
            }}
          />
          <div
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              width: "24px",
              height: "24px",
              backgroundColor: "#00000099",
              borderRadius: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <Button
              style={{ background: "none", border: "none", color: "#fff" }}
            >
              +
            </Button>
          </div>
        </div>
      </Upload>
    </div>
  );
};

export default ImageUploader;
