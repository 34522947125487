/* eslint-disable import/no-anonymous-default-export */
const AppStore = require("./appStore.png")
const GoogleStore = require("./googlePlayStore.png")
const fyndrMobile = require("./fyndrMobile.png")
const googlePlayLogo = require("./playstore.png")
const appleLogo = require("./apple-store.png")
const StripeImage = require("./stripe.png") || require("./stripe.png");
const PlaceHolder = require("./greyPlaceholderFyn.png")

const grocery = require("./grocery.png")
const restaurant = require("./restaurant.png")
const currentlyDue=require("./currDue.png")
const eventuallyDue=require("./eventually.png")
const pendingDue=require("./pending.png")
const pastDue=require("./pastDue.png")
const errorList=require("./errorList.png")
const menu=require("./menu.png")

const fb=require("./facebook.png")
const instagram=require("./instagram.png")
const linkdin=require("./linkdin.png")
const twitter=require("./twitter.png")


export default {
  googlePlayLogo,
  appleLogo,
  fyndrMobile,
  StripeImage,
  GoogleStore,
  AppStore,
  PlaceHolder,
  grocery,
  restaurant,
  currentlyDue,
  eventuallyDue,
  pendingDue,
  pastDue,
  errorList,
  fb,
  instagram,
  linkdin,
  twitter,
  menu
  
};
