/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Row, message, List, Typography, Modal, Popconfirm } from "antd";
import UserActions from "../../actions";
import Theme from "../../common/Theme.js";
import Loader from "../../components/Loading/Loader";
import Button from "antd-button-color";
import AddCard from "./AddCard";
import { MdDeleteForever } from "react-icons/md";
import { TiTick } from "react-icons/ti";
import ContainerWrapper from "../../components/ContainerWrapper";
import { isMobile } from "../../common/util.js";
import HelmetFyndr from "../../components/HelmetFyndr.jsx";
import getEnvVars from "../../environment.js";

const { Text } = Typography;
const web = getEnvVars();
const PaymentMethods = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [newCard, setNewCard] = useState(false);

  const { qrcode, cards } = useSelector(({ auth }) => auth.indv);

  const makeCardDefault = async (qrcode, id) => {
    setIsLoading(true);
    let response = await UserActions.makeCardDefault({ qrcode, id });
    setIsLoading(false);
    if (!response.success) {
      message.error(response.message);
    } else {
      message.success("Default card changed successfully");
    }
  };

  const deleteCard = async (qrcode, id) => {
    setIsLoading(true);
    let response = await UserActions.deleteCard({ qrcode, id });
    setIsLoading(false);
    if (!response.success) {
      message.error(response?.msg);
      setIsLoading(false);
    } else {
      message.success("Card Deleted Successfully");
      setIsLoading(false);
    }
  };
  return (
    <ContainerWrapper
      title={"Payment Methods"}
      headerOption={
        <>
          {isLoading && <Loader />}
          {!newCard && (
            <Button
              type="primary"
              onClick={() => setNewCard(true)}
              disabled={isLoading}
            >
              Add {Array.isArray(cards) && cards.length > 0 ? "another" : ""}{" "}
              card
            </Button>
          )}
        </>
      }
    >
      {
        <HelmetFyndr
          title="Payment"
          imgUrl={""}
          url={`${web}/payment_methods`}
          descr="merchant stripe_connect"
        />
      }
      <List
        style={{
          margin: isMobile() ? "-8px" : "0px",
          fontSize: isMobile() ? "12px" : "14px",
        }}
        itemLayout="horizontal"
        dataSource={cards || []}
        renderItem={(card) => (
          <List.Item>
            <div className="list-style-v1">
              <div className="list-item">
                <div className={`icon-btn mr-4`} style={{ marginTop: "-8px" }}>
                  {" "}
                  <i className={`fa fa-cc-${card.brand} cc`} />
                </div>
                <div className="list-item__body">
                  <div className="list-item__title list-sep cc-padding card.default">
                    <Text
                      className={
                        card.default === "y" ? "cc-default" : "cc-others"
                      }
                    >
                      {`**** **** **** ${card.last4}`}
                    </Text>
                  </div>
                  <div
                    className="list-item__title list-sep cc-padding"
                    style={{ minWidth: "120px" }}
                  >{`Expires: ${card.exp_month}/${card.exp_year}`}</div>
                  <div
                    className="list-sep"
                    style={{ marginRight: isMobile() ? "1rem" : "2rem" }}
                  >
                    {
                      <Popconfirm
                        title="Are you sure to delete this card?"
                        onConfirm={() => deleteCard(qrcode, card.id)}
                        okText="Yes"
                        cancelText="No"
                      >
                        <MdDeleteForever
                          style={{
                            fontSize: "28px",
                            marginRight: "1.5rem",
                            color: Theme.blue,
                            cursor: "pointer",
                          }}
                        />
                      </Popconfirm>
                    }
                    {card.default !== "y" && (
                      <TiTick
                        title="Make this card default"
                        style={{
                          fontSize: "30px",
                          color: Theme.green,
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          makeCardDefault(qrcode, card.id);
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </List.Item>
        )}
      />

      {newCard && (
        <Modal
          title="Add Payment Method"
          visible={newCard}
          footer={null}
          onCancel={() => setNewCard(false)}
        >
          <Row justify="center">
            <AddCard callback={() => setNewCard(false)} />
          </Row>
        </Modal>
      )}
    </ContainerWrapper>
  );
};
export default PaymentMethods;
