import React, { useEffect, useState } from "react";
import actions from "../../actions";
import accordion from "../../assets/images/Accordian.svg";
import SearchIcon from "../../assets/images/home-search-icon.svg";
import { isMobile } from "../../common/util";
import { MINIMUM_SEARCH_LIMIT } from "../../common/config";
import { Input, Tabs, Typography } from "antd";
import RichTextViewer from "../RichTextView";

const Text = Typography;
const Faq = (props) => {
  const { entity , activeKey } = props;
  const [activeIndex, setActiveIndex] = useState(null);
  const [categories, setCategories] = useState([])
  const [faqQA, setFaqQA] = useState([])
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [search, setSearch] = useState('');

  useEffect(() => {
    faqCategoryList();
  }, []);

  useEffect(() => {
    if (categories.length > 0 && selectedCategoryId !== null) {
      faqQAlist(selectedCategoryId , search);
    }
  }, [selectedCategoryId, search]);

  const faqCategoryList = async () => {
   const payload = {
    entityId : activeKey
   }
   let res = await actions.fetchFaqCategoryList(payload);

   if (res?.success === true){
    let categories = res?.data;
    const desiredOrder = ["About Fyndr", "Registration", "Campaigns", "Using Fyndr"];
    
    categories.sort((a, b) => {
      return desiredOrder.indexOf(a.name) - desiredOrder.indexOf(b.name);
    });

    setCategories(categories);    
    if (res.data.length > 0) {
      setSelectedCategoryId(res.data[0].id);
    }
   }
  }

  const faqQAlist = async (categoryId, searchStr = "") => {
    const payload = {
      searchStr,
      categoryId,
      fetchByGoal: "instore",
    }
    let res = await actions.fetchFaqQuestionAnswer(payload);

    if (res?.success === true) {
      setFaqQA(res?.data);
      
    } else {
      console.error(res?.msg);
    }
  }


  const handleSearch = (value) => {
    if(value.length >= MINIMUM_SEARCH_LIMIT){
      setSearch(value);
    } else {
      setSearch("");
    }
  };

  const handleTabChange = (key) => {
    const selectedCategory = categories.find(category => category.id.toString() === key);
    if (selectedCategory) {
      setSelectedCategoryId(selectedCategory?.id);
    }
  };
  
  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index); // Toggle accordion panel
  };  

  const { TabPane } = Tabs;

  const tabData = categories.map(category => ({
    key: category.id.toString(),
    title: category.name,
    content: ''
  }));

  return (
    <>
      <div
        style={{ padding: "24px", display: "flex", justifyContent: "center" }}
      >
       {<div className="faq_container">
          <div className="FaqText">
            <Text>Frequently Asked Questions By {activeKey==2 ? "Businesses" : "Individuals" } (FAQs)</Text>
            <span style={{fontSize:isMobile() ? "12px" : "14px" , fontWeight:"400"}}>Have question? We’re here to help you.</span>          
            <Input
               className="faq-search"
               placeholder= "Type your question here..."
               prefix={
                <img
                  loading="lazy"
                  src={SearchIcon}
                  alt="search"
                  style={{ marginRight: "0.75rem" }}
                />
              }
              onPressEnter={(e) => handleSearch(e.target.value)}
            />
          </div>
          <div className="faq-custom-tab">
          <Tabs 
            defaultActiveKey="1"
            tabBarGutter={isMobile() ? 20 :100}
            onChange={handleTabChange}
            type="scrollable"
            size="small">
            {tabData.map(tab => (
              <TabPane tab={tab.title} key={tab.key}>
                {tab.content}
              </TabPane>
            ))}
          </Tabs>
          </div>
          <div className="top-faq-question-box">
          {faqQA.map((item, index) => (
            <div key={index} className="faqQuestionBox">
              <div
                className="faqQnA"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  cursor: "pointer",
                  padding: "12px",
                  // color: activeIndex === index ? "red" : "yellow",
                  // backgroundColor : activeIndex == index ? "red" : "yellow",
                  borderRadius:
                    activeIndex === index ? "10px 10px 0px 0px" : "10px",
                  background: activeIndex === index ? "#EAF2FC" : "transparent",
                }}
                onClick={() => toggleAccordion(index)}
              >
                <Text className="faqQuestion">{item?.question}</Text>
                <img
                  src={accordion}
                  alt="accordion"
                  style={{
                    width: "24px",
                    height: "24px",
                    transform:
                      activeIndex === index ? "rotate(180deg)" : "rotate(0deg)",
                    transition: "transform 0.5s",
                    
                  }}
                />
              </div>
              {activeIndex === index && (
                <div
                  className="faqAns"
                  style={{
                    borderRadius:
                      activeIndex === index ? "0px 0px 10px 10px" : "",
                      background: "#EAF2FC",
                  }}
                >
                  <RichTextViewer content={item?.answer}/>                  
                </div>
              )}
            </div>
          ))}
          </div>
        </div>}
      </div>
    </>
  );
};

export default Faq;