import React,{useState} from "react";
import { List, Avatar, Typography, Button,Image,message } from "antd";
import { Theme } from "../common";
import { FiEdit } from "react-icons/fi";
import RichTextViewer from "./RichTextView";
import ConfirmationModal from "./ConfirmationModal";
import Images from "../assets";
import getEnvVars from "../environment";
import clipboard_black from "../assets/images/clipboard_black.png";
const { web } = getEnvVars();

const ListItem = (props) => {
  const { Text } = Typography;
  const {
    deletePress,
    onClick,
    hideDelete,
    onEditClick,
    onStoreUrlClick,
    item: { images, name, description, url },
  } = props;

  const [visible, setVisible] = React.useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const copyTextToClipboard = () => {
    const urlWithReferralCode = `${web}/catalogue/store/${url}`;
    navigator.clipboard
      .writeText(urlWithReferralCode)
      .then(() => {
        console.log("Text copied to clipboard:", urlWithReferralCode);
        message.success("URL copied");
      })
      .catch((error) => {
        console.error("Error copying text:", error);
      });
    setIsCopied(true);
  };
  return (
    <List.Item>
      <div className="list-style-v1">
        <div className="list-item" style={{ cursor: "pointer" }}>
          <Avatar
            onClick={onClick}
            shape="square"
            size={{
              xs: 24,
              sm: 32,
              md: 40,
              lg: 64,
              xl: 80,
              xxl: 100,
            }}
            src={
              images
                ? images[0]?.img || images[0]?.img_url
                : Images?.Images?.PlaceHolder
            }
            className="list-sep cc-padding"
          />
          <div className="list-item__body list-sep cc-padding">
            <div
              onClick={onClick}
            >
              <div className="big">
                <h5>
                  <Text>{name}</Text>
                </h5>
              </div>
              {description != null && (
                <div className="textOver">
                  <RichTextViewer content={`${description}`} />
                </div>
              )}
            </div>
            {url != null && (
              <div>
                <h6 className="storeUrl_text">
                  URL:{""}
                  <Text
                    className="storeUrl_text"
                    onClick={onStoreUrlClick}

                  >{`${web}/catalogue/store/${url}`}</Text>
                  <Image
                    src={clipboard_black}
                    onClick={copyTextToClipboard}
                    preview={false}
                    className="copyTextIconCatalogue"
                  />
                </h6>
              </div>
            )}
          </div>
          <div >
            <div className="list-sep cc-padding" style={{ float: "right" }}>
              <FiEdit
                onClick={onClick}
                className="list-item-edit"
                style={{
                  color: Theme.blue,
                }}
              />
            </div>
            <div className="editbtn">
              {hideDelete === true && (
                <div>
                  <Button
                    type="warning" onClick={onEditClick}>
                    Edit URL
                  </Button>
                </div>
              )}
            </div>
          </div>
          {hideDelete !== true && (
            <div className="list-sep cc-padding">
              <i
                style={{
                  fontSize: "22px",
                  color: Theme.red,
                  cursor: "pointer",
                }}
                className="fas fa-trash-alt"
                onClick={() => setVisible(true)}
              ></i>
            </div>
          )}
        </div>
        <ConfirmationModal
          title={`Delete ${name} `}
          visible={visible}
          description={"Are you sure you want to proceed ?"}
          onConfirm={() => {
            try {
              deletePress();
            } catch (error) {
              console.error(error);
            } finally {
              setVisible(false);
            }
          }}
          onCancel={() => setVisible(false)}
        />
      </div>
    </List.Item>
  );
};

export default ListItem;
