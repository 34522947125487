/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Typography, Popconfirm, Row, Col, Space, Button, Popover } from "antd";
import Store from "../assets/images/Store.svg";
import { isMobile } from "../common/util";
import { Theme } from "../common";
const { Text } = Typography;

const BizInfo = (props) => {
  const { label, link, icon, phone, callback, showStore, onClickViewStore, showInfo=false } =
    props;
  const url = link && !link.startsWith("http") ? `https://${link}` : link;
  return (
    <div style={{ maxWidth: "96%" }}>
      <Row justify="space-between">
        <Col span={24}>
            <span>{icon}</span>
            {link !== null && link !== undefined && (
              <Popconfirm
                overlay
                placement="top"
                title={
                  <div style={{ width: "24rem" }}>
                    You are visiting the link {link} outside Fyndr domain that
                    is not verified or secured by Fyndr, do you want to continue
                  </div>
                }
                onConfirm={() => {
                  window.open(url);
                }}
                okText="Yes"
                cancelText="No"
              >
                <a style={{ maxWidth: link.length > 50 ?"80%":"100%", textAlign: 'start',whiteSpace: "pre-wrap" ,display:'inline-block' }} href={link}>{link}</a>
                {/* <Button type="link" size="small" style={{ width: "120%", textAlign: 'start' }}>
                  {link}
                </Button> */}
              </Popconfirm>
            )}
            {phone !== undefined && isMobile() ? (
              <a onClick={() => callback(true)}>{phone}</a>
            ) : (
              <a onClick={() => callback(true)}>{phone}</a>
            )}
            {label !== undefined && <Text style={{ cursor: "pointer"}} className="invoice_value">{label} </Text>}
            {showInfo && <Popover content={"This is the sharable url of campaign"}>
                      <i
                        style={{ color: Theme.blue, marginLeft: ".5rem" }}
                        className="fa fa-info-circle"
                        aria-hidden="true"
                      />
                    </Popover>}
        </Col>
        <Col span={24}>
          {!phone && showStore && (
            <Button
              onClick={onClickViewStore}
              type="primary"
              style={{ borderRadius: '5px', marginTop: "12px" }}
              icon={
                <img src={Store} alt="store" className="catalogue_btn-icons" />
              }
            >
              View Store
            </Button>
          )}
        </Col>
      </Row>
    </div>
  );
};
export default BizInfo;