// import React from "react";
// import PropType from "prop-types";
// import ReactHtmlParser from 'react-html-parser';
// const ReactMarkdown = require("react-markdown");

// const RichTextViewer = ({ content }) => {
//   return <>{ReactHtmlParser(content)}</>;
// };

// RichTextViewer.propType = {
//   content: PropType.string,
// };
// export default RichTextViewer;

import React from "react";
import PropType from "prop-types";
import ReactHtmlParser from 'react-html-parser';
const ReactMarkdown = require("react-markdown");
const RichTextViewer = ({ content }) => {
  const isHtml =  isHTML(content)
  if(isHtml){
    // console.log("Html"  , content)
    return <>{ReactHtmlParser(content)}</>;
  }else{
    // console.log("markdown" ,  content)
    return <ReactMarkdown>{content}</ReactMarkdown>;
  }
};
function isHTML(str) {
  var a = document.createElement('div');
  a.innerHTML = str;
  for (var c = a.childNodes, i = c.length; i--; ) {
    if (c[i].nodeType == 1) return true;
  }
  return false;
}
RichTextViewer.propType = {
  content: PropType.string,
};
export default RichTextViewer;
