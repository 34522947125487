import React from 'react';
import Nav from './Nav';
// import MobileSidenav from './MobileSidenav';

const SidenavContainer = (props) => (
  <div className="app-sidenav-container ">
    <Nav {...props} />
  </div>
)

export default SidenavContainer;