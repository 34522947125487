/* eslint-disable no-unused-expressions */
import React, { useState } from "react";
import { Marker, InfoWindow } from "react-google-maps";
import { Row, Col } from "antd";
import Images from "../assets/images/Images";

const Markers = (props) => {
  const [isOpen, setIsOpen] = useState(null);
  const handleMarkerClick = (location) => {
    setIsOpen(location);
  };
  return (
    <>
      {props.item.cmpnLocs ?
        props.item.cmpnLocs?.map((location, i) => {
          // location.lat += 0.001;
          return (
            <Marker
              onClick={() => handleMarkerClick(location)}
              key={props.myKey}
              position={{
                lat: location.lat,
                lng: location.lng
              }}
            >
              
              {isOpen && isOpen.objid === location.objid && (
                <InfoWindow
                  onCloseClick={() => setIsOpen(null)}
                  options={{ closeBoxURL: ``, enableEventPropagation: true }}
                >
                  <div
                    style={{
                      backgroundColor: `#fff`,
                      opacity: 0.75,
                      padding: `1rem`,

                      minWidth: "10vw",
                    }}
                  >
                    <div
                      style={{
                        fontSize: `16px`,
                        fontColor: `#08233B`,
                        maxWidth: "100%",
                      }}
                    >
                      <Row gutter={30} align="middle" justify="center">
                        <Col span={8}>
                          <img
                            alt="thumbnail"
                            style={{ maxWidth: "100%" }}
                            src={
                              (props.item?.cmpnImgs &&
                                props.item?.cmpnImgs[0]?.thumbnailUri) ||
                              props.item?.catImg || props?.item?.mainLogo || Images.PlaceHolder
                            }
                          />
                        </Col>
                        <Col span={16}>
                          <h3 style={{ fontSize: "14px", fontWeight: "300" }}>
                            {props.item?.biz?.bizName || props.item.bizName}
                          </h3>
                          <h4
                            style={{
                              fontSize: "12px",
                              fontWeight: "500",
                              marginTop: "10px",
                            }}
                          >
                            {props.item.title || props.item.bizName}
                          </h4>
                        </Col>
                      </Row>
                      {props.item.qrCode && (
                        <>
                          <div
                            style={{
                              background: "#3F9AF7",
                              borderRadius: ".5rem",
                              width: "100%",
                              marginTop: "20px",
                              textAlign: "center",
                              padding: "5px",
                              marginRight: "20px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              props.history.push(
                                `/offer-details/${props.item.biz.bizName.replace(/[.\W]+/g,'-')}/${props.item.qrCode}`,
                                {
                                  item: props.item,
                                }
                              );
                            }}
                          >
                            <h5
                              style={{
                                color: "#fff",
                                fontSize: "14px",
                              }}
                            >
                              Check Offers
                            </h5>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </InfoWindow>
              )}
            </Marker>
          )
        })
        :
        <Marker
          onClick={() => handleMarkerClick(props.item)}
          key={props.myKey}
          position={{
            lat: props.item?.lat,
            lng: props.item?.lng
          }}
        >
          {isOpen && isOpen.objid === props.item.objid &&(
            <InfoWindow
              onCloseClick={() => setIsOpen(null)}
              options={{ closeBoxURL: ``, enableEventPropagation: true }}
            >
              <div
                style={{
                  backgroundColor: `#fff`,
                  opacity: 0.75,
                  padding: `1rem`,

                  minWidth: "10vw",
                }}
              >
                <div
                  style={{
                    fontSize: `16px`,
                    fontColor: `#08233B`,
                    maxWidth: "100%",
                  }}
                >
                  <Row gutter={30} align="middle" justify="center">
                    <Col span={8}>
                      <img
                        alt="thumbnail"
                        style={{ maxWidth: "100%" }}
                        src={
                          (props.item?.cmpnImgs &&
                            props.item?.cmpnImgs[0]?.thumbnailUri) ||
                          props.item?.catImg || props?.item?.mainLogo || Images.PlaceHolder
                        }
                      />
                    </Col>
                    <Col span={16}>
                      <h3 style={{ fontSize: "14px", fontWeight: "300" }}>
                        {props.item?.biz?.bizName || props.item.bizName}
                      </h3>
                      <h4
                        style={{
                          fontSize: "12px",
                          fontWeight: "500",
                          marginTop: "10px",
                        }}
                      >
                        {props.item.title || props.item.bizName}
                      </h4>
                    </Col>
                  </Row>
                  {props.item.qrCode && (
                    <>
                      <div
                        style={{
                          background: "#3F9AF7",
                          borderRadius: ".5rem",
                          width: "100%",
                          marginTop: "20px",
                          textAlign: "center",
                          padding: "5px",
                          marginRight: "20px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          props.history.push(
                            `/offer-details/${props.item.biz.bizName.replace(/[.\W]+/g,'-')}/${props.item.qrCode}`,
                            {
                              item: props.item,
                            }
                          );
                        }}
                      >
                        <h5
                          style={{
                            color: "#fff",
                            fontSize: "14px",
                          }}
                        >
                          Check Offers
                        </h5>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </InfoWindow>
          )}
        </Marker>
      }
    </>
  )
}

export default Markers;
