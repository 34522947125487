import React from "react";
import { Skeleton, Row, Col, Divider } from "antd";
import { isMobile } from "../../common/util";

const SkeletonLoader = () => (
  <div>
    {[1, 2, 3, 4].map((item, key) => {
      return (
        <div key={key} >
          <Row gutter={[20]}>
            <Col xs={0} lg={6}>
               <Skeleton.Image className="catalogue_category_skeleton_loader" />
            </Col>
            <Col xs={24} lg={18}>
              <Skeleton
                active
                loading={true}
                visible={true}
                paragraph={3}
                title
                avatar={isMobile()}
              />
            </Col>
          </Row>
          
          <Divider />
        </div>
      );
    })}
  </div>
);

export default SkeletonLoader;
