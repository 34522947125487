import React, { useState, useEffect } from "react";
import {
  Breadcrumb,
  Table,
  Col,
  Button,
  Divider,
  Row,
  Input,
  DatePicker,
  message,
  Popover,
  Typography,
  Space,
  Image,
} from "antd";
import {
  SearchOutlined,
  ArrowDownOutlined,
  ArrowUpOutlined,
} from "@ant-design/icons";
import DropdownComponent from "../../../components/Dashboard/DropdownComponent";
import actions from "../../../actions";
import { useHistory, useLocation } from "react-router-dom";
import { findHomePathFromRole, getDateNew } from "../../../common/util";
import PulseLoader from "react-spinners/PulseLoader";
import { MINIMUM_SEARCH_LIMIT } from "../../../common/config";
import moment from "moment";
import searchIcon from "../../../assets/images/searchIcon.svg";
import accordion from "../../../assets/images/Accordian.svg";
import BarChart from "../../../components/BarChart/BarChart";
import PieChart from "../../../components/PieChart/PieChart";
import chart from "../../../assets/images/chart.svg";
import barChart from "../../../assets/images/pai-chart.svg";
import { useSelector } from "react-redux";

const { RangePicker } = DatePicker;
const Text = Typography;
export default function CampaignStatistics() {
  const {
    indv: { userTimeZone },
  } = useSelector(({ auth }) => auth);

  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const [data, setData] = useState([]);
  const [cmpnType, setCmpnType] = useState([]);
  const [cmpnStatusType, setCmpnStatusType] = useState([]);
  const [filterCmpn, setFilterCmpn] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [dateOrder, setOrderDate] = useState(1);
  const [userType, setUserType] = useState(
    location?.state?.userType ? location?.state?.userType : []
  );
  const [userStatus, setUserStatus] = useState([]);
  const [text, setText] = useState("");
  const [cmpntext, setCmpnText] = useState([]);
  const [cmpnStatus, setCmpnStatus] = useState([]);
  const [statusDropdown, setStatusDropdown] = useState("");
  const [color, setColor] = useState("");
  const [bgColor, setBgColor] = useState("");
  const [searchStr, setSearchStr] = useState("");
  const [countryName, setCountryName] = useState("US");
  const [activeIndex, setActiveIndex] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 100,
    total: 0,
  });
  const [isHovered, setIsHovered] = useState(false);
  const [isHoveredStatus, setIsHoveredStatus] = useState(false);

  const handleStartDateChange = (date, dateString) => {
    setStartDate(dateString);
  };

  const handleEndDateChange = (date, dateString) => {
    setEndDate(dateString);
  };
  const country = [
    { value: "US", label: "US" },
    { value: "IN", label: "IN" },
  ];
  const status = [
    { value: "ACTIVE", label: "Active" },
    { value: "INACTIVE", label: "Inactive" },
    { value: "EXPIRED", label: "Expired" },
    { value: "REJECTED", label: "Rejected" },
    { value: "PENDING_REVIEW", label: "Pending Review" },
  ];
  
  const CmpnType = [
    { value: "offers", label: "offers" },
    { value: "coupons", label: "coupons" },
    { value: "events", label: "events" },
    // { value: "brochure", label: "brochure" },
  ];
  const getStatus = (status) => {
    switch (status) {
      case "ACTIVE":
        return "Active";
      case "PENDING_REVIEW":
        return "Pending Review";
      case "INACTIVE":
        return "Inactive";
      case "EXPIRED":
        return "Expired";
      case "REJECTED":
        return "Rejected";
    }
  };
  const getColor = (status) => {
    switch (status) {
      case "ACTIVE":
        return "#07C603";
      case "INACTIVE":
        return "#C63D03";
      case "EXPIRED":
        return "#6F6F6F";
      case "PENDING_REVIEW":
        return "#0000FF";
      case "REJECTED":
        return "#9E0F99";
      default:
        return null;
    }
  };
  const getBgColor = (status) => {
    switch (status) {
      case "ACTIVE":
        return "#E2FFE5";
      case "INACTIVE":
        return "#FFD6D6";
      case "EXPIRED":
        return "#E8E8E8";
      case "PENDING_REVIEW":
        return "#E2E2FF";
      case "REJECTED":
        return "#FFCBFD";
      default:
        return null;
    }
  };
  const UpdateStatusFunction = async (value, campaignId) => {
    let payload = { status: value };
    setLoading(true);
    let resp = await actions.CampnStatusUpdate(payload, campaignId);
    if (resp.success === true) {
      AdminSearchFunction(text);
      message.success(resp.msg);
    } else {
      message.error(resp.msg);
    }
    setLoading(false);
  };
  const getContant = () => {
    switch (dateOrder) {
      case 1:
        return `Click to sort ascending`;
      case 3:
        return `Click to cancel sorting`;
      case 2:
        return `Click to sort descending`;
      default:
        break;
    }
  };

  const tableColumn = [
    {
      title: "Business Name ",
      dataIndex: "businessName",
      key: "name",
      width: 130,
    },
    {
      title: "Campaign Name ",
      dataIndex: "campaignName",
      key: "name",
      width: 150,
    },
    {
      title: "Campaign Type",
      dataIndex: "campaignType",
      key: "cmpntype",
    },
    {
      title: "Active Campaign ",
      render: (_, record) => {
        return <p>{`${record.activeOffers}/${record.totalOffers}`}</p>;
      },
      key: "offers",
    },
    {
      title: "Industry Type",
      dataIndex: "industryType",

      key: "industry",
    },
    {
      title: "Sold Campaign ",
      dataIndex: "offerSold",
      key: "offersSold",
    },
    {
      title: "Total sold amount",
      dataIndex: "totalOfferSoldAmount",
      key: "soldamount",
      render: (data, key) => {
        return <p>{data ? `${key.currencySymbol}${data}` : "-"}</p>;
      },
    },
    {
      title: (
        <Popover content={getContant()}>
          <div
            onClick={() => {
              setPagination({
                ...pagination,
                current: 1,
              });
              setOrderDate((prevStep) => (prevStep === 3 ? 1 : prevStep + 1));
            }}
            style={{ display: "flex", alignItems: "center" }}
          >
            End Date{" "}
            <ArrowUpOutlined
              style={{
                color: dateOrder === 2 && "#2DCE89",
                height: 20,
                width: 20,
              }}
            />{" "}
            <ArrowDownOutlined
              style={{
                color: dateOrder === 3 && "#2DCE89",
                height: 20,
                width: 20,
              }}
            />
          </div>
        </Popover>
      ),
      dataIndex: "endDate",
      key: "endDate",
      width: 90,
      render: (endDate) => {
        return endDate ? getDateNew(endDate, userTimeZone) : "-";
      }

    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 170,
      render: (item, record) => (
        <DropdownComponent
          options={status}
          style={{
            color: getColor(item),
            width: "100%",
            borderRadius: "1rem",
            backgroundColor: getBgColor(item),
          }}
          value={getStatus(item)}
          onChange={(option) => {
            setColor(getColor(option));
            setBgColor(getBgColor(option));
            setStatusDropdown(option);
            UpdateStatusFunction(option, record.cmpnId);
          }}
        />
      ),
    },
    {
      title: "Actions",
      key: "action",
      width: 50,
      render: (_, record) => {
        return (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Button onClick={() => masqueradeUser(record.objId)} type="link">
              Login
            </Button>
          </div>
        );
      },
    },
  ];
  const handleSearch = (value) => {
    setText(value);
    if (value.length >= MINIMUM_SEARCH_LIMIT) {
      AdminSearchFunction(value);
    } else if (value.length == 0) {
      AdminSearchFunction("");
    }
  };
  const masqueradeUser = async (id) => {
    let response = await actions.MasqueradeUser(id);
    if (response.success === true) {
      let path = await findHomePathFromRole(response.role);
      history.push(path);
    } else {
      message.error(response.msg);
    }
  };
  const handleTableChange = (pagination) => {
    setPagination({
      ...pagination,
    });
  };

  const AdminSearchFunction = async (text) => {
    setLoading(true);

    const pgstart = text.pgstart || pagination.current;
    let response = await actions.AdminCampaignSearch({
      businessName: text,
      startDate: startDate,
      endDate: endDate,
      country: countryName,
      status: cmpnStatus,
      campaignType: filterCmpn,
      pgstart,
      pgSize: pagination.pageSize,
      dateOrder: dateOrder === 2 ? "ASC" : dateOrder === 3 ? "DESC" : "",
    });
    setPagination({
      ...pagination,
      total: response?.count,
    });

    if (response?.success === true) {
      setLoading(false);
      if (response?.campaignDetails !== null) {
        const filteredData = response?.campaignDetails.filter(item => item.campaignType !== "brochure");
        setData(filteredData);
      }
      else {
        setData([]);
      }

      setCmpnType(response.campaignTypeCount);
      setCmpnStatusType(response.campaignStatusCount);
    }
  };

  //effects
  useEffect(() => {
    //calling the first api here
      AdminSearchFunction(text);
  }, [
    userStatus,
    userType,
    pagination.current,
    pagination.total,
    pagination.pageSize,
    countryName,
    cmpnStatus,
    dateOrder,
    filterCmpn,
  ]);

  useEffect(() => {
    if ((endDate !== "" && startDate !== "") || startDate === "" && endDate === "") {
      AdminSearchFunction(text);
    }

  }, [startDate, endDate])
  // let objId = selectedUser?.objId

  const pieData = {
    datasets: [
      {
        data: [
          cmpnType?.offers,
          // cmpnType?.brochure,
          cmpnType?.coupons,
          cmpnType?.events,
        ],
        backgroundColor: ["#92BEED", "#F7AB66", "#82CC8B"],
        hoverBackgroundColor: ["#92BEED", "#F7AB66", "#82CC8B"],
      },
    ],
  };
  const cmpnoptions = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const dataset = context.dataset.data;
            const total = dataset.reduce((acc, value) => acc + value, 0);
            const currentValue = dataset[context.dataIndex];
            const percentage = ((currentValue / total) * 100).toFixed(2) + "%";
            return percentage;
          },
        },
      },
    },
    onClick: (elems, chart) => {
      if (chart.length > 0 && chart[0]?.index !== undefined) {
        setPagination({
          ...pagination,
          current: 1,
        });
        const newValue =
          chart[0]?.index === 1
            ? "brochure"
            : chart[0]?.index === 0
              ? "offers"
              : chart[0]?.index === 2
                ? "coupons"
                : "events";


        if (filterCmpn.includes(newValue)) {
          setFilterCmpn((prevText) =>
            prevText.filter((value) => value !== newValue)
          );
        } else {
          setFilterCmpn((prevText) => [...prevText, newValue]);
        }
      }
    },
  };

  const bardata = {
    labels: ["", "", "", ""],
    datasets: [
      {
        data: [
          cmpnType?.offers,
          // cmpnType?.brochure,
          cmpnType?.coupons,
          cmpnType?.events,
        ],
        // backgroundColor: ["#92BEED", "#999999", "#F7AB66", "#82CC8B"],
        backgroundColor: ["#92BEED", "#F7AB66", "#82CC8B"],
        barHeight: 100,
        borderRadius: 5,
      },
    ],
  };
  const options = {
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    onClick: (elems, chart) => {
      if (chart.length > 0 && chart[0]?.index !== undefined) {
        setPagination({
          ...pagination,
          current: 1,
        });
        const newValue =
          chart[0]?.index === 1
            ? "brochure"
            : chart[0]?.index === 0
              ? "offers"
              : chart[0]?.index === 2
                ? "coupons"
                : "events";

        if (filterCmpn.includes(newValue)) {
          setFilterCmpn((prevText) =>
            prevText.filter((value) => value !== newValue)
          );
        } else {
          setFilterCmpn((prevText) => [...prevText, newValue]);
        }
      }
    },
  };

  const getPieDataStatus = () => {
    let dataArray = [];
    if (cmpnStatusType?.active) {
      dataArray.push(cmpnStatusType?.active)
    }
    if (cmpnStatusType?.expired) {
      dataArray.push(cmpnStatusType?.expired)
    }
    if (cmpnStatusType?.pending_review) {
      dataArray.push(cmpnStatusType?.pending_review)
    }
    if (cmpnStatusType?.inactive) {
      dataArray.push(cmpnStatusType?.inactive)
    }
    if (cmpnStatusType?.rejected) {
      dataArray.push(cmpnStatusType?.rejected)
    }
    const pieDataStatus = {
      datasets: [
        {
          data: dataArray,
          backgroundColor: [
            "#82CC8B",
            "#999999",
            "#92BEED",
            "#657196",
            "#F7AB66",
          ],
          hoverBackgroundColor: [
            "#82CC8B",
            "#999999",
            "#92BEED",
            "#657196",
            "#F7AB66",
          ],
        },
      ],
    };

    return pieDataStatus

  }


  const OptionsStatus = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const dataset = context.dataset.data;
            console.log("context", dataset.data);
            const total = dataset.reduce((acc, value) => acc + value, 0);
            const currentValue = dataset[context.dataIndex];
            const percentage = ((currentValue / total) * 100).toFixed(2) + "%";
            return percentage;
          },
        },
      },
    },
    onClick: (elems, chart) => {
      if (chart.length > 0 && chart[0]?.index !== undefined) {
        setPagination({
          ...pagination,
          current: 1,
        });
        const newValue =
          chart[0]?.index === 1
            ? "EXPIRED"
            : chart[0]?.index === 0
              ? "ACTIVE"
              : chart[0]?.index === 2
                ? "PENDING_REVIEW"
                : chart[0]?.index === 3
                  ? "INACTIVE"
                  : " REJECTED";

        if (cmpnStatus.includes(newValue)) {
          setCmpnStatus((prevText) =>
            prevText.filter((value) => value !== newValue)
          );
        } else {
          setCmpnStatus((prevText) => [...prevText, newValue]);
        }
      }
    },
  };

  const bardataStatus = {
    labels: ["", "", "", "", ""],
    datasets: [
      {
        data: [
          cmpnStatusType?.active,
          cmpnStatusType?.expired,
          cmpnStatusType?.pendingReview,
          cmpnStatusType?.inactive,
          cmpnStatusType?.rejected,
        ],
        backgroundColor: [
          "#82CC8B",
          "#999999",
          "#92BEED",
          "#657196",
          "#F7AB66",
        ],
        barHeight: 100,
        borderRadius: 5,
      },
    ],
    borderRadius: 5,
  };
  const statusOptions = {
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    onClick: (elems, chart) => {
      if (chart.length > 0 && chart[0]?.index !== undefined) {
        setPagination({
          ...pagination,
          current: 1,
        });
        const newValue =
          chart[0]?.index === 1
            ? "EXPIRED"
            : chart[0]?.index === 0
              ? "ACTIVE"
              : chart[0]?.index === 2
                ? "PENDING_REVIEW"
                : chart[0]?.index === 3
                  ? "INACTIVE"
                  : " REJECTED";

        if (cmpnStatus.includes(newValue)) {
          setCmpnStatus((prevText) =>
            prevText.filter((value) => value !== newValue)
          );
        } else {
          setCmpnStatus((prevText) => [...prevText, newValue]);
        }
      }
    },
  };

  const [bar, setBar] = useState(false);
  const [statusbar, setStatusBar] = useState(false);
  const toggleChartView = () => {
    setBar(!bar);
    console.log(bar, "bar");
  };
  const toggleChartViewStatus = () => {
    setStatusBar(!statusbar);
  };
  const filteredData = data.filter(campaign => campaign.campaignType !== 'brochure');

  return (
    <div className="admin_container no-breadcrumb container-mw-xl chapter">
      <div className="quickLoader">
        <PulseLoader color={"#3F9AF7"} loading={loading} size={10} />
      </div>
      <article className="article">
        <Breadcrumb>
          <Breadcrumb.Item>Admin</Breadcrumb.Item>
          <Breadcrumb.Item>Campaign</Breadcrumb.Item>
        </Breadcrumb>
        <Divider />
        <Row style={{ paddingBottom: "40px" }}>
          <Col sm={24} md={24} lg={24} xl={8} xxl={8} className="adminCmpn--inputBars">
            <Input
              className="adminCmpn--search-input"
              placeholder="Search Business"
              //   type="search"
              onPressEnter={(e) => {
                setPagination({
                  ...pagination,
                  current: 1,
                });
                handleSearch(e.target.value);
              }}
              suffix={<img src={searchIcon} alt="searchIcon" />}
            />

            <DropdownComponent
              className="adminCmpn--countrySelect"
              onChange={(e) => {
                setPagination({
                  ...pagination,
                  current: 1,
                });
                setCountryName(e);
                setActiveIndex(false);
              }}
              onClick={() => setActiveIndex(true)}
              placeholder="Country"
              value={countryName}
              options={country}
              suffixIcon={
                <img
                  src={accordion}
                  alt="accordion"
                  style={{
                    width: "24px",
                    height: "24px",
                    transform: activeIndex ? "rotate(180deg)" : "rotate(0deg)",
                    transition: "transform 0.5s",
                  }}
                />
              }
              style={{ minWidth: "100%" }}
            />

            <DropdownComponent
              className="adminCmpn--statusSelect"
              onChange={(e) => {
                setPagination({
                  ...pagination,
                  current: 1,
                });
                console.log("e", e);
                setCmpnStatus(e);
              }}
              placeholder="Status"
              value={cmpnStatus}
              options={status}
              style={{ minWidth: "100%" }}
              mode="multiple"
            />

            <DropdownComponent
              className="adminCmpn--statusSelect"
              onChange={(e) => {
                setPagination({
                  ...pagination,
                  current: 1,
                });
                console.log("e", e);
                setFilterCmpn(e);
              }}
              placeholder="Campaign Types"
              value={filterCmpn}
              options={CmpnType}
              style={{ minWidth: "100%" }}
              mode="multiple"
            />
            <div className="adminCmpn--startEndDate">
              <div>
                <DatePicker
                  className="adminCmpn--datePicker"
                  onChange={handleStartDateChange}
                  value={startDate ? moment(startDate) : ""}
                  allowClear={true}
                  placeholder="Start Date"
                />
              </div>
              <div>
                <DatePicker
                  placeholder="End Date"
                  className="adminCmpn--datePicker"
                  onChange={handleEndDateChange}
                  value={endDate ? moment(endDate) : ""}
                  allowClear={true}
                  
                />
              </div>
            </div>
          </Col>
          <Col sm={24} md={12} lg={12} xl={8} xxl={8} className="adminCmpn--pieCol">
            <div className="adminCmpn--pieDiv">
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Text className="adminCmpn--statusText">Campaign Type</Text>
              </div>
              <div
                style={{
                  padding: "12px 12px 24px 12px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {!bar ? (
                  <PieChart
                    height={165}
                    width={165}
                    data={pieData}
                    options={cmpnoptions}
                  />
                ) : (
                  <BarChart data={bardata} options={options} />
                )}
              </div>

              <div className="adminCmpn--indecatorsPieChart">
                <Row>
                  <Col span={19}>
                    <Row style={{ padding: "10px 0px 5px 10px" }}>
                      <Col
                        span={12}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <div
                          style={{
                            borderRadius: "100%",
                            width: "12px",
                            height: "12px",
                            background: "#F7AB66",
                            marginRight: "5px",
                          }}
                        />
                        <Text className="adminCmpn--status">Coupons</Text>
                      </Col>
                      <Col
                        span={12}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <div
                          style={{
                            borderRadius: "100%",
                            width: "12px",
                            height: "12px",
                            background: "#82CC8B",
                            marginRight: "5px",
                          }}
                        />
                        <Text className="adminCmpn--status">Events</Text>
                      </Col>
                    </Row>
                    <Row style={{ padding: "0px 0px 5px 10px" }}>
                      {/* <Col
                        span={12}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <div
                          style={{
                            borderRadius: "100%",
                            width: "12px",
                            height: "12px",
                            background: "#82CC8B",
                            marginRight: "5px",
                          }}
                        />
                        <Text className="adminCmpn--status">Events</Text>
                      </Col> */}
                      <Col
                        span={24}
                        style={{ display: "flex", alignItems: "center", justifyContent: "left" }}
                      >
                        <div
                          style={{
                            borderRadius: "100%",
                            width: "12px",
                            height: "12px",
                            background: "#92BEED",
                            marginRight: "5px",
                          }}
                        />
                        <Text className="adminCmpn--status">Offers</Text>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={5}>
                    <div
                      style={{ position: "relative" }}
                      onClick={toggleChartView}
                      onMouseEnter={() => setIsHovered(true)}
                      onMouseLeave={() => setIsHovered(false)}
                    >
                      <Image
                        className="barImage"
                        preview={false}
                        src={bar ? barChart : chart}
                      />
                    </div>
                    <div className="adminCmpn--status">
                      {isHovered && (
                        <p className="barText">
                          {bar ? "Pie Chart" : "Bar Chart"}
                        </p>
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
          <Col sm={24} md={12} lg={12} xl={8} xxl={8}
            className="adminCmpn--pieCol"
          >
            <div className="adminCmpn--pieStatusDiv">
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Text className="adminCmpn--statusText">Status</Text>
              </div>
              <div
                style={{
                  padding: "12px 12px 24px 12px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {!statusbar ? (
                  <PieChart
                    height={165}
                    width={165}
                    data={getPieDataStatus()}
                    options={OptionsStatus}
                  />
                ) : (
                  <BarChart data={bardataStatus} options={statusOptions} />
                )}
              </div>

              <div className="adminCmpn--indecatorsPieChart">
                <Row>
                  <Col span={19}>
                    <Row style={{ padding: "10px 0px 5px 10px" }}>
                      <Col
                        span={7}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <div
                          style={{
                            borderRadius: "100%",
                            width: "12px",
                            height: "12px",
                            background: "#999999",
                            marginRight: "5px",
                          }}
                        />
                        <Text className="adminCmpn--status">Expired</Text>
                      </Col>
                      <Col
                        span={7}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <div
                          style={{
                            borderRadius: "100%",
                            width: "12px",
                            height: "12px",
                            background: "#82CC8B",
                            marginRight: "5px",
                          }}
                        />
                        <Text className="adminCmpn--status">Active</Text>
                      </Col>
                      <Col
                        span={7}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <div
                          style={{
                            borderRadius: "100%",
                            width: "12px",
                            height: "12px",
                            background: "#657196",
                            marginRight: "5px",
                          }}
                        />
                        <Text className="adminCmpn--status">Inactive</Text>
                      </Col>
                    </Row>
                    <Row style={{ padding: "0px 0px 5px 10px" }}>
                      <Col
                        span={12}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <div
                          style={{
                            borderRadius: "100%",
                            width: "12px",
                            height: "12px",
                            background: "#92BEED",
                            marginRight: "5px",
                          }}
                        />
                        <Text className="adminCmpn--status">
                          Pending Review
                        </Text>
                      </Col>
                      <Col
                        span={8}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <div
                          style={{
                            borderRadius: "100%",
                            width: "12px",
                            height: "12px",
                            background: "#F7AB66",
                            marginRight: "5px",
                          }}
                        />
                        <Text className="adminCmpn--status">Rejected</Text>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={5}>
                    <div
                      style={{ position: "relative" }}
                      onClick={toggleChartViewStatus}
                      onMouseEnter={() => setIsHoveredStatus(true)}
                      onMouseLeave={() => setIsHoveredStatus(false)}
                    >
                      <Image
                        className="barImage"
                        preview={false}
                        src={statusbar ? barChart : chart}
                      />
                    </div>
                    <div className="adminCmpn--status">
                      {isHoveredStatus && (
                        <p className="barText">
                          {statusbar ? "Pie Chart" : "Bar Chart"}
                        </p>
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>


        {/* <Row style={{ paddingBottom: "40px" }}>
          <Col span={8}>
            <Input
              className="adminCmpn--search-input"
              placeholder="Search Business"
              //   type="search"
              onPressEnter={(e) => {
                setPagination({
                  ...pagination,
                  current: 1,
                });
                handleSearch(e.target.value);
              }}
              suffix={<img src={searchIcon} alt="searchIcon" />}
            />

            <DropdownComponent
              className="adminCmpn--countrySelect"
              onChange={(e) => {
                setPagination({
                  ...pagination,
                  current: 1,
                });
                setCountryName(e);
                setActiveIndex(false);
              }}
              onClick={() => setActiveIndex(true)}
              placeholder="Country"
              value={countryName}
              options={country}
              suffixIcon={
                <img
                  src={accordion}
                  alt="accordion"
                  style={{
                    width: "24px",
                    height: "24px",
                    transform: activeIndex ? "rotate(180deg)" : "rotate(0deg)",
                    transition: "transform 0.5s",
                  }}
                />
              }
              style={{ minWidth: "100%" }}
            />

            <DropdownComponent
              className="adminCmpn--statusSelect"
              onChange={(e) => {
                setPagination({
                  ...pagination,
                  current: 1,
                });
                console.log("e", e);
                setCmpnStatus(e);
              }}
              placeholder="Status"
              value={cmpnStatus}
              options={status}
              style={{ minWidth: "100%" }}
              mode="multiple"
            />

            <div className="adminCmpn--startEndDate">
              <div>
                <DatePicker
                  className="adminCmpn--datePicker"
                  onChange={handleStartDateChange}
                  value={startDate ? moment(startDate) : ""}
                  allowClear={true}
                  placeholder="Start Date"
                />
              </div>
              <div>
                <DatePicker
                  placeholder="End Date"
                  className="adminCmpn--datePicker"
                  onChange={handleEndDateChange}
                  value={endDate ? moment(endDate) : ""}
                  allowClear={true}
                />
              </div>
            </div>
          </Col>
          <Col
            span={8}
            style={{
              display: "flex",
              justifyContent: "center",
              paddingLeft: "24px",
            }}
          >
            <div className="adminCmpn--pieDiv">
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Text className="adminCmpn--statusText">Campaign Type</Text>
              </div>
              <div
                style={{
                  padding: "12px 12px 24px 12px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {!bar ? (
                  <PieChart
                    height={165}
                    width={165}
                    data={pieData}
                    options={cmpnoptions}
                  />
                ) : (
                  <BarChart data={bardata} options={options} />
                )}
              </div>

              <div className="adminCmpn--indecatorsPieChart">
                <Row>
                  <Col span={19}>
                    <Row style={{ padding: "10px 0px 5px 10px" }}>
                      <Col
                        span={12}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <div
                          style={{
                            borderRadius: "100%",
                            width: "12px",
                            height: "12px",
                            background: "#999999",
                            marginRight: "5px",
                          }}
                        />
                        <Text className="adminCmpn--status">Brouchres</Text>
                      </Col>
                      <Col
                        span={12}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <div
                          style={{
                            borderRadius: "100%",
                            width: "12px",
                            height: "12px",
                            background: "#F7AB66",
                            marginRight: "5px",
                          }}
                        />
                        <Text className="adminCmpn--status">Coupons</Text>
                      </Col>
                    </Row>
                    <Row style={{ padding: "0px 0px 5px 10px" }}>
                      <Col
                        span={12}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <div
                          style={{
                            borderRadius: "100%",
                            width: "12px",
                            height: "12px",
                            background: "#82CC8B",
                            marginRight: "5px",
                          }}
                        />
                        <Text className="adminCmpn--status">Events</Text>
                      </Col>
                      <Col
                        span={12}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <div
                          style={{
                            borderRadius: "100%",
                            width: "12px",
                            height: "12px",
                            background: "#92BEED",
                            marginRight: "5px",
                          }}
                        />
                        <Text className="adminCmpn--status">Offers</Text>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={5}>
                    <div
                      style={{ position: "relative" }}
                      onClick={toggleChartView}
                      onMouseEnter={() => setIsHovered(true)}
                      onMouseLeave={() => setIsHovered(false)}
                    >
                      <Image
                        className="barImage"
                        preview={false}
                        src={bar ? barChart : chart}
                      />
                    </div>
                    <div className="adminCmpn--status">
                      {isHovered && (
                        <p className="barText">
                          {bar ? "Pie Chart" : "Bar Chart"}
                        </p>
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
          <Col
            span={8}
            style={{
              display: "flex",
              justifyContent: "center",
              paddingLeft: "24px",
            }}
          >
            <div className="adminCmpn--pieStatusDiv">
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Text className="adminCmpn--statusText">Status</Text>
              </div>
              <div
                style={{
                  padding: "12px 12px 24px 12px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {!statusbar ? (
                  <PieChart
                    height={165}
                    width={165}
                    data={pieDataStatus}
                    options={OptionsStatus}
                  />
                ) : (
                  <BarChart data={bardataStatus} options={statusOptions} />
                )}
              </div>

              <div className="adminCmpn--indecatorsPieChart">
                <Row>
                  <Col span={19}>
                    <Row style={{ padding: "10px 0px 5px 10px" }}>
                      <Col
                        span={7}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <div
                          style={{
                            borderRadius: "100%",
                            width: "12px",
                            height: "12px",
                            background: "#999999",
                            marginRight: "5px",
                          }}
                        />
                        <Text className="adminCmpn--status">Expired</Text>
                      </Col>
                      <Col
                        span={7}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <div
                          style={{
                            borderRadius: "100%",
                            width: "12px",
                            height: "12px",
                            background: "#82CC8B",
                            marginRight: "5px",
                          }}
                        />
                        <Text className="adminCmpn--status">Active</Text>
                      </Col>
                      <Col
                        span={7}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <div
                          style={{
                            borderRadius: "100%",
                            width: "12px",
                            height: "12px",
                            background: "#657196",
                            marginRight: "5px",
                          }}
                        />
                        <Text className="adminCmpn--status">Inactive</Text>
                      </Col>
                    </Row>
                    <Row style={{ padding: "0px 0px 5px 10px" }}>
                      <Col
                        span={12}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <div
                          style={{
                            borderRadius: "100%",
                            width: "12px",
                            height: "12px",
                            background: "#92BEED",
                            marginRight: "5px",
                          }}
                        />
                        <Text className="adminCmpn--status">
                          Pending Review
                        </Text>
                      </Col>
                      <Col
                        span={8}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <div
                          style={{
                            borderRadius: "100%",
                            width: "12px",
                            height: "12px",
                            background: "#F7AB66",
                            marginRight: "5px",
                          }}
                        />
                        <Text className="adminCmpn--status">Rejected</Text>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={5}>
                    <div
                      style={{ position: "relative" }}
                      onClick={toggleChartViewStatus}
                      onMouseEnter={() => setIsHoveredStatus(true)}
                      onMouseLeave={() => setIsHoveredStatus(false)}
                    >
                      <Image
                        className="barImage"
                        preview={false}
                        src={statusbar ? barChart : chart}
                      />
                    </div>
                    <div className="adminCmpn--status">
                      {isHoveredStatus && (
                        <p className="barText">
                          {statusbar ? "Pie Chart" : "Bar Chart"}
                        </p>
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row> */}

        {/* <Divider /> */}

        <Table
          className="admin-table"
          bordered
          pagination={pagination}
          columns={tableColumn}
          dataSource={data}
          size="medium"
          onChange={handleTableChange}
        />
      </article>
    </div>
  );
}
